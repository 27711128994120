import React from "react";

export function LoadingSpinner({
  variant = "Light",
  className,
}: {
  className?: string;
  variant?: "Light" | "Dark";
}) {
  return (
    <img
      src={`/Icon_Loading_${variant}.svg`}
      className={className}
      alt="Loading…"
    />
  );
}
