const baseUrl = "https://s3.ap-southeast-2.amazonaws.com/goldeneggs.info/";

export const videoLinks = {
  landing_page_mp4: baseUrl + "goldeneggs_8801_landing_page.mp4",
  landing_page_webm: baseUrl + "goldeneggs_8801_landing_page-2.webm",

  subscription_mov: baseUrl + "	goldeneggs_8810_subscription.mov",
  subscription_webm: baseUrl + "goldeneggs_8810_subscription.webm",

  about_relationship_mov: baseUrl + "goldeneggs_8811_about_relationship.mov",
  about_relationship_webm: baseUrl + "goldeneggs_8811_about_relationship.webm",

  money_behaviour_mov: baseUrl + "goldeneggs_8812_money_behaviour.mov",
  money_behaviour_webm: baseUrl + "goldeneggs_8812_money_behaviour.webm",

  goals_mov: baseUrl + "goldeneggs_8813_goals.mov",
  goals_webm: baseUrl + "goldeneggs_8813_goals.webm",

  goals_relationship_and_family_mov:
    baseUrl + "goldeneggs_8814_goals_relationship_and_family.mov",
  goals_relationship_and_family_webm:
    baseUrl + "goldeneggs_8814_goals_relationship_and_family.webm",
  goals_relationship_and_family_info: "",

  goals_own_home_mov: baseUrl + "goldeneggs_8815_goals_own_home.mov",
  goals_own_home_webm: baseUrl + "goldeneggs_8815_goals_own_home.webm",

  goals_investment_property_mov:
    baseUrl + "goldeneggs_8816_goals_investment_property.mov",
  goals_investment_property_webm:
    baseUrl + "goldeneggs_8816_goals_investment_property.webm",

  goals_income_career_mov: baseUrl + "goldeneggs_8818_goals_income_career.mov",
  goals_income_career_webm:
    baseUrl + "goldeneggs_8818_goals_income_career.webm",

  goals_holidays_mov: baseUrl + "goldeneggs_8819_goals_holidays.mov",
  goals_holidays_webm: baseUrl + "goldeneggs_8819_goals_holidays.webm",

  goals_celebrations_mov: baseUrl + "goldeneggs_8820_goals_celebrations.mov",
  goals_celebrations_webm: baseUrl + "goldeneggs_8820_goals_celebrations.webm",

  goals_big_things_mov: baseUrl + "goldeneggs_8821_goals_big_things.mov",
  goals_big_things_webm: baseUrl + "goldeneggs_8821_goals_big_things.webm",

  income_mov: baseUrl + "goldeneggs_8822_income.mov",
  income_webm: baseUrl + "goldeneggs_8822_income.webm",

  investment_property_mov: baseUrl + "goldeneggs_8823_investment_property.mov",
  investment_property_webm:
    baseUrl + "goldeneggs_8823_investment_property.webm",

  investment_property_mortgage_mov:
    baseUrl + "goldeneggs_8824_investment_property_mortgage.mov",
  investment_property_mortgage_webm:
    baseUrl + "goldeneggs_8824_investment_property_mortgage.webm",

  investment_property_depreciation_mov:
    baseUrl + "goldeneggs_8825_investment_property_depreciation.mov",
  investment_property_depreciation_webm:
    baseUrl + "goldeneggs_8825_investment_property_depreciation.webm",

  set_up_your_budget_mov: baseUrl + "goldeneggs_8826_set_up_your_budget.mov",
  set_up_your_budget_webm: baseUrl + "goldeneggs_8826_set_up_your_budget.webm",

  bills_mov: baseUrl + "goldeneggs_8827_bills.mov",
  bills_webm: baseUrl + "	goldeneggs_8827_bills.webm",

  everyday_mov: baseUrl + "goldeneggs_8828_everyday.mov",
  everyday_webm: baseUrl + "goldeneggs_8828_everyday.webm",

  fun_mov: baseUrl + "goldeneggs_8829_fun.mov",
  fun_webm: baseUrl + "goldeneggs_8829_fun.webm",

  holidays_mov: baseUrl + "goldeneggs_8830_holidays.mov",
  holidays_webm: baseUrl + "goldeneggs_8830_holidays.webm",

  future_and_mortgage_mov: baseUrl + "goldeneggs_8831_future_and_mortgage.mov",
  future_and_mortgage_webm:
    baseUrl + "	goldeneggs_8831_future_and_mortgage.webm",

  everyday2_mov: baseUrl + "goldeneggs_8832_everyday2.mov",
  everyday2_webm: baseUrl + "goldeneggs_8832_everyday2.webm",

  fun2_mov: baseUrl + "goldeneggs_8833_fun2.mov",
  fun2_webm: baseUrl + "goldeneggs_8833_fun2.webm",

  you_set_up_plan_mov: baseUrl + "goldeneggs_8834_you_set_up_plan.mov",
  you_set_up_plan_webm: baseUrl + "goldeneggs_8834_you_set_up_plan.webm",

  at_least_2_banks_mov: baseUrl + "goldeneggs_8835_at_least_2_banks.mov",
  at_least_2_banks_webm: baseUrl + "goldeneggs_8835_at_least_2_banks.webm",

  your_bank_accounts_mov: baseUrl + "goldeneggs_8836_your_bank_accounts.mov",
  your_bank_accounts_webm: baseUrl + "goldeneggs_8836_your_bank_accounts.webm",
};
