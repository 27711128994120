import Head from "next/head";
import React, { PropsWithChildren } from "react";

import { tw } from "../../tailwindcss-classnames";

import { c } from "lib/c";

export type FullscreenWrapperBGColors =
  | "bg-unassumingGray"
  | "bg-white"
  | "bg-soft-gold"
  | "bg-lightSand"
  | "bg-ecru"
  | "bg-gold"
  | "bg-sand"
  | "mobile-white-desktop-soft-gold";

type FullscreenWrapperProps = {
  title?: string;
  className?: string;
  direction?: "flex-col" | "flex-row";
  bg?: FullscreenWrapperBGColors;
};

export function FullscreenFlexWrapper({
  title,
  children,
  className,
  direction = "flex-col",
  bg = "bg-unassumingGray",
}: PropsWithChildren<FullscreenWrapperProps>) {
  return (
    <>
      {title && (
        <Head>
          <title>{title} - Golden Eggs</title>
        </Head>
      )}
      <div
        className={c(
          tw(
            getBg(bg),
            direction,
            "absolute",
            "inset-0",
            "flex",
            "overflow-y-auto"
          ),
          className
        )}
      >
        {children}
      </div>
    </>
  );
}

const getBg = (bg: FullscreenWrapperBGColors) =>
  bg === "mobile-white-desktop-soft-gold"
    ? {
        "bg-white": true,
        "container:bg-soft-gold": true,
      }
    : bg;
