import Image from "next/image";
import React, { FC, PropsWithChildren } from "react";

import { tw } from "../../tailwindcss-classnames";
import { Button } from "../button/button";
import { Footer } from "../display/Footer";
import { FullscreenFlexWrapper } from "../display/fullscreen-flex-wrapper";
import { B2, H1, H2 } from "../type/Typography";

import { noWidows } from "lib/no-widows";
import { videoLinks } from "lib/videolinks";

export const LandingPage: FC<unknown> = () => {
  console.log("Running on Local Updated test last");
  return (
    <FullscreenFlexWrapper bg="bg-white">
      <div className={tw("flex-1", "overflow-y-auto", "w-full")}>
        <DesktopTopNav />
        <Hero />
        <BlockQuotes />
        <SetupImgs />
        <ColumnsSection />
        <FaqsSection />
        <MaxSection />
        <Footer className={tw("bg-ecru")} />
      </div>

      <FloatingSignUp />
    </FullscreenFlexWrapper>
  );
};

function DesktopTopNav() {
  return (
    <nav className={tw("hidden", "container:block", "bg-white")}>
      <div
        className={tw(
          "max-w-screen-container-large",
          "flex",
          "mx-auto",
          "p-16",
          "items-center"
        )}
      >
        <div className={tw("flex-1")}>
          <Image
            src="/golden-eggs-logo.png"
            alt="Golden Eggs Logo"
            width={200}
            height={75}
          />
        </div>
        <div>
          <Button href={"/login"} shape="block">
            Log In
          </Button>
        </div>
      </div>
    </nav>
  );
}

function Hero() {
  return (
    <div
      className={tw(
        "bg-ecru",
        "container:bg-gold",
        "container:text-white",
        "container:py-48",
        "container:px-16",
        "container-large:px-0"
      )}
    >
      <div
        className={tw(
          "bg-no-repeat",
          "bg-gold",
          "bg-shapeGoldEcru",
          "bg-fullWidth",
          "bg-bottom",
          "text-white",
          "container:bg-none",
          "py-24",
          "pb-48",
          "container:pt-0"
        )}
      >
        <H1
          className={tw(
            "leading-normal",
            "container:pt-24",
            "container:text-left",
            "max-w-screen-container-large",
            "mx-auto",
            "w-full"
          )}
        >
          What is the Golden Eggs App?
        </H1>
      </div>
      <div
        className={tw(
          "w-full",
          "max-w-screen-container-large",
          "mx-auto",
          "container:flex",
          "container:pb-48"
        )}
      >
        <div
          className={tw(
            "space-y-16",
            "p-16",
            "container:w-2/5",
            "container:pl-0",
            "container:pr-48"
          )}
        >
          <p>
            The <strong>Golden Eggs App</strong> merges money and psychology to
            help us stick to whatever budget we set ourselves.
          </p>
          <p className={tw("hidden", "container:block")}>
            The focus is on enjoying life now, while staying on track for a
            great future.
          </p>
          <p className={tw("hidden", "container:block")}>
            Others claim savings are possible, but psychology trumps maths with
            money and we tend to overspend
          </p>
          <div className={tw("hidden", "container:block")}>
            <Button href="/signup" color="white">
              Sign Up Now For Free
            </Button>
          </div>
        </div>
        <div className={tw("container:w-3/5", "flex")}>
          <video
            style={{ width: "100%", maxWidth: "100%", maxHeight: 280 }}
            controls
            playsInline
            preload="auto"
          >
            <source src={videoLinks.landing_page_mp4} type="video/mp4" />
            <source src={videoLinks.landing_page_webm} type="video/webm" />
          </video>
        </div>
      </div>
      <div className={tw("container:hidden", "p-16", "space-y-16")}>
        <p>
          The focus is on enjoying life now, while staying on track for a great
          future.
        </p>
        <p>
          Others claim savings are possible, but psychology trumps maths with
          money and we tend to overspend.
        </p>
      </div>
    </div>
  );
}

function BlockQuotes() {
  return (
    <div
      className={tw(
        "container:text-l",
        "text-center",
        "max-w-screen-container-large",
        "mx-auto",
        "px-24",
        "py-32",
        "container:p-48",
        "space-y-32"
      )}
    >
      <p>
        We help: <br />
        <strong>
          {"people on a career path who love travel and property…"}
        </strong>
      </p>
      <p>
        {"…to"}{" "}
        <strong>
          {noWidows(
            "eliminate money worries and get on the path to financial freedom."
          )}
        </strong>
      </p>
      <p className={tw("font-extrabold")}>
        {"Best Practice Money Management Structure"}
      </p>
      <div className={tw("container:w-3/5", "mx-auto")}>
        <iframe
          style={{ width: "100%", height: "auto", minHeight: 260 }}
          src="https://www.youtube.com/embed/xVf_HIq6ug0"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}

function SetupImgs() {
  return (
    <div className={tw("p-16", "pb-80")}>
      <div
        className={tw(
          "max-w-screen-container-large",
          "mx-auto",
          "gap-16",
          "flex",
          "flex-col",
          "container:flex-row",
          "container:justify-around",
          "container:items-center"
        )}
      >
        <Image
          src={"/worst_setup.png"}
          alt={"How your accounts are set up now"}
          width={400}
          height={387}
        />
        <Image
          src={"/fast_slow_system.png"}
          alt={"How your accounts are set up in the Five2 System"}
          width={477}
          height={387}
        />
      </div>
    </div>
  );
}

function ColumnsSection() {
  return (
    <div className={tw("py-80", "bg-ecru")}>
      <div
        className={tw(
          "max-w-screen-container-large",
          "mx-auto",
          "space-y-32",
          "container:space-y-0",
          "px-16",
          "pb-48",
          "container:px-0",
          "container:flex",
          "container:items-start",
          "container:space-x-100"
        )}
      >
        <Col icon={"Icon_Cart.svg"} title={"Guilt Free Spending"}>
          Find the right balance between normal spending, fun spending and
          saving, so you can spend guilt free.
        </Col>
        <Col icon={"Icon_Chart.svg"} title={"Accountability"}>
          Fortnightly check ups to help you stay on track and quarterly
          revisions to make sure your plan is working. Scheduled meetings for
          when you needs that extra help to get you back on track.
        </Col>
        <Col icon={"Icon_Person.svg"} title={"Personalised Golden Eggs"}>
          One size does not fit all. Get a tailored solution based on your
          spending habits and current financial situation. We will set you up on
          our golden eggs and help create a setup that works for you.
        </Col>
      </div>
      <BigQuote />
    </div>
  );
}

function Col(
  properties: PropsWithChildren<{ icon?: string; img?: string; title: string }>
) {
  return (
    <div className={tw("flex-1")}>
      {properties.icon && (
        <img
          src={properties.icon}
          width={40}
          height={40}
          alt={properties.title}
        />
      )}
      {properties.img && (
        <Image
          src={properties.img}
          alt={properties.title}
          width={300}
          height={169}
        />
      )}
      <H2 className={tw("leading-normal", "pb-16")}>{properties.title}</H2>
      <B2 Component="p">{properties.children}</B2>
    </div>
  );
}

function BigQuote() {
  return (
    <div className={tw("px-16", "container:py-48")}>
      <H1
        Component={"h3"}
        className={tw("hidden", "container-mid:block", "pb-24")}
      >
        {"Don't just take our word for it"}
      </H1>
      <div
        className={tw(
          "max-w-screen-container-mid",
          "mx-auto",
          "bg-white",
          "px-32",
          "container:px-48",
          "py-24",
          "rounded-8"
        )}
      >
        <div className={tw("flex", "pt-0", "pb-16")}>
          <img src="/Icon_Star.svg" alt="*" />
          <img src="/Icon_Star.svg" alt="*" />
          <img src="/Icon_Star.svg" alt="*" />
          <img src="/Icon_Star.svg" alt="*" />
          <img src="/Icon_Star.svg" alt="*" />
        </div>
        <div className={tw("font-medium", "italic", "text-sm")}>
          ‘We were earning $110k/year after tax, but struggling to save, pay
          rent and enjoy our life. In addition to wanting to save the deposit
          for a home, we wanted to save to go to the World Cup finals. We set up
          with 7 accounts across 3 banks and it helped us save our home deposit,
          went to 2 World Cups and an Olympics and eventually stop smoking!’
        </div>
        <div className={tw("text-right", "pt-16")}>
          <h5 className={tw("font-bold")}>Karen and Di</h5>
          <h6 className={tw("text-steel", "text-sm")}>Clients for 7 Years</h6>
        </div>
      </div>
    </div>
  );
}

function FaqsSection() {
  return (
    <div
      className={tw(
        "px-16",
        "py-48",
        "max-w-screen-container-large",
        "mx-auto"
      )}
    >
      <H1 Component="h3" className={tw("pb-48")}>
        FAQs
      </H1>
      <div
        className={tw(
          "max-w-screen-container-large",
          "mx-auto",
          "space-y-32",
          "container:space-y-0",
          "px-16",
          "pb-48",
          "container:px-0",
          "container:flex",
          "container:items-start",
          "container:space-x-48"
        )}
      >
        <Col
          img={"/Img_Spending.jpg"}
          title={"'I often feel guilty about how much I’m spending’"}
        >
          We help you find the right balance between normal spending, fun
          spending and saving, so you can spend guilt free.
        </Col>
        <Col
          img={"/Img_Theory.jpg"}
          title={
            "‘I understand the theory, but I’m not sure if it’ll work for us.’"
          }
        >
          We will help create a personalised solution and integrate it into your
          life within 4 weeks.
        </Col>
        <Col
          img={"/Img_KeepTrack.jpg"}
          title={
            "‘I can’t keep track of our money now, how will I cope with all these new accounts?’"
          }
        >
          We give you access to a portal to view all banks, superannuation and
          property assets and liabilities on one screen.
        </Col>
      </div>
    </div>
  );
}

function MaxSection() {
  return (
    <>
      <hr className={tw("hidden", "container:block", "pt-48")} />
      <div
        className={tw(
          "max-w-screen-container-large",
          "mx-auto",
          "container:flex",
          "container:space-x-48",
          "items-center",
          "px-16",
          "py-48",
          "container:px-0"
        )}
      >
        <div>
          <div
            className={tw(
              "relative",
              "overflow-hidden",
              "rounded-full",
              "bg-center",
              "mx-auto",
              "max-w-full"
            )}
            style={{
              width: 316,
              height: 316,
              backgroundImage: `url("/Img_Max.jpg")`,
            }}
          />
        </div>
        <div className={tw("space-y-32", "py-32", "container:py-0")}>
          <H1 Component="h3">Max Phelps</H1>
          <p>
            Grew up in a family of nine kids, playing Monopoly and aiming not to
            be poor. Initially terrible at saving, during a sales and marketing
            career with a multinational he stumbled across an effective set-up.
            He spent a couple of years as a maths teacher and began investing in
            property in order to retire young. Now Max is a professional
            property investor, with 11 years of experience in finance, and
            qualifications in mortgage broking and financial planning. He is the
            Amazon best-selling author of &ldquo;Getting your money $hit
            together&rdquo; and the current FBAA Finance Broker of the year for
            NSW/ACT.
          </p>
          <p>
            These days he spends his time coaching young people on a career path
            who like property and love travel– to implement the golden eggs and
            get on the path to financial freedom. He wants them to learn from
            the mistakes he made, yet gain the same – or greater –success. When
            he takes a break, it’s to travel with the love of his life and
            partner for thirty years, Kelly. On Fridays he is with his grandson,
            Matthew, and he cannot be disturbed. Max has taken the 1% Pledge and
            1% of revenue is used to improve education in developing countries.
          </p>
        </div>
      </div>
    </>
  );
}

function FloatingSignUp() {
  return (
    <footer
      className={tw("bg-ecru", "w-full", "p-16", "container-large:hidden")}
    >
      <Button href={"/signup"}>Sign Up / Login</Button>
    </footer>
  );
}
