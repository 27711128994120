/* eslint-disable */
/* tslint:disable */
import classnamesLib from 'classnames';

export type TScreenReaders = 
  | 'sr-only'
  | 'not-sr-only';

export type TAccessibility =
  | TScreenReaders

export type TBackgroundAttachment = 
  | 'bg-fixed'
  | 'bg-local'
  | 'bg-scroll';

export type TBackgroundClip = 
  | 'bg-clip-border'
  | 'bg-clip-padding'
  | 'bg-clip-content'
  | 'bg-clip-text';

export type TBackgroundColor = 
  | 'bg-transparent'
  | 'bg-current'
  | 'bg-black'
  | 'bg-white'
  | 'bg-gray-100'
  | 'bg-gray-200'
  | 'bg-gray-300'
  | 'bg-gray-400'
  | 'bg-gray-500'
  | 'bg-gray-600'
  | 'bg-gray-700'
  | 'bg-gray-800'
  | 'bg-gray-900'
  | 'bg-red-100'
  | 'bg-red-200'
  | 'bg-red-300'
  | 'bg-red-400'
  | 'bg-red-500'
  | 'bg-red-600'
  | 'bg-red-700'
  | 'bg-red-800'
  | 'bg-red-900'
  | 'bg-orange'
  | 'bg-yellow'
  | 'bg-green'
  | 'bg-teal-100'
  | 'bg-teal-200'
  | 'bg-teal-300'
  | 'bg-teal-400'
  | 'bg-teal-500'
  | 'bg-teal-600'
  | 'bg-teal-700'
  | 'bg-teal-800'
  | 'bg-teal-900'
  | 'bg-blue'
  | 'bg-indigo-100'
  | 'bg-indigo-200'
  | 'bg-indigo-300'
  | 'bg-indigo-400'
  | 'bg-indigo-500'
  | 'bg-indigo-600'
  | 'bg-indigo-700'
  | 'bg-indigo-800'
  | 'bg-indigo-900'
  | 'bg-purple'
  | 'bg-pink'
  | 'bg-soft-gold'
  | 'bg-gold'
  | 'bg-darkGold'
  | 'bg-sand'
  | 'bg-lightSand'
  | 'bg-ecru'
  | 'bg-lemon'
  | 'bg-ink'
  | 'bg-steel'
  | 'bg-lightGray'
  | 'bg-unassumingGray'
  | 'bg-disabledTextGray'
  | 'bg-lightRed'
  | 'bg-mistakeRed'
  | 'bg-grey'
  | 'bg-olive-400'
  | 'bg-olive-600'
  | 'bg-olive-800';

export type TBackgroundOpacity = 
  | 'bg-opacity-0'
  | 'bg-opacity-25'
  | 'bg-opacity-50'
  | 'bg-opacity-75'
  | 'bg-opacity-100';

export type TBackgroundPosition = 
  | 'bg-bottom'
  | 'bg-center'
  | 'bg-left'
  | 'bg-left-bottom'
  | 'bg-left-top'
  | 'bg-right'
  | 'bg-right-bottom'
  | 'bg-right-top'
  | 'bg-top';

export type TBackgroundRepeat = 
  | 'bg-repeat'
  | 'bg-no-repeat'
  | 'bg-repeat-x'
  | 'bg-repeat-y'
  | 'bg-repeat-round'
  | 'bg-repeat-space';

export type TBackgroundSize = 
  | 'bg-auto'
  | 'bg-cover'
  | 'bg-contain'
  | 'bg-fullWidth';

export type TBackgroundImage = 
  | 'bg-none'
  | 'bg-gradient-to-t'
  | 'bg-gradient-to-tr'
  | 'bg-gradient-to-r'
  | 'bg-gradient-to-br'
  | 'bg-gradient-to-b'
  | 'bg-gradient-to-bl'
  | 'bg-gradient-to-l'
  | 'bg-gradient-to-tl'
  | 'bg-shapeGoldEcru'
  | 'bg-shapeGoldWhite'
  | 'bg-shapeGoldSoftGold';

export type TGradientColorStops = 
  | 'from-transparent'
  | 'via-transparent'
  | 'to-transparent'
  | 'from-current'
  | 'via-current'
  | 'to-current'
  | 'from-black'
  | 'via-black'
  | 'to-black'
  | 'from-white'
  | 'via-white'
  | 'to-white'
  | 'from-gray-100'
  | 'via-gray-100'
  | 'to-gray-100'
  | 'from-gray-200'
  | 'via-gray-200'
  | 'to-gray-200'
  | 'from-gray-300'
  | 'via-gray-300'
  | 'to-gray-300'
  | 'from-gray-400'
  | 'via-gray-400'
  | 'to-gray-400'
  | 'from-gray-500'
  | 'via-gray-500'
  | 'to-gray-500'
  | 'from-gray-600'
  | 'via-gray-600'
  | 'to-gray-600'
  | 'from-gray-700'
  | 'via-gray-700'
  | 'to-gray-700'
  | 'from-gray-800'
  | 'via-gray-800'
  | 'to-gray-800'
  | 'from-gray-900'
  | 'via-gray-900'
  | 'to-gray-900'
  | 'from-red-100'
  | 'via-red-100'
  | 'to-red-100'
  | 'from-red-200'
  | 'via-red-200'
  | 'to-red-200'
  | 'from-red-300'
  | 'via-red-300'
  | 'to-red-300'
  | 'from-red-400'
  | 'via-red-400'
  | 'to-red-400'
  | 'from-red-500'
  | 'via-red-500'
  | 'to-red-500'
  | 'from-red-600'
  | 'via-red-600'
  | 'to-red-600'
  | 'from-red-700'
  | 'via-red-700'
  | 'to-red-700'
  | 'from-red-800'
  | 'via-red-800'
  | 'to-red-800'
  | 'from-red-900'
  | 'via-red-900'
  | 'to-red-900'
  | 'from-orange'
  | 'via-orange'
  | 'to-orange'
  | 'from-yellow'
  | 'via-yellow'
  | 'to-yellow'
  | 'from-green'
  | 'via-green'
  | 'to-green'
  | 'from-teal-100'
  | 'via-teal-100'
  | 'to-teal-100'
  | 'from-teal-200'
  | 'via-teal-200'
  | 'to-teal-200'
  | 'from-teal-300'
  | 'via-teal-300'
  | 'to-teal-300'
  | 'from-teal-400'
  | 'via-teal-400'
  | 'to-teal-400'
  | 'from-teal-500'
  | 'via-teal-500'
  | 'to-teal-500'
  | 'from-teal-600'
  | 'via-teal-600'
  | 'to-teal-600'
  | 'from-teal-700'
  | 'via-teal-700'
  | 'to-teal-700'
  | 'from-teal-800'
  | 'via-teal-800'
  | 'to-teal-800'
  | 'from-teal-900'
  | 'via-teal-900'
  | 'to-teal-900'
  | 'from-blue'
  | 'via-blue'
  | 'to-blue'
  | 'from-indigo-100'
  | 'via-indigo-100'
  | 'to-indigo-100'
  | 'from-indigo-200'
  | 'via-indigo-200'
  | 'to-indigo-200'
  | 'from-indigo-300'
  | 'via-indigo-300'
  | 'to-indigo-300'
  | 'from-indigo-400'
  | 'via-indigo-400'
  | 'to-indigo-400'
  | 'from-indigo-500'
  | 'via-indigo-500'
  | 'to-indigo-500'
  | 'from-indigo-600'
  | 'via-indigo-600'
  | 'to-indigo-600'
  | 'from-indigo-700'
  | 'via-indigo-700'
  | 'to-indigo-700'
  | 'from-indigo-800'
  | 'via-indigo-800'
  | 'to-indigo-800'
  | 'from-indigo-900'
  | 'via-indigo-900'
  | 'to-indigo-900'
  | 'from-purple'
  | 'via-purple'
  | 'to-purple'
  | 'from-pink'
  | 'via-pink'
  | 'to-pink'
  | 'from-soft-gold'
  | 'via-soft-gold'
  | 'to-soft-gold'
  | 'from-gold'
  | 'via-gold'
  | 'to-gold'
  | 'from-darkGold'
  | 'via-darkGold'
  | 'to-darkGold'
  | 'from-sand'
  | 'via-sand'
  | 'to-sand'
  | 'from-lightSand'
  | 'via-lightSand'
  | 'to-lightSand'
  | 'from-ecru'
  | 'via-ecru'
  | 'to-ecru'
  | 'from-lemon'
  | 'via-lemon'
  | 'to-lemon'
  | 'from-ink'
  | 'via-ink'
  | 'to-ink'
  | 'from-steel'
  | 'via-steel'
  | 'to-steel'
  | 'from-lightGray'
  | 'via-lightGray'
  | 'to-lightGray'
  | 'from-unassumingGray'
  | 'via-unassumingGray'
  | 'to-unassumingGray'
  | 'from-disabledTextGray'
  | 'via-disabledTextGray'
  | 'to-disabledTextGray'
  | 'from-lightRed'
  | 'via-lightRed'
  | 'to-lightRed'
  | 'from-mistakeRed'
  | 'via-mistakeRed'
  | 'to-mistakeRed'
  | 'from-grey'
  | 'via-grey'
  | 'to-grey'
  | 'from-olive-400'
  | 'via-olive-400'
  | 'to-olive-400'
  | 'from-olive-600'
  | 'via-olive-600'
  | 'to-olive-600'
  | 'from-olive-800'
  | 'via-olive-800'
  | 'to-olive-800';

export type TBackgrounds =
  | TBackgroundAttachment
  | TBackgroundClip
  | TBackgroundColor
  | TBackgroundOpacity
  | TBackgroundPosition
  | TBackgroundRepeat
  | TBackgroundSize
  | TBackgroundImage
  | TGradientColorStops

export type TBorderColor = 
  | 'border-transparent'
  | 'border-current'
  | 'border-black'
  | 'border-white'
  | 'border-gray-100'
  | 'border-gray-200'
  | 'border-gray-300'
  | 'border-gray-400'
  | 'border-gray-500'
  | 'border-gray-600'
  | 'border-gray-700'
  | 'border-gray-800'
  | 'border-gray-900'
  | 'border-red-100'
  | 'border-red-200'
  | 'border-red-300'
  | 'border-red-400'
  | 'border-red-500'
  | 'border-red-600'
  | 'border-red-700'
  | 'border-red-800'
  | 'border-red-900'
  | 'border-orange-100'
  | 'border-orange-200'
  | 'border-orange-300'
  | 'border-orange-400'
  | 'border-orange-500'
  | 'border-orange-600'
  | 'border-orange-700'
  | 'border-orange-800'
  | 'border-orange-900'
  | 'border-yellow-100'
  | 'border-yellow-200'
  | 'border-yellow-300'
  | 'border-yellow-400'
  | 'border-yellow-500'
  | 'border-yellow-600'
  | 'border-yellow-700'
  | 'border-yellow-800'
  | 'border-yellow-900'
  | 'border-green-100'
  | 'border-green-200'
  | 'border-green-300'
  | 'border-green-400'
  | 'border-green-500'
  | 'border-green-600'
  | 'border-green-700'
  | 'border-green-800'
  | 'border-green-900'
  | 'border-teal-100'
  | 'border-teal-200'
  | 'border-teal-300'
  | 'border-teal-400'
  | 'border-teal-500'
  | 'border-teal-600'
  | 'border-teal-700'
  | 'border-teal-800'
  | 'border-teal-900'
  | 'border-blue-100'
  | 'border-blue-200'
  | 'border-blue-300'
  | 'border-blue-400'
  | 'border-blue-500'
  | 'border-blue-600'
  | 'border-blue-700'
  | 'border-blue-800'
  | 'border-blue-900'
  | 'border-indigo-100'
  | 'border-indigo-200'
  | 'border-indigo-300'
  | 'border-indigo-400'
  | 'border-indigo-500'
  | 'border-indigo-600'
  | 'border-indigo-700'
  | 'border-indigo-800'
  | 'border-indigo-900'
  | 'border-purple-100'
  | 'border-purple-200'
  | 'border-purple-300'
  | 'border-purple-400'
  | 'border-purple-500'
  | 'border-purple-600'
  | 'border-purple-700'
  | 'border-purple-800'
  | 'border-purple-900'
  | 'border-pink-100'
  | 'border-pink-200'
  | 'border-pink-300'
  | 'border-pink-400'
  | 'border-pink-500'
  | 'border-pink-600'
  | 'border-pink-700'
  | 'border-pink-800'
  | 'border-pink-900';

export type TBorderOpacity = 
  | 'border-opacity-0'
  | 'border-opacity-25'
  | 'border-opacity-50'
  | 'border-opacity-75'
  | 'border-opacity-100';

export type TBorderStyle = 
  | 'border-solid'
  | 'border-dashed'
  | 'border-dotted'
  | 'border-double'
  | 'border-none';

export type TBorderWidth = 
  | 'border-t-0'
  | 'border-r-0'
  | 'border-b-0'
  | 'border-l-0'
  | 'border-0'
  | 'border-t-2'
  | 'border-r-2'
  | 'border-b-2'
  | 'border-l-2'
  | 'border-2'
  | 'border-t-4'
  | 'border-r-4'
  | 'border-b-4'
  | 'border-l-4'
  | 'border-4'
  | 'border-t-8'
  | 'border-r-8'
  | 'border-b-8'
  | 'border-l-8'
  | 'border-8'
  | 'border-t'
  | 'border-r'
  | 'border-b'
  | 'border-l'
  | 'border';

export type TBorderRadius = 
  | 'rounded-t-4'
  | 'rounded-r-4'
  | 'rounded-b-4'
  | 'rounded-l-4'
  | 'rounded-tr-4'
  | 'rounded-tl-4'
  | 'rounded-br-4'
  | 'rounded-bl-4'
  | 'rounded-4'
  | 'rounded-t-8'
  | 'rounded-r-8'
  | 'rounded-b-8'
  | 'rounded-l-8'
  | 'rounded-tr-8'
  | 'rounded-tl-8'
  | 'rounded-br-8'
  | 'rounded-bl-8'
  | 'rounded-8'
  | 'rounded-t-none'
  | 'rounded-r-none'
  | 'rounded-b-none'
  | 'rounded-l-none'
  | 'rounded-tr-none'
  | 'rounded-tl-none'
  | 'rounded-br-none'
  | 'rounded-bl-none'
  | 'rounded-none'
  | 'rounded-t-full'
  | 'rounded-r-full'
  | 'rounded-b-full'
  | 'rounded-l-full'
  | 'rounded-tr-full'
  | 'rounded-tl-full'
  | 'rounded-br-full'
  | 'rounded-bl-full'
  | 'rounded-full';

export type TDivideWidth = 
  | 'divide-x-0'
  | 'divide-y-0'
  | 'divide-x-2'
  | 'divide-y-2'
  | 'divide-x-4'
  | 'divide-y-4'
  | 'divide-x-8'
  | 'divide-y-8'
  | 'divide-x'
  | 'divide-y'
  | 'divide-x-reverse'
  | 'divide-y-reverse';

export type TDivideOpacity = 
  | 'divide-opacity-0'
  | 'divide-opacity-25'
  | 'divide-opacity-50'
  | 'divide-opacity-75'
  | 'divide-opacity-100';

export type TDivideColor = 
  | 'divide-transparent'
  | 'divide-current'
  | 'divide-black'
  | 'divide-white'
  | 'divide-gray-100'
  | 'divide-gray-200'
  | 'divide-gray-300'
  | 'divide-gray-400'
  | 'divide-gray-500'
  | 'divide-gray-600'
  | 'divide-gray-700'
  | 'divide-gray-800'
  | 'divide-gray-900'
  | 'divide-red-100'
  | 'divide-red-200'
  | 'divide-red-300'
  | 'divide-red-400'
  | 'divide-red-500'
  | 'divide-red-600'
  | 'divide-red-700'
  | 'divide-red-800'
  | 'divide-red-900'
  | 'divide-orange-100'
  | 'divide-orange-200'
  | 'divide-orange-300'
  | 'divide-orange-400'
  | 'divide-orange-500'
  | 'divide-orange-600'
  | 'divide-orange-700'
  | 'divide-orange-800'
  | 'divide-orange-900'
  | 'divide-yellow-100'
  | 'divide-yellow-200'
  | 'divide-yellow-300'
  | 'divide-yellow-400'
  | 'divide-yellow-500'
  | 'divide-yellow-600'
  | 'divide-yellow-700'
  | 'divide-yellow-800'
  | 'divide-yellow-900'
  | 'divide-green-100'
  | 'divide-green-200'
  | 'divide-green-300'
  | 'divide-green-400'
  | 'divide-green-500'
  | 'divide-green-600'
  | 'divide-green-700'
  | 'divide-green-800'
  | 'divide-green-900'
  | 'divide-teal-100'
  | 'divide-teal-200'
  | 'divide-teal-300'
  | 'divide-teal-400'
  | 'divide-teal-500'
  | 'divide-teal-600'
  | 'divide-teal-700'
  | 'divide-teal-800'
  | 'divide-teal-900'
  | 'divide-blue-100'
  | 'divide-blue-200'
  | 'divide-blue-300'
  | 'divide-blue-400'
  | 'divide-blue-500'
  | 'divide-blue-600'
  | 'divide-blue-700'
  | 'divide-blue-800'
  | 'divide-blue-900'
  | 'divide-indigo-100'
  | 'divide-indigo-200'
  | 'divide-indigo-300'
  | 'divide-indigo-400'
  | 'divide-indigo-500'
  | 'divide-indigo-600'
  | 'divide-indigo-700'
  | 'divide-indigo-800'
  | 'divide-indigo-900'
  | 'divide-purple-100'
  | 'divide-purple-200'
  | 'divide-purple-300'
  | 'divide-purple-400'
  | 'divide-purple-500'
  | 'divide-purple-600'
  | 'divide-purple-700'
  | 'divide-purple-800'
  | 'divide-purple-900'
  | 'divide-pink-100'
  | 'divide-pink-200'
  | 'divide-pink-300'
  | 'divide-pink-400'
  | 'divide-pink-500'
  | 'divide-pink-600'
  | 'divide-pink-700'
  | 'divide-pink-800'
  | 'divide-pink-900';

export type TDivideStyle = 
  | 'divide-solid'
  | 'divide-dashed'
  | 'divide-dotted'
  | 'divide-double'
  | 'divide-none';

export type TBorders =
  | TBorderColor
  | TBorderOpacity
  | TBorderStyle
  | TBorderWidth
  | TBorderRadius
  | TDivideWidth
  | TDivideOpacity
  | TDivideColor
  | TDivideStyle

export type TBorderCollapse = 
  | 'border-collapse'
  | 'border-separate';

export type TTableLayout = 
  | 'table-auto'
  | 'table-fixed';

export type TTables =
  | TBorderCollapse
  | TTableLayout

export type TBoxShadow = 
  | 'shadow-xs'
  | 'shadow-sm'
  | 'shadow'
  | 'shadow-md'
  | 'shadow-lg'
  | 'shadow-xl'
  | 'shadow-2xl'
  | 'shadow-inner'
  | 'shadow-outline'
  | 'shadow-none'
  | 'shadow-outlineGold'
  | 'shadow-outlineRed'
  | 'shadow-omni'
  | 'shadow-omni-small';

export type TOpacity = 
  | 'opacity-0'
  | 'opacity-25'
  | 'opacity-50'
  | 'opacity-75'
  | 'opacity-100';

export type TEffects =
  | TBoxShadow
  | TOpacity

export type TTransitionProperty = 
  | 'transition-none'
  | 'transition-all'
  | 'transition'
  | 'transition-colors'
  | 'transition-opacity'
  | 'transition-shadow'
  | 'transition-transform';

export type TTransitionDuration = 
  | 'duration-75'
  | 'duration-100'
  | 'duration-150'
  | 'duration-200'
  | 'duration-300'
  | 'duration-500'
  | 'duration-700'
  | 'duration-1000';

export type TTransitionTimingFunction = 
  | 'ease-linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out';

export type TTransitionDelay = 
  | 'delay-75'
  | 'delay-100'
  | 'delay-150'
  | 'delay-200'
  | 'delay-300'
  | 'delay-500'
  | 'delay-700'
  | 'delay-1000';

export type TAnimation = 
  | 'animate-none'
  | 'animate-spin'
  | 'animate-ping'
  | 'animate-pulse'
  | 'animate-bounce';

export type TTransitionsAndAnimations =
  | TTransitionProperty
  | TTransitionDuration
  | TTransitionTimingFunction
  | TTransitionDelay
  | TAnimation

export type TFlexDirection = 
  | 'flex-row'
  | 'flex-row-reverse'
  | 'flex-col'
  | 'flex-col-reverse';

export type TFlexWrap = 
  | 'flex-no-wrap'
  | 'flex-wrap'
  | 'flex-wrap-reverse';

export type TAlignItems = 
  | 'items-stretch'
  | 'items-start'
  | 'items-center'
  | 'items-end'
  | 'items-baseline';

export type TAlignContent = 
  | 'content-start'
  | 'content-center'
  | 'content-end'
  | 'content-between'
  | 'content-around';

export type TAlignSelf = 
  | 'self-auto'
  | 'self-start'
  | 'self-center'
  | 'self-end'
  | 'self-stretch';

export type TPlaceContent = 
  | 'place-content-start'
  | 'place-content-center'
  | 'place-content-end'
  | 'place-content-between'
  | 'place-content-around'
  | 'place-content-evenly'
  | 'place-content-stretch';

export type TPlaceItems = 
  | 'place-items-auto'
  | 'place-items-start'
  | 'place-items-center'
  | 'place-items-end'
  | 'place-items-stretch';

export type TPlaceSelf = 
  | 'place-self-auto'
  | 'place-self-start'
  | 'place-self-center'
  | 'place-self-end'
  | 'place-self-stretch';

export type TJustifyContent = 
  | 'justify-start'
  | 'justify-center'
  | 'justify-end'
  | 'justify-between'
  | 'justify-around';

export type TJustifyItems = 
  | 'justify-items-auto'
  | 'justify-items-start'
  | 'justify-items-center'
  | 'justify-items-end'
  | 'justify-items-stretch';

export type TJustifySelf = 
  | 'justify-self-auto'
  | 'justify-self-start'
  | 'justify-self-center'
  | 'justify-self-end'
  | 'justify-self-stretch';

export type TFlex = 
  | 'flex-initial'
  | 'flex-1'
  | 'flex-auto'
  | 'flex-none';

export type TFlexGrow = 
  | 'flex-grow-0'
  | 'flex-grow';

export type TFlexShrink = 
  | 'flex-shrink-0'
  | 'flex-shrink';

export type TOrder = 
  | 'order-1'
  | 'order-2'
  | 'order-3'
  | 'order-4'
  | 'order-5'
  | 'order-6'
  | 'order-7'
  | 'order-8'
  | 'order-9'
  | 'order-10'
  | 'order-11'
  | 'order-12'
  | 'order-first'
  | 'order-last'
  | 'order-none';

export type TFlexBox =
  | TFlexDirection
  | TFlexWrap
  | TAlignItems
  | TAlignContent
  | TAlignSelf
  | TPlaceContent
  | TPlaceItems
  | TPlaceSelf
  | TJustifyContent
  | TJustifyItems
  | TJustifySelf
  | TFlex
  | TFlexGrow
  | TFlexShrink
  | TOrder

export type TGridTemplateColumns = 
  | 'grid-cols-1'
  | 'grid-cols-2'
  | 'grid-cols-3'
  | 'grid-cols-4'
  | 'grid-cols-5'
  | 'grid-cols-6'
  | 'grid-cols-7'
  | 'grid-cols-8'
  | 'grid-cols-9'
  | 'grid-cols-10'
  | 'grid-cols-11'
  | 'grid-cols-12'
  | 'grid-cols-none';

export type TGridAutoColumns = 
  | 'auto-cols-auto'
  | 'auto-cols-min'
  | 'auto-cols-max'
  | 'auto-cols-fr';

export type TGridColumn = 
  | 'col-auto'
  | 'col-span-1'
  | 'col-span-2'
  | 'col-span-3'
  | 'col-span-4'
  | 'col-span-5'
  | 'col-span-6'
  | 'col-span-7'
  | 'col-span-8'
  | 'col-span-9'
  | 'col-span-10'
  | 'col-span-11'
  | 'col-span-12'
  | 'col-span-full';

export type TGridColumnStart = 
  | 'col-start-1'
  | 'col-start-2'
  | 'col-start-3'
  | 'col-start-4'
  | 'col-start-5'
  | 'col-start-6'
  | 'col-start-7'
  | 'col-start-8'
  | 'col-start-9'
  | 'col-start-10'
  | 'col-start-11'
  | 'col-start-12'
  | 'col-start-13'
  | 'col-start-auto';

export type TGridColumnEnd = 
  | 'col-end-1'
  | 'col-end-2'
  | 'col-end-3'
  | 'col-end-4'
  | 'col-end-5'
  | 'col-end-6'
  | 'col-end-7'
  | 'col-end-8'
  | 'col-end-9'
  | 'col-end-10'
  | 'col-end-11'
  | 'col-end-12'
  | 'col-end-13'
  | 'col-end-auto';

export type TGridTemplateRows = 
  | 'grid-rows-1'
  | 'grid-rows-2'
  | 'grid-rows-3'
  | 'grid-rows-4'
  | 'grid-rows-5'
  | 'grid-rows-6'
  | 'grid-rows-none';

export type TGridAutoRows = 
  | 'auto-rows-auto'
  | 'auto-rows-min'
  | 'auto-rows-max'
  | 'auto-rows-fr';

export type TGridRow = 
  | 'row-auto'
  | 'row-span-1'
  | 'row-span-2'
  | 'row-span-3'
  | 'row-span-4'
  | 'row-span-5'
  | 'row-span-6'
  | 'row-span-full';

export type TGridRowStart = 
  | 'row-start-1'
  | 'row-start-2'
  | 'row-start-3'
  | 'row-start-4'
  | 'row-start-5'
  | 'row-start-6'
  | 'row-start-7'
  | 'row-start-auto';

export type TGridRowEnd = 
  | 'row-end-1'
  | 'row-end-2'
  | 'row-end-3'
  | 'row-end-4'
  | 'row-end-5'
  | 'row-end-6'
  | 'row-end-7'
  | 'row-end-auto';

export type TGap = 
  | 'gap-0'
  | 'gap-1'
  | 'gap-2'
  | 'gap-4'
  | 'gap-8'
  | 'gap-10'
  | 'gap-12'
  | 'gap-16'
  | 'gap-20'
  | 'gap-24'
  | 'gap-32'
  | 'gap-48'
  | 'gap-80'
  | 'gap-100'
  | 'gap-160'
  | 'gap-200'
  | 'gap-280'
  | 'gap-y-0'
  | 'gap-y-1'
  | 'gap-y-2'
  | 'gap-y-4'
  | 'gap-y-8'
  | 'gap-y-10'
  | 'gap-y-12'
  | 'gap-y-16'
  | 'gap-y-20'
  | 'gap-y-24'
  | 'gap-y-32'
  | 'gap-y-48'
  | 'gap-y-80'
  | 'gap-y-100'
  | 'gap-y-160'
  | 'gap-y-200'
  | 'gap-y-280'
  | 'gap-x-0'
  | 'gap-x-1'
  | 'gap-x-2'
  | 'gap-x-4'
  | 'gap-x-8'
  | 'gap-x-10'
  | 'gap-x-12'
  | 'gap-x-16'
  | 'gap-x-20'
  | 'gap-x-24'
  | 'gap-x-32'
  | 'gap-x-48'
  | 'gap-x-80'
  | 'gap-x-100'
  | 'gap-x-160'
  | 'gap-x-200'
  | 'gap-x-280'
  | 'row-gap-0'
  | 'row-gap-1'
  | 'row-gap-2'
  | 'row-gap-4'
  | 'row-gap-8'
  | 'row-gap-10'
  | 'row-gap-12'
  | 'row-gap-16'
  | 'row-gap-20'
  | 'row-gap-24'
  | 'row-gap-32'
  | 'row-gap-48'
  | 'row-gap-80'
  | 'row-gap-100'
  | 'row-gap-160'
  | 'row-gap-200'
  | 'row-gap-280'
  | 'col-gap-0'
  | 'col-gap-1'
  | 'col-gap-2'
  | 'col-gap-4'
  | 'col-gap-8'
  | 'col-gap-10'
  | 'col-gap-12'
  | 'col-gap-16'
  | 'col-gap-20'
  | 'col-gap-24'
  | 'col-gap-32'
  | 'col-gap-48'
  | 'col-gap-80'
  | 'col-gap-100'
  | 'col-gap-160'
  | 'col-gap-200'
  | 'col-gap-280';

export type TGridAutoFlow = 
  | 'grid-flow-row'
  | 'grid-flow-col'
  | 'grid-flow-row-dense'
  | 'grid-flow-col-dense';

export type TGrid =
  | TGridTemplateColumns
  | TGridAutoColumns
  | TGridColumn
  | TGridColumnStart
  | TGridColumnEnd
  | TGridTemplateRows
  | TGridAutoRows
  | TGridRow
  | TGridRowStart
  | TGridRowEnd
  | TGap
  | TGridAutoFlow

export type TPadding = 
  | 'p-0'
  | 'p-1'
  | 'p-2'
  | 'p-4'
  | 'p-8'
  | 'p-10'
  | 'p-12'
  | 'p-16'
  | 'p-20'
  | 'p-24'
  | 'p-32'
  | 'p-48'
  | 'p-80'
  | 'p-100'
  | 'p-160'
  | 'p-200'
  | 'p-280'
  | 'py-0'
  | 'py-1'
  | 'py-2'
  | 'py-4'
  | 'py-8'
  | 'py-10'
  | 'py-12'
  | 'py-16'
  | 'py-20'
  | 'py-24'
  | 'py-32'
  | 'py-48'
  | 'py-80'
  | 'py-100'
  | 'py-160'
  | 'py-200'
  | 'py-280'
  | 'px-0'
  | 'px-1'
  | 'px-2'
  | 'px-4'
  | 'px-8'
  | 'px-10'
  | 'px-12'
  | 'px-16'
  | 'px-20'
  | 'px-24'
  | 'px-32'
  | 'px-48'
  | 'px-80'
  | 'px-100'
  | 'px-160'
  | 'px-200'
  | 'px-280'
  | 'pt-0'
  | 'pt-1'
  | 'pt-2'
  | 'pt-4'
  | 'pt-8'
  | 'pt-10'
  | 'pt-12'
  | 'pt-16'
  | 'pt-20'
  | 'pt-24'
  | 'pt-32'
  | 'pt-48'
  | 'pt-80'
  | 'pt-100'
  | 'pt-160'
  | 'pt-200'
  | 'pt-280'
  | 'pr-0'
  | 'pr-1'
  | 'pr-2'
  | 'pr-4'
  | 'pr-8'
  | 'pr-10'
  | 'pr-12'
  | 'pr-16'
  | 'pr-20'
  | 'pr-24'
  | 'pr-32'
  | 'pr-48'
  | 'pr-80'
  | 'pr-100'
  | 'pr-160'
  | 'pr-200'
  | 'pr-280'
  | 'pb-0'
  | 'pb-1'
  | 'pb-2'
  | 'pb-4'
  | 'pb-8'
  | 'pb-10'
  | 'pb-12'
  | 'pb-16'
  | 'pb-20'
  | 'pb-24'
  | 'pb-32'
  | 'pb-48'
  | 'pb-80'
  | 'pb-100'
  | 'pb-160'
  | 'pb-200'
  | 'pb-280'
  | 'pl-0'
  | 'pl-1'
  | 'pl-2'
  | 'pl-4'
  | 'pl-8'
  | 'pl-10'
  | 'pl-12'
  | 'pl-16'
  | 'pl-20'
  | 'pl-24'
  | 'pl-32'
  | 'pl-48'
  | 'pl-80'
  | 'pl-100'
  | 'pl-160'
  | 'pl-200'
  | 'pl-280';

export type TMargin = 
  | 'm-0'
  | 'm-1'
  | 'm-2'
  | 'm-4'
  | 'm-8'
  | 'm-10'
  | 'm-12'
  | 'm-16'
  | 'm-20'
  | 'm-24'
  | 'm-32'
  | 'm-48'
  | 'm-80'
  | 'm-100'
  | 'm-160'
  | 'm-200'
  | 'm-280'
  | 'm-auto'
  | '-m-0'
  | '-m-1'
  | '-m-2'
  | '-m-4'
  | '-m-8'
  | '-m-10'
  | '-m-12'
  | '-m-16'
  | '-m-20'
  | '-m-24'
  | '-m-32'
  | '-m-48'
  | '-m-80'
  | '-m-100'
  | '-m-160'
  | '-m-200'
  | '-m-280'
  | 'my-0'
  | 'my-1'
  | 'my-2'
  | 'my-4'
  | 'my-8'
  | 'my-10'
  | 'my-12'
  | 'my-16'
  | 'my-20'
  | 'my-24'
  | 'my-32'
  | 'my-48'
  | 'my-80'
  | 'my-100'
  | 'my-160'
  | 'my-200'
  | 'my-280'
  | 'my-auto'
  | '-my-0'
  | '-my-1'
  | '-my-2'
  | '-my-4'
  | '-my-8'
  | '-my-10'
  | '-my-12'
  | '-my-16'
  | '-my-20'
  | '-my-24'
  | '-my-32'
  | '-my-48'
  | '-my-80'
  | '-my-100'
  | '-my-160'
  | '-my-200'
  | '-my-280'
  | 'mx-0'
  | 'mx-1'
  | 'mx-2'
  | 'mx-4'
  | 'mx-8'
  | 'mx-10'
  | 'mx-12'
  | 'mx-16'
  | 'mx-20'
  | 'mx-24'
  | 'mx-32'
  | 'mx-48'
  | 'mx-80'
  | 'mx-100'
  | 'mx-160'
  | 'mx-200'
  | 'mx-280'
  | 'mx-auto'
  | '-mx-0'
  | '-mx-1'
  | '-mx-2'
  | '-mx-4'
  | '-mx-8'
  | '-mx-10'
  | '-mx-12'
  | '-mx-16'
  | '-mx-20'
  | '-mx-24'
  | '-mx-32'
  | '-mx-48'
  | '-mx-80'
  | '-mx-100'
  | '-mx-160'
  | '-mx-200'
  | '-mx-280'
  | 'mt-0'
  | 'mt-1'
  | 'mt-2'
  | 'mt-4'
  | 'mt-8'
  | 'mt-10'
  | 'mt-12'
  | 'mt-16'
  | 'mt-20'
  | 'mt-24'
  | 'mt-32'
  | 'mt-48'
  | 'mt-80'
  | 'mt-100'
  | 'mt-160'
  | 'mt-200'
  | 'mt-280'
  | 'mt-auto'
  | '-mt-0'
  | '-mt-1'
  | '-mt-2'
  | '-mt-4'
  | '-mt-8'
  | '-mt-10'
  | '-mt-12'
  | '-mt-16'
  | '-mt-20'
  | '-mt-24'
  | '-mt-32'
  | '-mt-48'
  | '-mt-80'
  | '-mt-100'
  | '-mt-160'
  | '-mt-200'
  | '-mt-280'
  | 'mr-0'
  | 'mr-1'
  | 'mr-2'
  | 'mr-4'
  | 'mr-8'
  | 'mr-10'
  | 'mr-12'
  | 'mr-16'
  | 'mr-20'
  | 'mr-24'
  | 'mr-32'
  | 'mr-48'
  | 'mr-80'
  | 'mr-100'
  | 'mr-160'
  | 'mr-200'
  | 'mr-280'
  | 'mr-auto'
  | '-mr-0'
  | '-mr-1'
  | '-mr-2'
  | '-mr-4'
  | '-mr-8'
  | '-mr-10'
  | '-mr-12'
  | '-mr-16'
  | '-mr-20'
  | '-mr-24'
  | '-mr-32'
  | '-mr-48'
  | '-mr-80'
  | '-mr-100'
  | '-mr-160'
  | '-mr-200'
  | '-mr-280'
  | 'mb-0'
  | 'mb-1'
  | 'mb-2'
  | 'mb-4'
  | 'mb-8'
  | 'mb-10'
  | 'mb-12'
  | 'mb-16'
  | 'mb-20'
  | 'mb-24'
  | 'mb-32'
  | 'mb-48'
  | 'mb-80'
  | 'mb-100'
  | 'mb-160'
  | 'mb-200'
  | 'mb-280'
  | 'mb-auto'
  | '-mb-0'
  | '-mb-1'
  | '-mb-2'
  | '-mb-4'
  | '-mb-8'
  | '-mb-10'
  | '-mb-12'
  | '-mb-16'
  | '-mb-20'
  | '-mb-24'
  | '-mb-32'
  | '-mb-48'
  | '-mb-80'
  | '-mb-100'
  | '-mb-160'
  | '-mb-200'
  | '-mb-280'
  | 'ml-0'
  | 'ml-1'
  | 'ml-2'
  | 'ml-4'
  | 'ml-8'
  | 'ml-10'
  | 'ml-12'
  | 'ml-16'
  | 'ml-20'
  | 'ml-24'
  | 'ml-32'
  | 'ml-48'
  | 'ml-80'
  | 'ml-100'
  | 'ml-160'
  | 'ml-200'
  | 'ml-280'
  | 'ml-auto'
  | '-ml-0'
  | '-ml-1'
  | '-ml-2'
  | '-ml-4'
  | '-ml-8'
  | '-ml-10'
  | '-ml-12'
  | '-ml-16'
  | '-ml-20'
  | '-ml-24'
  | '-ml-32'
  | '-ml-48'
  | '-ml-80'
  | '-ml-100'
  | '-ml-160'
  | '-ml-200'
  | '-ml-280';

export type TSpace = 
  | 'space-x-0'
  | 'space-x-1'
  | 'space-x-2'
  | 'space-x-4'
  | 'space-x-8'
  | 'space-x-10'
  | 'space-x-12'
  | 'space-x-16'
  | 'space-x-20'
  | 'space-x-24'
  | 'space-x-32'
  | 'space-x-48'
  | 'space-x-80'
  | 'space-x-100'
  | 'space-x-160'
  | 'space-x-200'
  | 'space-x-280'
  | '-space-x-0'
  | '-space-x-1'
  | '-space-x-2'
  | '-space-x-4'
  | '-space-x-8'
  | '-space-x-10'
  | '-space-x-12'
  | '-space-x-16'
  | '-space-x-20'
  | '-space-x-24'
  | '-space-x-32'
  | '-space-x-48'
  | '-space-x-80'
  | '-space-x-100'
  | '-space-x-160'
  | '-space-x-200'
  | '-space-x-280'
  | 'space-x-reverse'
  | 'space-y-0'
  | 'space-y-1'
  | 'space-y-2'
  | 'space-y-4'
  | 'space-y-8'
  | 'space-y-10'
  | 'space-y-12'
  | 'space-y-16'
  | 'space-y-20'
  | 'space-y-24'
  | 'space-y-32'
  | 'space-y-48'
  | 'space-y-80'
  | 'space-y-100'
  | 'space-y-160'
  | 'space-y-200'
  | 'space-y-280'
  | '-space-y-0'
  | '-space-y-1'
  | '-space-y-2'
  | '-space-y-4'
  | '-space-y-8'
  | '-space-y-10'
  | '-space-y-12'
  | '-space-y-16'
  | '-space-y-20'
  | '-space-y-24'
  | '-space-y-32'
  | '-space-y-48'
  | '-space-y-80'
  | '-space-y-100'
  | '-space-y-160'
  | '-space-y-200'
  | '-space-y-280'
  | 'space-y-reverse';

export type TSpacing =
  | TPadding
  | TMargin
  | TSpace

export type TAppearance = 
  | 'appearance-none';

export type TCursor = 
  | 'cursor-auto'
  | 'cursor-default'
  | 'cursor-pointer'
  | 'cursor-wait'
  | 'cursor-text'
  | 'cursor-move'
  | 'cursor-not-allowed';

export type TOutline = 
  | 'outline-none'
  | 'outline-white'
  | 'outline-black';

export type TPointerEvents = 
  | 'pointer-events-none'
  | 'pointer-events-auto';

export type TResize = 
  | 'resize-none'
  | 'resize'
  | 'resize-y'
  | 'resize-x';

export type TUserSelect = 
  | 'select-none'
  | 'select-text'
  | 'select-all'
  | 'select-auto';

export type TInteractivity =
  | TAppearance
  | TCursor
  | TOutline
  | TPointerEvents
  | TResize
  | TUserSelect

export type TDisplay = 
  | 'block'
  | 'inline-block'
  | 'inline'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'table'
  | 'table-row'
  | 'table-cell'
  | 'contents'
  | 'hidden';

export type TBoxSizing = 
  | 'box-border'
  | 'box-content';

export type TContainer = 
  | 'container';

export type TFloat = 
  | 'float-right'
  | 'float-left'
  | 'float-none'
  | 'clearfix';

export type TClear = 
  | 'clear-left'
  | 'clear-right'
  | 'clear-both'
  | 'clear-none';

export type TObjectFit = 
  | 'object-contain'
  | 'object-cover'
  | 'object-fill'
  | 'object-none'
  | 'object-scale-down';

export type TObjectPosition = 
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top';

export type TOverflow = 
  | 'overflow-auto'
  | 'overflow-hidden'
  | 'overflow-visible'
  | 'overflow-scroll'
  | 'overflow-x-auto'
  | 'overflow-y-auto'
  | 'overflow-x-hidden'
  | 'overflow-y-hidden'
  | 'overflow-x-visible'
  | 'overflow-y-visible'
  | 'overflow-x-scroll'
  | 'overflow-y-scroll'
  | 'scrolling-touch'
  | 'scrolling-auto';

export type TOverscrollBehavior = 
  | 'overscroll-auto'
  | 'overscroll-contain'
  | 'overscroll-none'
  | 'overscroll-y-auto'
  | 'overscroll-y-contain'
  | 'overscroll-y-none'
  | 'overscroll-x-auto'
  | 'overscroll-x-contain'
  | 'overscroll-x-none';

export type TPosition = 
  | 'static'
  | 'fixed'
  | 'absolute'
  | 'relative'
  | 'sticky';

export type TInset = 
  | 'inset-0'
  | 'inset-x-0'
  | 'inset-y-0'
  | 'top-0'
  | 'right-0'
  | 'bottom-0'
  | 'left-0'
  | 'inset-16'
  | 'inset-x-16'
  | 'inset-y-16'
  | 'top-16'
  | 'right-16'
  | 'bottom-16'
  | 'left-16'
  | 'inset-auto'
  | 'inset-x-auto'
  | 'inset-y-auto'
  | 'top-auto'
  | 'right-auto'
  | 'bottom-auto'
  | 'left-auto';

export type TVisibility = 
  | 'visible'
  | 'invisible';

export type TZIndex = 
  | 'z-0'
  | 'z-10'
  | 'z-20'
  | 'z-30'
  | 'z-40'
  | 'z-50'
  | 'z-auto'
  | '-z-1';

export type TLayout =
  | TDisplay
  | TBoxSizing
  | TContainer
  | TFloat
  | TClear
  | TObjectFit
  | TObjectPosition
  | TOverflow
  | TOverscrollBehavior
  | TPosition
  | TInset
  | TVisibility
  | TZIndex

export type TWidth = 
  | 'w-0'
  | 'w-1'
  | 'w-2'
  | 'w-4'
  | 'w-8'
  | 'w-10'
  | 'w-12'
  | 'w-16'
  | 'w-20'
  | 'w-24'
  | 'w-32'
  | 'w-48'
  | 'w-80'
  | 'w-100'
  | 'w-160'
  | 'w-200'
  | 'w-280'
  | 'w-auto'
  | 'w-1/2'
  | 'w-1/3'
  | 'w-2/3'
  | 'w-1/4'
  | 'w-2/4'
  | 'w-3/4'
  | 'w-1/5'
  | 'w-2/5'
  | 'w-3/5'
  | 'w-4/5'
  | 'w-1/6'
  | 'w-2/6'
  | 'w-3/6'
  | 'w-4/6'
  | 'w-5/6'
  | 'w-1/12'
  | 'w-2/12'
  | 'w-3/12'
  | 'w-4/12'
  | 'w-5/12'
  | 'w-6/12'
  | 'w-7/12'
  | 'w-8/12'
  | 'w-9/12'
  | 'w-10/12'
  | 'w-11/12'
  | 'w-full'
  | 'w-screen';

export type TMinWidth = 
  | 'min-w-0'
  | 'min-w-full';

export type TMaxWidth = 
  | 'max-w-none'
  | 'max-w-xs'
  | 'max-w-sm'
  | 'max-w-md'
  | 'max-w-lg'
  | 'max-w-xl'
  | 'max-w-2xl'
  | 'max-w-3xl'
  | 'max-w-4xl'
  | 'max-w-5xl'
  | 'max-w-6xl'
  | 'max-w-full'
  | 'max-w-screen-container'
  | 'max-w-screen-container-mid'
  | 'max-w-screen-container-large';

export type THeight = 
  | 'h-0'
  | 'h-1'
  | 'h-2'
  | 'h-4'
  | 'h-8'
  | 'h-10'
  | 'h-12'
  | 'h-16'
  | 'h-20'
  | 'h-24'
  | 'h-32'
  | 'h-48'
  | 'h-80'
  | 'h-100'
  | 'h-160'
  | 'h-200'
  | 'h-280'
  | 'h-auto'
  | 'h-full'
  | 'h-screen';

export type TMinHeight = 
  | 'min-h-0'
  | 'min-h-full'
  | 'min-h-screen';

export type TMaxHeight = 
  | 'max-h-full'
  | 'max-h-screen';

export type TSizing =
  | TWidth
  | TMinWidth
  | TMaxWidth
  | THeight
  | TMinHeight
  | TMaxHeight

export type TFill = 
  | 'fill-current';

export type TStroke = 
  | 'stroke-current';

export type TStrokeWidth = 
  | 'stroke-0'
  | 'stroke-1'
  | 'stroke-2';

export type TSVG =
  | TFill
  | TStroke
  | TStrokeWidth

export type TScale = 
  | 'scale-0'
  | 'scale-50'
  | 'scale-75'
  | 'scale-90'
  | 'scale-95'
  | 'scale-100'
  | 'scale-105'
  | 'scale-110'
  | 'scale-125'
  | 'scale-150'
  | 'scale-x-0'
  | 'scale-x-50'
  | 'scale-x-75'
  | 'scale-x-90'
  | 'scale-x-95'
  | 'scale-x-100'
  | 'scale-x-105'
  | 'scale-x-110'
  | 'scale-x-125'
  | 'scale-x-150'
  | 'scale-y-0'
  | 'scale-y-50'
  | 'scale-y-75'
  | 'scale-y-90'
  | 'scale-y-95'
  | 'scale-y-100'
  | 'scale-y-105'
  | 'scale-y-110'
  | 'scale-y-125'
  | 'scale-y-150';

export type TRotate = 
  | 'rotate-0'
  | 'rotate-1'
  | 'rotate-2'
  | 'rotate-3'
  | 'rotate-6'
  | 'rotate-12'
  | 'rotate-45'
  | 'rotate-90'
  | 'rotate-180'
  | '-rotate-180'
  | '-rotate-90'
  | '-rotate-45'
  | '-rotate-12'
  | '-rotate-6'
  | '-rotate-3'
  | '-rotate-2'
  | '-rotate-1';

export type TTranslate = 
  | 'translate-x-0'
  | 'translate-x-1'
  | 'translate-x-2'
  | 'translate-x-4'
  | 'translate-x-8'
  | 'translate-x-10'
  | 'translate-x-12'
  | 'translate-x-16'
  | 'translate-x-20'
  | 'translate-x-24'
  | 'translate-x-32'
  | 'translate-x-48'
  | 'translate-x-80'
  | 'translate-x-100'
  | 'translate-x-160'
  | 'translate-x-200'
  | 'translate-x-280'
  | '-translate-x-0'
  | '-translate-x-1'
  | '-translate-x-2'
  | '-translate-x-4'
  | '-translate-x-8'
  | '-translate-x-10'
  | '-translate-x-12'
  | '-translate-x-16'
  | '-translate-x-20'
  | '-translate-x-24'
  | '-translate-x-32'
  | '-translate-x-48'
  | '-translate-x-80'
  | '-translate-x-100'
  | '-translate-x-160'
  | '-translate-x-200'
  | '-translate-x-280'
  | '-translate-x-full'
  | '-translate-x-1/2'
  | 'translate-x-1/2'
  | 'translate-x-full'
  | 'translate-y-0'
  | 'translate-y-1'
  | 'translate-y-2'
  | 'translate-y-4'
  | 'translate-y-8'
  | 'translate-y-10'
  | 'translate-y-12'
  | 'translate-y-16'
  | 'translate-y-20'
  | 'translate-y-24'
  | 'translate-y-32'
  | 'translate-y-48'
  | 'translate-y-80'
  | 'translate-y-100'
  | 'translate-y-160'
  | 'translate-y-200'
  | 'translate-y-280'
  | '-translate-y-0'
  | '-translate-y-1'
  | '-translate-y-2'
  | '-translate-y-4'
  | '-translate-y-8'
  | '-translate-y-10'
  | '-translate-y-12'
  | '-translate-y-16'
  | '-translate-y-20'
  | '-translate-y-24'
  | '-translate-y-32'
  | '-translate-y-48'
  | '-translate-y-80'
  | '-translate-y-100'
  | '-translate-y-160'
  | '-translate-y-200'
  | '-translate-y-280'
  | '-translate-y-full'
  | '-translate-y-1/2'
  | 'translate-y-1/2'
  | 'translate-y-full';

export type TSkew = 
  | 'skew-x-0'
  | 'skew-x-1'
  | 'skew-x-2'
  | 'skew-x-3'
  | 'skew-x-6'
  | 'skew-x-12'
  | '-skew-x-12'
  | '-skew-x-6'
  | '-skew-x-3'
  | '-skew-x-2'
  | '-skew-x-1'
  | 'skew-y-0'
  | 'skew-y-1'
  | 'skew-y-2'
  | 'skew-y-3'
  | 'skew-y-6'
  | 'skew-y-12'
  | '-skew-y-12'
  | '-skew-y-6'
  | '-skew-y-3'
  | '-skew-y-2'
  | '-skew-y-1';

export type TTransformOrigin = 
  | 'origin-0'
  | 'origin-center'
  | 'origin-top'
  | 'origin-top-right'
  | 'origin-right'
  | 'origin-bottom-right'
  | 'origin-bottom'
  | 'origin-bottom-left'
  | 'origin-left'
  | 'origin-top-left';

export type TTransforms =
  | TScale
  | TRotate
  | TTranslate
  | TSkew
  | TTransformOrigin

export type TFontFamily = 
  | 'font-montserrat';

export type TFontSize = 
  | 'text-xs'
  | 'text-sm'
  | 'text-base'
  | 'text-l'
  | 'text-xl';

export type TFontSmoothing = 
  | 'antialiased'
  | 'subpixel-antialiased';

export type TFontStyle = 
  | 'italic'
  | 'non-italic';

export type TFontWeight = 
  | 'font-normal'
  | 'font-medium'
  | 'font-bold'
  | 'font-extrabold';

export type TFontVariantNumeric = 
  | 'normal-nums'
  | 'ordinal'
  | 'slashed-zero'
  | 'lining-nums'
  | 'oldstyle-nums'
  | 'proportional-nums'
  | 'tabular-nums'
  | 'diagonal-fractions'
  | 'stacked-fractions';

export type TLetterSpacing = 
  | 'tracking-tighter'
  | 'tracking-tight'
  | 'tracking-normal'
  | 'tracking-wide'
  | 'tracking-wider'
  | 'tracking-widest';

export type TLineHeight = 
  | 'leading-3'
  | 'leading-4'
  | 'leading-5'
  | 'leading-6'
  | 'leading-7'
  | 'leading-8'
  | 'leading-9'
  | 'leading-10'
  | 'leading-none'
  | 'leading-tight'
  | 'leading-snug'
  | 'leading-normal'
  | 'leading-relaxed'
  | 'leading-loose';

export type TListStyleType = 
  | 'list-none'
  | 'list-disc'
  | 'list-decimal';

export type TListStylePosition = 
  | 'list-inside'
  | 'list-outside';

export type TPlaceholderColor = 
  | 'placeholder-transparent'
  | 'placeholder-current'
  | 'placeholder-black'
  | 'placeholder-white'
  | 'placeholder-gray-100'
  | 'placeholder-gray-200'
  | 'placeholder-gray-300'
  | 'placeholder-gray-400'
  | 'placeholder-gray-500'
  | 'placeholder-gray-600'
  | 'placeholder-gray-700'
  | 'placeholder-gray-800'
  | 'placeholder-gray-900'
  | 'placeholder-red-100'
  | 'placeholder-red-200'
  | 'placeholder-red-300'
  | 'placeholder-red-400'
  | 'placeholder-red-500'
  | 'placeholder-red-600'
  | 'placeholder-red-700'
  | 'placeholder-red-800'
  | 'placeholder-red-900'
  | 'placeholder-orange'
  | 'placeholder-yellow'
  | 'placeholder-green'
  | 'placeholder-teal-100'
  | 'placeholder-teal-200'
  | 'placeholder-teal-300'
  | 'placeholder-teal-400'
  | 'placeholder-teal-500'
  | 'placeholder-teal-600'
  | 'placeholder-teal-700'
  | 'placeholder-teal-800'
  | 'placeholder-teal-900'
  | 'placeholder-blue'
  | 'placeholder-indigo-100'
  | 'placeholder-indigo-200'
  | 'placeholder-indigo-300'
  | 'placeholder-indigo-400'
  | 'placeholder-indigo-500'
  | 'placeholder-indigo-600'
  | 'placeholder-indigo-700'
  | 'placeholder-indigo-800'
  | 'placeholder-indigo-900'
  | 'placeholder-purple'
  | 'placeholder-pink'
  | 'placeholder-soft-gold'
  | 'placeholder-gold'
  | 'placeholder-darkGold'
  | 'placeholder-sand'
  | 'placeholder-lightSand'
  | 'placeholder-ecru'
  | 'placeholder-lemon'
  | 'placeholder-ink'
  | 'placeholder-steel'
  | 'placeholder-lightGray'
  | 'placeholder-unassumingGray'
  | 'placeholder-disabledTextGray'
  | 'placeholder-lightRed'
  | 'placeholder-mistakeRed'
  | 'placeholder-grey'
  | 'placeholder-olive-400'
  | 'placeholder-olive-600'
  | 'placeholder-olive-800';

export type TPlaceholderOpacity = 
  | 'placeholder-opacity-0'
  | 'placeholder-opacity-25'
  | 'placeholder-opacity-50'
  | 'placeholder-opacity-75'
  | 'placeholder-opacity-100';

export type TTextAlign = 
  | 'text-left'
  | 'text-center'
  | 'text-right'
  | 'text-justify';

export type TTextColor = 
  | 'text-transparent'
  | 'text-current'
  | 'text-black'
  | 'text-white'
  | 'text-gray-100'
  | 'text-gray-200'
  | 'text-gray-300'
  | 'text-gray-400'
  | 'text-gray-500'
  | 'text-gray-600'
  | 'text-gray-700'
  | 'text-gray-800'
  | 'text-gray-900'
  | 'text-red-100'
  | 'text-red-200'
  | 'text-red-300'
  | 'text-red-400'
  | 'text-red-500'
  | 'text-red-600'
  | 'text-red-700'
  | 'text-red-800'
  | 'text-red-900'
  | 'text-orange'
  | 'text-yellow'
  | 'text-green'
  | 'text-teal-100'
  | 'text-teal-200'
  | 'text-teal-300'
  | 'text-teal-400'
  | 'text-teal-500'
  | 'text-teal-600'
  | 'text-teal-700'
  | 'text-teal-800'
  | 'text-teal-900'
  | 'text-blue'
  | 'text-indigo-100'
  | 'text-indigo-200'
  | 'text-indigo-300'
  | 'text-indigo-400'
  | 'text-indigo-500'
  | 'text-indigo-600'
  | 'text-indigo-700'
  | 'text-indigo-800'
  | 'text-indigo-900'
  | 'text-purple'
  | 'text-pink'
  | 'text-soft-gold'
  | 'text-gold'
  | 'text-darkGold'
  | 'text-sand'
  | 'text-lightSand'
  | 'text-ecru'
  | 'text-lemon'
  | 'text-ink'
  | 'text-steel'
  | 'text-lightGray'
  | 'text-unassumingGray'
  | 'text-disabledTextGray'
  | 'text-lightRed'
  | 'text-mistakeRed'
  | 'text-grey'
  | 'text-olive-400'
  | 'text-olive-600'
  | 'text-olive-800';

export type TTextDecoration = 
  | 'underline'
  | 'line-through'
  | 'no-underline';

export type TTextOpacity = 
  | 'text-opacity-0'
  | 'text-opacity-25'
  | 'text-opacity-50'
  | 'text-opacity-75'
  | 'text-opacity-100';

export type TTextTransform = 
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'normal-case';

export type TVerticalAlign = 
  | 'align-baseline'
  | 'align-top'
  | 'align-middle'
  | 'align-bottom'
  | 'align-text-top'
  | 'align-text-bottom';

export type TWhitespace = 
  | 'whitespace-normal'
  | 'whitespace-no-wrap'
  | 'whitespace-pre'
  | 'whitespace-pre-line'
  | 'whitespace-pre-wrap';

export type TWordBreak = 
  | 'break-normal'
  | 'break-words'
  | 'break-all'
  | 'truncate';

export type TTypography =
  | TFontFamily
  | TFontSize
  | TFontSmoothing
  | TFontStyle
  | TFontWeight
  | TFontVariantNumeric
  | TLetterSpacing
  | TLineHeight
  | TListStyleType
  | TListStylePosition
  | TPlaceholderColor
  | TPlaceholderOpacity
  | TTextAlign
  | TTextColor
  | TTextDecoration
  | TTextOpacity
  | TTextTransform
  | TVerticalAlign
  | TWhitespace
  | TWordBreak


export type TPseudoClasses =
  | 'container:content-start'
  | 'container-mid:content-start'
  | 'container-large:content-start'
  | 'container:content-center'
  | 'container-mid:content-center'
  | 'container-large:content-center'
  | 'container:content-end'
  | 'container-mid:content-end'
  | 'container-large:content-end'
  | 'container:content-between'
  | 'container-mid:content-between'
  | 'container-large:content-between'
  | 'container:content-around'
  | 'container-mid:content-around'
  | 'container-large:content-around'
  | 'container:items-stretch'
  | 'container-mid:items-stretch'
  | 'container-large:items-stretch'
  | 'container:items-start'
  | 'container-mid:items-start'
  | 'container-large:items-start'
  | 'container:items-center'
  | 'container-mid:items-center'
  | 'container-large:items-center'
  | 'container:items-end'
  | 'container-mid:items-end'
  | 'container-large:items-end'
  | 'container:items-baseline'
  | 'container-mid:items-baseline'
  | 'container-large:items-baseline'
  | 'container:self-auto'
  | 'container-mid:self-auto'
  | 'container-large:self-auto'
  | 'container:self-start'
  | 'container-mid:self-start'
  | 'container-large:self-start'
  | 'container:self-center'
  | 'container-mid:self-center'
  | 'container-large:self-center'
  | 'container:self-end'
  | 'container-mid:self-end'
  | 'container-large:self-end'
  | 'container:self-stretch'
  | 'container-mid:self-stretch'
  | 'container-large:self-stretch'
  | 'container:appearance-none'
  | 'container-mid:appearance-none'
  | 'container-large:appearance-none'
  | 'container:bg-fixed'
  | 'container-mid:bg-fixed'
  | 'container-large:bg-fixed'
  | 'container:bg-local'
  | 'container-mid:bg-local'
  | 'container-large:bg-local'
  | 'container:bg-scroll'
  | 'container-mid:bg-scroll'
  | 'container-large:bg-scroll'
  | 'container:bg-clip-border'
  | 'container-mid:bg-clip-border'
  | 'container-large:bg-clip-border'
  | 'container:bg-clip-padding'
  | 'container-mid:bg-clip-padding'
  | 'container-large:bg-clip-padding'
  | 'container:bg-clip-content'
  | 'container-mid:bg-clip-content'
  | 'container-large:bg-clip-content'
  | 'container:bg-clip-text'
  | 'container-mid:bg-clip-text'
  | 'container-large:bg-clip-text'
  | 'container:bg-transparent'
  | 'container-mid:bg-transparent'
  | 'container-large:bg-transparent'
  | 'hover:bg-transparent'
  | 'focus:bg-transparent'
  | 'active:bg-transparent'
  | 'group-hover:bg-transparent'
  | 'group'
  | 'container:bg-current'
  | 'container-mid:bg-current'
  | 'container-large:bg-current'
  | 'hover:bg-current'
  | 'focus:bg-current'
  | 'active:bg-current'
  | 'group-hover:bg-current'
  | 'container:bg-black'
  | 'container-mid:bg-black'
  | 'container-large:bg-black'
  | 'hover:bg-black'
  | 'focus:bg-black'
  | 'active:bg-black'
  | 'group-hover:bg-black'
  | 'container:bg-white'
  | 'container-mid:bg-white'
  | 'container-large:bg-white'
  | 'hover:bg-white'
  | 'focus:bg-white'
  | 'active:bg-white'
  | 'group-hover:bg-white'
  | 'container:bg-gray-100'
  | 'container-mid:bg-gray-100'
  | 'container-large:bg-gray-100'
  | 'hover:bg-gray-100'
  | 'focus:bg-gray-100'
  | 'active:bg-gray-100'
  | 'group-hover:bg-gray-100'
  | 'container:bg-gray-200'
  | 'container-mid:bg-gray-200'
  | 'container-large:bg-gray-200'
  | 'hover:bg-gray-200'
  | 'focus:bg-gray-200'
  | 'active:bg-gray-200'
  | 'group-hover:bg-gray-200'
  | 'container:bg-gray-300'
  | 'container-mid:bg-gray-300'
  | 'container-large:bg-gray-300'
  | 'hover:bg-gray-300'
  | 'focus:bg-gray-300'
  | 'active:bg-gray-300'
  | 'group-hover:bg-gray-300'
  | 'container:bg-gray-400'
  | 'container-mid:bg-gray-400'
  | 'container-large:bg-gray-400'
  | 'hover:bg-gray-400'
  | 'focus:bg-gray-400'
  | 'active:bg-gray-400'
  | 'group-hover:bg-gray-400'
  | 'container:bg-gray-500'
  | 'container-mid:bg-gray-500'
  | 'container-large:bg-gray-500'
  | 'hover:bg-gray-500'
  | 'focus:bg-gray-500'
  | 'active:bg-gray-500'
  | 'group-hover:bg-gray-500'
  | 'container:bg-gray-600'
  | 'container-mid:bg-gray-600'
  | 'container-large:bg-gray-600'
  | 'hover:bg-gray-600'
  | 'focus:bg-gray-600'
  | 'active:bg-gray-600'
  | 'group-hover:bg-gray-600'
  | 'container:bg-gray-700'
  | 'container-mid:bg-gray-700'
  | 'container-large:bg-gray-700'
  | 'hover:bg-gray-700'
  | 'focus:bg-gray-700'
  | 'active:bg-gray-700'
  | 'group-hover:bg-gray-700'
  | 'container:bg-gray-800'
  | 'container-mid:bg-gray-800'
  | 'container-large:bg-gray-800'
  | 'hover:bg-gray-800'
  | 'focus:bg-gray-800'
  | 'active:bg-gray-800'
  | 'group-hover:bg-gray-800'
  | 'container:bg-gray-900'
  | 'container-mid:bg-gray-900'
  | 'container-large:bg-gray-900'
  | 'hover:bg-gray-900'
  | 'focus:bg-gray-900'
  | 'active:bg-gray-900'
  | 'group-hover:bg-gray-900'
  | 'container:bg-red-100'
  | 'container-mid:bg-red-100'
  | 'container-large:bg-red-100'
  | 'hover:bg-red-100'
  | 'focus:bg-red-100'
  | 'active:bg-red-100'
  | 'group-hover:bg-red-100'
  | 'container:bg-red-200'
  | 'container-mid:bg-red-200'
  | 'container-large:bg-red-200'
  | 'hover:bg-red-200'
  | 'focus:bg-red-200'
  | 'active:bg-red-200'
  | 'group-hover:bg-red-200'
  | 'container:bg-red-300'
  | 'container-mid:bg-red-300'
  | 'container-large:bg-red-300'
  | 'hover:bg-red-300'
  | 'focus:bg-red-300'
  | 'active:bg-red-300'
  | 'group-hover:bg-red-300'
  | 'container:bg-red-400'
  | 'container-mid:bg-red-400'
  | 'container-large:bg-red-400'
  | 'hover:bg-red-400'
  | 'focus:bg-red-400'
  | 'active:bg-red-400'
  | 'group-hover:bg-red-400'
  | 'container:bg-red-500'
  | 'container-mid:bg-red-500'
  | 'container-large:bg-red-500'
  | 'hover:bg-red-500'
  | 'focus:bg-red-500'
  | 'active:bg-red-500'
  | 'group-hover:bg-red-500'
  | 'container:bg-red-600'
  | 'container-mid:bg-red-600'
  | 'container-large:bg-red-600'
  | 'hover:bg-red-600'
  | 'focus:bg-red-600'
  | 'active:bg-red-600'
  | 'group-hover:bg-red-600'
  | 'container:bg-red-700'
  | 'container-mid:bg-red-700'
  | 'container-large:bg-red-700'
  | 'hover:bg-red-700'
  | 'focus:bg-red-700'
  | 'active:bg-red-700'
  | 'group-hover:bg-red-700'
  | 'container:bg-red-800'
  | 'container-mid:bg-red-800'
  | 'container-large:bg-red-800'
  | 'hover:bg-red-800'
  | 'focus:bg-red-800'
  | 'active:bg-red-800'
  | 'group-hover:bg-red-800'
  | 'container:bg-red-900'
  | 'container-mid:bg-red-900'
  | 'container-large:bg-red-900'
  | 'hover:bg-red-900'
  | 'focus:bg-red-900'
  | 'active:bg-red-900'
  | 'group-hover:bg-red-900'
  | 'container:bg-orange'
  | 'container-mid:bg-orange'
  | 'container-large:bg-orange'
  | 'hover:bg-orange'
  | 'focus:bg-orange'
  | 'active:bg-orange'
  | 'group-hover:bg-orange'
  | 'container:bg-yellow'
  | 'container-mid:bg-yellow'
  | 'container-large:bg-yellow'
  | 'hover:bg-yellow'
  | 'focus:bg-yellow'
  | 'active:bg-yellow'
  | 'group-hover:bg-yellow'
  | 'container:bg-green'
  | 'container-mid:bg-green'
  | 'container-large:bg-green'
  | 'hover:bg-green'
  | 'focus:bg-green'
  | 'active:bg-green'
  | 'group-hover:bg-green'
  | 'container:bg-teal-100'
  | 'container-mid:bg-teal-100'
  | 'container-large:bg-teal-100'
  | 'hover:bg-teal-100'
  | 'focus:bg-teal-100'
  | 'active:bg-teal-100'
  | 'group-hover:bg-teal-100'
  | 'container:bg-teal-200'
  | 'container-mid:bg-teal-200'
  | 'container-large:bg-teal-200'
  | 'hover:bg-teal-200'
  | 'focus:bg-teal-200'
  | 'active:bg-teal-200'
  | 'group-hover:bg-teal-200'
  | 'container:bg-teal-300'
  | 'container-mid:bg-teal-300'
  | 'container-large:bg-teal-300'
  | 'hover:bg-teal-300'
  | 'focus:bg-teal-300'
  | 'active:bg-teal-300'
  | 'group-hover:bg-teal-300'
  | 'container:bg-teal-400'
  | 'container-mid:bg-teal-400'
  | 'container-large:bg-teal-400'
  | 'hover:bg-teal-400'
  | 'focus:bg-teal-400'
  | 'active:bg-teal-400'
  | 'group-hover:bg-teal-400'
  | 'container:bg-teal-500'
  | 'container-mid:bg-teal-500'
  | 'container-large:bg-teal-500'
  | 'hover:bg-teal-500'
  | 'focus:bg-teal-500'
  | 'active:bg-teal-500'
  | 'group-hover:bg-teal-500'
  | 'container:bg-teal-600'
  | 'container-mid:bg-teal-600'
  | 'container-large:bg-teal-600'
  | 'hover:bg-teal-600'
  | 'focus:bg-teal-600'
  | 'active:bg-teal-600'
  | 'group-hover:bg-teal-600'
  | 'container:bg-teal-700'
  | 'container-mid:bg-teal-700'
  | 'container-large:bg-teal-700'
  | 'hover:bg-teal-700'
  | 'focus:bg-teal-700'
  | 'active:bg-teal-700'
  | 'group-hover:bg-teal-700'
  | 'container:bg-teal-800'
  | 'container-mid:bg-teal-800'
  | 'container-large:bg-teal-800'
  | 'hover:bg-teal-800'
  | 'focus:bg-teal-800'
  | 'active:bg-teal-800'
  | 'group-hover:bg-teal-800'
  | 'container:bg-teal-900'
  | 'container-mid:bg-teal-900'
  | 'container-large:bg-teal-900'
  | 'hover:bg-teal-900'
  | 'focus:bg-teal-900'
  | 'active:bg-teal-900'
  | 'group-hover:bg-teal-900'
  | 'container:bg-blue'
  | 'container-mid:bg-blue'
  | 'container-large:bg-blue'
  | 'hover:bg-blue'
  | 'focus:bg-blue'
  | 'active:bg-blue'
  | 'group-hover:bg-blue'
  | 'container:bg-indigo-100'
  | 'container-mid:bg-indigo-100'
  | 'container-large:bg-indigo-100'
  | 'hover:bg-indigo-100'
  | 'focus:bg-indigo-100'
  | 'active:bg-indigo-100'
  | 'group-hover:bg-indigo-100'
  | 'container:bg-indigo-200'
  | 'container-mid:bg-indigo-200'
  | 'container-large:bg-indigo-200'
  | 'hover:bg-indigo-200'
  | 'focus:bg-indigo-200'
  | 'active:bg-indigo-200'
  | 'group-hover:bg-indigo-200'
  | 'container:bg-indigo-300'
  | 'container-mid:bg-indigo-300'
  | 'container-large:bg-indigo-300'
  | 'hover:bg-indigo-300'
  | 'focus:bg-indigo-300'
  | 'active:bg-indigo-300'
  | 'group-hover:bg-indigo-300'
  | 'container:bg-indigo-400'
  | 'container-mid:bg-indigo-400'
  | 'container-large:bg-indigo-400'
  | 'hover:bg-indigo-400'
  | 'focus:bg-indigo-400'
  | 'active:bg-indigo-400'
  | 'group-hover:bg-indigo-400'
  | 'container:bg-indigo-500'
  | 'container-mid:bg-indigo-500'
  | 'container-large:bg-indigo-500'
  | 'hover:bg-indigo-500'
  | 'focus:bg-indigo-500'
  | 'active:bg-indigo-500'
  | 'group-hover:bg-indigo-500'
  | 'container:bg-indigo-600'
  | 'container-mid:bg-indigo-600'
  | 'container-large:bg-indigo-600'
  | 'hover:bg-indigo-600'
  | 'focus:bg-indigo-600'
  | 'active:bg-indigo-600'
  | 'group-hover:bg-indigo-600'
  | 'container:bg-indigo-700'
  | 'container-mid:bg-indigo-700'
  | 'container-large:bg-indigo-700'
  | 'hover:bg-indigo-700'
  | 'focus:bg-indigo-700'
  | 'active:bg-indigo-700'
  | 'group-hover:bg-indigo-700'
  | 'container:bg-indigo-800'
  | 'container-mid:bg-indigo-800'
  | 'container-large:bg-indigo-800'
  | 'hover:bg-indigo-800'
  | 'focus:bg-indigo-800'
  | 'active:bg-indigo-800'
  | 'group-hover:bg-indigo-800'
  | 'container:bg-indigo-900'
  | 'container-mid:bg-indigo-900'
  | 'container-large:bg-indigo-900'
  | 'hover:bg-indigo-900'
  | 'focus:bg-indigo-900'
  | 'active:bg-indigo-900'
  | 'group-hover:bg-indigo-900'
  | 'container:bg-purple'
  | 'container-mid:bg-purple'
  | 'container-large:bg-purple'
  | 'hover:bg-purple'
  | 'focus:bg-purple'
  | 'active:bg-purple'
  | 'group-hover:bg-purple'
  | 'container:bg-pink'
  | 'container-mid:bg-pink'
  | 'container-large:bg-pink'
  | 'hover:bg-pink'
  | 'focus:bg-pink'
  | 'active:bg-pink'
  | 'group-hover:bg-pink'
  | 'container:bg-soft-gold'
  | 'container-mid:bg-soft-gold'
  | 'container-large:bg-soft-gold'
  | 'hover:bg-soft-gold'
  | 'focus:bg-soft-gold'
  | 'active:bg-soft-gold'
  | 'group-hover:bg-soft-gold'
  | 'container:bg-gold'
  | 'container-mid:bg-gold'
  | 'container-large:bg-gold'
  | 'hover:bg-gold'
  | 'focus:bg-gold'
  | 'active:bg-gold'
  | 'group-hover:bg-gold'
  | 'container:bg-darkGold'
  | 'container-mid:bg-darkGold'
  | 'container-large:bg-darkGold'
  | 'hover:bg-darkGold'
  | 'focus:bg-darkGold'
  | 'active:bg-darkGold'
  | 'group-hover:bg-darkGold'
  | 'container:bg-sand'
  | 'container-mid:bg-sand'
  | 'container-large:bg-sand'
  | 'hover:bg-sand'
  | 'focus:bg-sand'
  | 'active:bg-sand'
  | 'group-hover:bg-sand'
  | 'container:bg-lightSand'
  | 'container-mid:bg-lightSand'
  | 'container-large:bg-lightSand'
  | 'hover:bg-lightSand'
  | 'focus:bg-lightSand'
  | 'active:bg-lightSand'
  | 'group-hover:bg-lightSand'
  | 'container:bg-ecru'
  | 'container-mid:bg-ecru'
  | 'container-large:bg-ecru'
  | 'hover:bg-ecru'
  | 'focus:bg-ecru'
  | 'active:bg-ecru'
  | 'group-hover:bg-ecru'
  | 'container:bg-lemon'
  | 'container-mid:bg-lemon'
  | 'container-large:bg-lemon'
  | 'hover:bg-lemon'
  | 'focus:bg-lemon'
  | 'active:bg-lemon'
  | 'group-hover:bg-lemon'
  | 'container:bg-ink'
  | 'container-mid:bg-ink'
  | 'container-large:bg-ink'
  | 'hover:bg-ink'
  | 'focus:bg-ink'
  | 'active:bg-ink'
  | 'group-hover:bg-ink'
  | 'container:bg-steel'
  | 'container-mid:bg-steel'
  | 'container-large:bg-steel'
  | 'hover:bg-steel'
  | 'focus:bg-steel'
  | 'active:bg-steel'
  | 'group-hover:bg-steel'
  | 'container:bg-lightGray'
  | 'container-mid:bg-lightGray'
  | 'container-large:bg-lightGray'
  | 'hover:bg-lightGray'
  | 'focus:bg-lightGray'
  | 'active:bg-lightGray'
  | 'group-hover:bg-lightGray'
  | 'container:bg-unassumingGray'
  | 'container-mid:bg-unassumingGray'
  | 'container-large:bg-unassumingGray'
  | 'hover:bg-unassumingGray'
  | 'focus:bg-unassumingGray'
  | 'active:bg-unassumingGray'
  | 'group-hover:bg-unassumingGray'
  | 'container:bg-disabledTextGray'
  | 'container-mid:bg-disabledTextGray'
  | 'container-large:bg-disabledTextGray'
  | 'hover:bg-disabledTextGray'
  | 'focus:bg-disabledTextGray'
  | 'active:bg-disabledTextGray'
  | 'group-hover:bg-disabledTextGray'
  | 'container:bg-lightRed'
  | 'container-mid:bg-lightRed'
  | 'container-large:bg-lightRed'
  | 'hover:bg-lightRed'
  | 'focus:bg-lightRed'
  | 'active:bg-lightRed'
  | 'group-hover:bg-lightRed'
  | 'container:bg-mistakeRed'
  | 'container-mid:bg-mistakeRed'
  | 'container-large:bg-mistakeRed'
  | 'hover:bg-mistakeRed'
  | 'focus:bg-mistakeRed'
  | 'active:bg-mistakeRed'
  | 'group-hover:bg-mistakeRed'
  | 'container:bg-grey'
  | 'container-mid:bg-grey'
  | 'container-large:bg-grey'
  | 'hover:bg-grey'
  | 'focus:bg-grey'
  | 'active:bg-grey'
  | 'group-hover:bg-grey'
  | 'container:bg-olive-400'
  | 'container-mid:bg-olive-400'
  | 'container-large:bg-olive-400'
  | 'hover:bg-olive-400'
  | 'focus:bg-olive-400'
  | 'active:bg-olive-400'
  | 'group-hover:bg-olive-400'
  | 'container:bg-olive-600'
  | 'container-mid:bg-olive-600'
  | 'container-large:bg-olive-600'
  | 'hover:bg-olive-600'
  | 'focus:bg-olive-600'
  | 'active:bg-olive-600'
  | 'group-hover:bg-olive-600'
  | 'container:bg-olive-800'
  | 'container-mid:bg-olive-800'
  | 'container-large:bg-olive-800'
  | 'hover:bg-olive-800'
  | 'focus:bg-olive-800'
  | 'active:bg-olive-800'
  | 'group-hover:bg-olive-800'
  | 'container:bg-none'
  | 'container-mid:bg-none'
  | 'container-large:bg-none'
  | 'container:bg-gradient-to-t'
  | 'container-mid:bg-gradient-to-t'
  | 'container-large:bg-gradient-to-t'
  | 'container:bg-gradient-to-tr'
  | 'container-mid:bg-gradient-to-tr'
  | 'container-large:bg-gradient-to-tr'
  | 'container:bg-gradient-to-r'
  | 'container-mid:bg-gradient-to-r'
  | 'container-large:bg-gradient-to-r'
  | 'container:bg-gradient-to-br'
  | 'container-mid:bg-gradient-to-br'
  | 'container-large:bg-gradient-to-br'
  | 'container:bg-gradient-to-b'
  | 'container-mid:bg-gradient-to-b'
  | 'container-large:bg-gradient-to-b'
  | 'container:bg-gradient-to-bl'
  | 'container-mid:bg-gradient-to-bl'
  | 'container-large:bg-gradient-to-bl'
  | 'container:bg-gradient-to-l'
  | 'container-mid:bg-gradient-to-l'
  | 'container-large:bg-gradient-to-l'
  | 'container:bg-gradient-to-tl'
  | 'container-mid:bg-gradient-to-tl'
  | 'container-large:bg-gradient-to-tl'
  | 'container:bg-shapeGoldEcru'
  | 'container-mid:bg-shapeGoldEcru'
  | 'container-large:bg-shapeGoldEcru'
  | 'container:bg-shapeGoldWhite'
  | 'container-mid:bg-shapeGoldWhite'
  | 'container-large:bg-shapeGoldWhite'
  | 'container:bg-shapeGoldSoftGold'
  | 'container-mid:bg-shapeGoldSoftGold'
  | 'container-large:bg-shapeGoldSoftGold'
  | 'container:from-transparent'
  | 'container-mid:from-transparent'
  | 'container-large:from-transparent'
  | 'hover:from-transparent'
  | 'focus:from-transparent'
  | 'container:via-transparent'
  | 'container-mid:via-transparent'
  | 'container-large:via-transparent'
  | 'hover:via-transparent'
  | 'focus:via-transparent'
  | 'container:to-transparent'
  | 'container-mid:to-transparent'
  | 'container-large:to-transparent'
  | 'hover:to-transparent'
  | 'focus:to-transparent'
  | 'container:from-current'
  | 'container-mid:from-current'
  | 'container-large:from-current'
  | 'hover:from-current'
  | 'focus:from-current'
  | 'container:via-current'
  | 'container-mid:via-current'
  | 'container-large:via-current'
  | 'hover:via-current'
  | 'focus:via-current'
  | 'container:to-current'
  | 'container-mid:to-current'
  | 'container-large:to-current'
  | 'hover:to-current'
  | 'focus:to-current'
  | 'container:from-black'
  | 'container-mid:from-black'
  | 'container-large:from-black'
  | 'hover:from-black'
  | 'focus:from-black'
  | 'container:via-black'
  | 'container-mid:via-black'
  | 'container-large:via-black'
  | 'hover:via-black'
  | 'focus:via-black'
  | 'container:to-black'
  | 'container-mid:to-black'
  | 'container-large:to-black'
  | 'hover:to-black'
  | 'focus:to-black'
  | 'container:from-white'
  | 'container-mid:from-white'
  | 'container-large:from-white'
  | 'hover:from-white'
  | 'focus:from-white'
  | 'container:via-white'
  | 'container-mid:via-white'
  | 'container-large:via-white'
  | 'hover:via-white'
  | 'focus:via-white'
  | 'container:to-white'
  | 'container-mid:to-white'
  | 'container-large:to-white'
  | 'hover:to-white'
  | 'focus:to-white'
  | 'container:from-gray-100'
  | 'container-mid:from-gray-100'
  | 'container-large:from-gray-100'
  | 'hover:from-gray-100'
  | 'focus:from-gray-100'
  | 'container:via-gray-100'
  | 'container-mid:via-gray-100'
  | 'container-large:via-gray-100'
  | 'hover:via-gray-100'
  | 'focus:via-gray-100'
  | 'container:to-gray-100'
  | 'container-mid:to-gray-100'
  | 'container-large:to-gray-100'
  | 'hover:to-gray-100'
  | 'focus:to-gray-100'
  | 'container:from-gray-200'
  | 'container-mid:from-gray-200'
  | 'container-large:from-gray-200'
  | 'hover:from-gray-200'
  | 'focus:from-gray-200'
  | 'container:via-gray-200'
  | 'container-mid:via-gray-200'
  | 'container-large:via-gray-200'
  | 'hover:via-gray-200'
  | 'focus:via-gray-200'
  | 'container:to-gray-200'
  | 'container-mid:to-gray-200'
  | 'container-large:to-gray-200'
  | 'hover:to-gray-200'
  | 'focus:to-gray-200'
  | 'container:from-gray-300'
  | 'container-mid:from-gray-300'
  | 'container-large:from-gray-300'
  | 'hover:from-gray-300'
  | 'focus:from-gray-300'
  | 'container:via-gray-300'
  | 'container-mid:via-gray-300'
  | 'container-large:via-gray-300'
  | 'hover:via-gray-300'
  | 'focus:via-gray-300'
  | 'container:to-gray-300'
  | 'container-mid:to-gray-300'
  | 'container-large:to-gray-300'
  | 'hover:to-gray-300'
  | 'focus:to-gray-300'
  | 'container:from-gray-400'
  | 'container-mid:from-gray-400'
  | 'container-large:from-gray-400'
  | 'hover:from-gray-400'
  | 'focus:from-gray-400'
  | 'container:via-gray-400'
  | 'container-mid:via-gray-400'
  | 'container-large:via-gray-400'
  | 'hover:via-gray-400'
  | 'focus:via-gray-400'
  | 'container:to-gray-400'
  | 'container-mid:to-gray-400'
  | 'container-large:to-gray-400'
  | 'hover:to-gray-400'
  | 'focus:to-gray-400'
  | 'container:from-gray-500'
  | 'container-mid:from-gray-500'
  | 'container-large:from-gray-500'
  | 'hover:from-gray-500'
  | 'focus:from-gray-500'
  | 'container:via-gray-500'
  | 'container-mid:via-gray-500'
  | 'container-large:via-gray-500'
  | 'hover:via-gray-500'
  | 'focus:via-gray-500'
  | 'container:to-gray-500'
  | 'container-mid:to-gray-500'
  | 'container-large:to-gray-500'
  | 'hover:to-gray-500'
  | 'focus:to-gray-500'
  | 'container:from-gray-600'
  | 'container-mid:from-gray-600'
  | 'container-large:from-gray-600'
  | 'hover:from-gray-600'
  | 'focus:from-gray-600'
  | 'container:via-gray-600'
  | 'container-mid:via-gray-600'
  | 'container-large:via-gray-600'
  | 'hover:via-gray-600'
  | 'focus:via-gray-600'
  | 'container:to-gray-600'
  | 'container-mid:to-gray-600'
  | 'container-large:to-gray-600'
  | 'hover:to-gray-600'
  | 'focus:to-gray-600'
  | 'container:from-gray-700'
  | 'container-mid:from-gray-700'
  | 'container-large:from-gray-700'
  | 'hover:from-gray-700'
  | 'focus:from-gray-700'
  | 'container:via-gray-700'
  | 'container-mid:via-gray-700'
  | 'container-large:via-gray-700'
  | 'hover:via-gray-700'
  | 'focus:via-gray-700'
  | 'container:to-gray-700'
  | 'container-mid:to-gray-700'
  | 'container-large:to-gray-700'
  | 'hover:to-gray-700'
  | 'focus:to-gray-700'
  | 'container:from-gray-800'
  | 'container-mid:from-gray-800'
  | 'container-large:from-gray-800'
  | 'hover:from-gray-800'
  | 'focus:from-gray-800'
  | 'container:via-gray-800'
  | 'container-mid:via-gray-800'
  | 'container-large:via-gray-800'
  | 'hover:via-gray-800'
  | 'focus:via-gray-800'
  | 'container:to-gray-800'
  | 'container-mid:to-gray-800'
  | 'container-large:to-gray-800'
  | 'hover:to-gray-800'
  | 'focus:to-gray-800'
  | 'container:from-gray-900'
  | 'container-mid:from-gray-900'
  | 'container-large:from-gray-900'
  | 'hover:from-gray-900'
  | 'focus:from-gray-900'
  | 'container:via-gray-900'
  | 'container-mid:via-gray-900'
  | 'container-large:via-gray-900'
  | 'hover:via-gray-900'
  | 'focus:via-gray-900'
  | 'container:to-gray-900'
  | 'container-mid:to-gray-900'
  | 'container-large:to-gray-900'
  | 'hover:to-gray-900'
  | 'focus:to-gray-900'
  | 'container:from-red-100'
  | 'container-mid:from-red-100'
  | 'container-large:from-red-100'
  | 'hover:from-red-100'
  | 'focus:from-red-100'
  | 'container:via-red-100'
  | 'container-mid:via-red-100'
  | 'container-large:via-red-100'
  | 'hover:via-red-100'
  | 'focus:via-red-100'
  | 'container:to-red-100'
  | 'container-mid:to-red-100'
  | 'container-large:to-red-100'
  | 'hover:to-red-100'
  | 'focus:to-red-100'
  | 'container:from-red-200'
  | 'container-mid:from-red-200'
  | 'container-large:from-red-200'
  | 'hover:from-red-200'
  | 'focus:from-red-200'
  | 'container:via-red-200'
  | 'container-mid:via-red-200'
  | 'container-large:via-red-200'
  | 'hover:via-red-200'
  | 'focus:via-red-200'
  | 'container:to-red-200'
  | 'container-mid:to-red-200'
  | 'container-large:to-red-200'
  | 'hover:to-red-200'
  | 'focus:to-red-200'
  | 'container:from-red-300'
  | 'container-mid:from-red-300'
  | 'container-large:from-red-300'
  | 'hover:from-red-300'
  | 'focus:from-red-300'
  | 'container:via-red-300'
  | 'container-mid:via-red-300'
  | 'container-large:via-red-300'
  | 'hover:via-red-300'
  | 'focus:via-red-300'
  | 'container:to-red-300'
  | 'container-mid:to-red-300'
  | 'container-large:to-red-300'
  | 'hover:to-red-300'
  | 'focus:to-red-300'
  | 'container:from-red-400'
  | 'container-mid:from-red-400'
  | 'container-large:from-red-400'
  | 'hover:from-red-400'
  | 'focus:from-red-400'
  | 'container:via-red-400'
  | 'container-mid:via-red-400'
  | 'container-large:via-red-400'
  | 'hover:via-red-400'
  | 'focus:via-red-400'
  | 'container:to-red-400'
  | 'container-mid:to-red-400'
  | 'container-large:to-red-400'
  | 'hover:to-red-400'
  | 'focus:to-red-400'
  | 'container:from-red-500'
  | 'container-mid:from-red-500'
  | 'container-large:from-red-500'
  | 'hover:from-red-500'
  | 'focus:from-red-500'
  | 'container:via-red-500'
  | 'container-mid:via-red-500'
  | 'container-large:via-red-500'
  | 'hover:via-red-500'
  | 'focus:via-red-500'
  | 'container:to-red-500'
  | 'container-mid:to-red-500'
  | 'container-large:to-red-500'
  | 'hover:to-red-500'
  | 'focus:to-red-500'
  | 'container:from-red-600'
  | 'container-mid:from-red-600'
  | 'container-large:from-red-600'
  | 'hover:from-red-600'
  | 'focus:from-red-600'
  | 'container:via-red-600'
  | 'container-mid:via-red-600'
  | 'container-large:via-red-600'
  | 'hover:via-red-600'
  | 'focus:via-red-600'
  | 'container:to-red-600'
  | 'container-mid:to-red-600'
  | 'container-large:to-red-600'
  | 'hover:to-red-600'
  | 'focus:to-red-600'
  | 'container:from-red-700'
  | 'container-mid:from-red-700'
  | 'container-large:from-red-700'
  | 'hover:from-red-700'
  | 'focus:from-red-700'
  | 'container:via-red-700'
  | 'container-mid:via-red-700'
  | 'container-large:via-red-700'
  | 'hover:via-red-700'
  | 'focus:via-red-700'
  | 'container:to-red-700'
  | 'container-mid:to-red-700'
  | 'container-large:to-red-700'
  | 'hover:to-red-700'
  | 'focus:to-red-700'
  | 'container:from-red-800'
  | 'container-mid:from-red-800'
  | 'container-large:from-red-800'
  | 'hover:from-red-800'
  | 'focus:from-red-800'
  | 'container:via-red-800'
  | 'container-mid:via-red-800'
  | 'container-large:via-red-800'
  | 'hover:via-red-800'
  | 'focus:via-red-800'
  | 'container:to-red-800'
  | 'container-mid:to-red-800'
  | 'container-large:to-red-800'
  | 'hover:to-red-800'
  | 'focus:to-red-800'
  | 'container:from-red-900'
  | 'container-mid:from-red-900'
  | 'container-large:from-red-900'
  | 'hover:from-red-900'
  | 'focus:from-red-900'
  | 'container:via-red-900'
  | 'container-mid:via-red-900'
  | 'container-large:via-red-900'
  | 'hover:via-red-900'
  | 'focus:via-red-900'
  | 'container:to-red-900'
  | 'container-mid:to-red-900'
  | 'container-large:to-red-900'
  | 'hover:to-red-900'
  | 'focus:to-red-900'
  | 'container:from-orange'
  | 'container-mid:from-orange'
  | 'container-large:from-orange'
  | 'hover:from-orange'
  | 'focus:from-orange'
  | 'container:via-orange'
  | 'container-mid:via-orange'
  | 'container-large:via-orange'
  | 'hover:via-orange'
  | 'focus:via-orange'
  | 'container:to-orange'
  | 'container-mid:to-orange'
  | 'container-large:to-orange'
  | 'hover:to-orange'
  | 'focus:to-orange'
  | 'container:from-yellow'
  | 'container-mid:from-yellow'
  | 'container-large:from-yellow'
  | 'hover:from-yellow'
  | 'focus:from-yellow'
  | 'container:via-yellow'
  | 'container-mid:via-yellow'
  | 'container-large:via-yellow'
  | 'hover:via-yellow'
  | 'focus:via-yellow'
  | 'container:to-yellow'
  | 'container-mid:to-yellow'
  | 'container-large:to-yellow'
  | 'hover:to-yellow'
  | 'focus:to-yellow'
  | 'container:from-green'
  | 'container-mid:from-green'
  | 'container-large:from-green'
  | 'hover:from-green'
  | 'focus:from-green'
  | 'container:via-green'
  | 'container-mid:via-green'
  | 'container-large:via-green'
  | 'hover:via-green'
  | 'focus:via-green'
  | 'container:to-green'
  | 'container-mid:to-green'
  | 'container-large:to-green'
  | 'hover:to-green'
  | 'focus:to-green'
  | 'container:from-teal-100'
  | 'container-mid:from-teal-100'
  | 'container-large:from-teal-100'
  | 'hover:from-teal-100'
  | 'focus:from-teal-100'
  | 'container:via-teal-100'
  | 'container-mid:via-teal-100'
  | 'container-large:via-teal-100'
  | 'hover:via-teal-100'
  | 'focus:via-teal-100'
  | 'container:to-teal-100'
  | 'container-mid:to-teal-100'
  | 'container-large:to-teal-100'
  | 'hover:to-teal-100'
  | 'focus:to-teal-100'
  | 'container:from-teal-200'
  | 'container-mid:from-teal-200'
  | 'container-large:from-teal-200'
  | 'hover:from-teal-200'
  | 'focus:from-teal-200'
  | 'container:via-teal-200'
  | 'container-mid:via-teal-200'
  | 'container-large:via-teal-200'
  | 'hover:via-teal-200'
  | 'focus:via-teal-200'
  | 'container:to-teal-200'
  | 'container-mid:to-teal-200'
  | 'container-large:to-teal-200'
  | 'hover:to-teal-200'
  | 'focus:to-teal-200'
  | 'container:from-teal-300'
  | 'container-mid:from-teal-300'
  | 'container-large:from-teal-300'
  | 'hover:from-teal-300'
  | 'focus:from-teal-300'
  | 'container:via-teal-300'
  | 'container-mid:via-teal-300'
  | 'container-large:via-teal-300'
  | 'hover:via-teal-300'
  | 'focus:via-teal-300'
  | 'container:to-teal-300'
  | 'container-mid:to-teal-300'
  | 'container-large:to-teal-300'
  | 'hover:to-teal-300'
  | 'focus:to-teal-300'
  | 'container:from-teal-400'
  | 'container-mid:from-teal-400'
  | 'container-large:from-teal-400'
  | 'hover:from-teal-400'
  | 'focus:from-teal-400'
  | 'container:via-teal-400'
  | 'container-mid:via-teal-400'
  | 'container-large:via-teal-400'
  | 'hover:via-teal-400'
  | 'focus:via-teal-400'
  | 'container:to-teal-400'
  | 'container-mid:to-teal-400'
  | 'container-large:to-teal-400'
  | 'hover:to-teal-400'
  | 'focus:to-teal-400'
  | 'container:from-teal-500'
  | 'container-mid:from-teal-500'
  | 'container-large:from-teal-500'
  | 'hover:from-teal-500'
  | 'focus:from-teal-500'
  | 'container:via-teal-500'
  | 'container-mid:via-teal-500'
  | 'container-large:via-teal-500'
  | 'hover:via-teal-500'
  | 'focus:via-teal-500'
  | 'container:to-teal-500'
  | 'container-mid:to-teal-500'
  | 'container-large:to-teal-500'
  | 'hover:to-teal-500'
  | 'focus:to-teal-500'
  | 'container:from-teal-600'
  | 'container-mid:from-teal-600'
  | 'container-large:from-teal-600'
  | 'hover:from-teal-600'
  | 'focus:from-teal-600'
  | 'container:via-teal-600'
  | 'container-mid:via-teal-600'
  | 'container-large:via-teal-600'
  | 'hover:via-teal-600'
  | 'focus:via-teal-600'
  | 'container:to-teal-600'
  | 'container-mid:to-teal-600'
  | 'container-large:to-teal-600'
  | 'hover:to-teal-600'
  | 'focus:to-teal-600'
  | 'container:from-teal-700'
  | 'container-mid:from-teal-700'
  | 'container-large:from-teal-700'
  | 'hover:from-teal-700'
  | 'focus:from-teal-700'
  | 'container:via-teal-700'
  | 'container-mid:via-teal-700'
  | 'container-large:via-teal-700'
  | 'hover:via-teal-700'
  | 'focus:via-teal-700'
  | 'container:to-teal-700'
  | 'container-mid:to-teal-700'
  | 'container-large:to-teal-700'
  | 'hover:to-teal-700'
  | 'focus:to-teal-700'
  | 'container:from-teal-800'
  | 'container-mid:from-teal-800'
  | 'container-large:from-teal-800'
  | 'hover:from-teal-800'
  | 'focus:from-teal-800'
  | 'container:via-teal-800'
  | 'container-mid:via-teal-800'
  | 'container-large:via-teal-800'
  | 'hover:via-teal-800'
  | 'focus:via-teal-800'
  | 'container:to-teal-800'
  | 'container-mid:to-teal-800'
  | 'container-large:to-teal-800'
  | 'hover:to-teal-800'
  | 'focus:to-teal-800'
  | 'container:from-teal-900'
  | 'container-mid:from-teal-900'
  | 'container-large:from-teal-900'
  | 'hover:from-teal-900'
  | 'focus:from-teal-900'
  | 'container:via-teal-900'
  | 'container-mid:via-teal-900'
  | 'container-large:via-teal-900'
  | 'hover:via-teal-900'
  | 'focus:via-teal-900'
  | 'container:to-teal-900'
  | 'container-mid:to-teal-900'
  | 'container-large:to-teal-900'
  | 'hover:to-teal-900'
  | 'focus:to-teal-900'
  | 'container:from-blue'
  | 'container-mid:from-blue'
  | 'container-large:from-blue'
  | 'hover:from-blue'
  | 'focus:from-blue'
  | 'container:via-blue'
  | 'container-mid:via-blue'
  | 'container-large:via-blue'
  | 'hover:via-blue'
  | 'focus:via-blue'
  | 'container:to-blue'
  | 'container-mid:to-blue'
  | 'container-large:to-blue'
  | 'hover:to-blue'
  | 'focus:to-blue'
  | 'container:from-indigo-100'
  | 'container-mid:from-indigo-100'
  | 'container-large:from-indigo-100'
  | 'hover:from-indigo-100'
  | 'focus:from-indigo-100'
  | 'container:via-indigo-100'
  | 'container-mid:via-indigo-100'
  | 'container-large:via-indigo-100'
  | 'hover:via-indigo-100'
  | 'focus:via-indigo-100'
  | 'container:to-indigo-100'
  | 'container-mid:to-indigo-100'
  | 'container-large:to-indigo-100'
  | 'hover:to-indigo-100'
  | 'focus:to-indigo-100'
  | 'container:from-indigo-200'
  | 'container-mid:from-indigo-200'
  | 'container-large:from-indigo-200'
  | 'hover:from-indigo-200'
  | 'focus:from-indigo-200'
  | 'container:via-indigo-200'
  | 'container-mid:via-indigo-200'
  | 'container-large:via-indigo-200'
  | 'hover:via-indigo-200'
  | 'focus:via-indigo-200'
  | 'container:to-indigo-200'
  | 'container-mid:to-indigo-200'
  | 'container-large:to-indigo-200'
  | 'hover:to-indigo-200'
  | 'focus:to-indigo-200'
  | 'container:from-indigo-300'
  | 'container-mid:from-indigo-300'
  | 'container-large:from-indigo-300'
  | 'hover:from-indigo-300'
  | 'focus:from-indigo-300'
  | 'container:via-indigo-300'
  | 'container-mid:via-indigo-300'
  | 'container-large:via-indigo-300'
  | 'hover:via-indigo-300'
  | 'focus:via-indigo-300'
  | 'container:to-indigo-300'
  | 'container-mid:to-indigo-300'
  | 'container-large:to-indigo-300'
  | 'hover:to-indigo-300'
  | 'focus:to-indigo-300'
  | 'container:from-indigo-400'
  | 'container-mid:from-indigo-400'
  | 'container-large:from-indigo-400'
  | 'hover:from-indigo-400'
  | 'focus:from-indigo-400'
  | 'container:via-indigo-400'
  | 'container-mid:via-indigo-400'
  | 'container-large:via-indigo-400'
  | 'hover:via-indigo-400'
  | 'focus:via-indigo-400'
  | 'container:to-indigo-400'
  | 'container-mid:to-indigo-400'
  | 'container-large:to-indigo-400'
  | 'hover:to-indigo-400'
  | 'focus:to-indigo-400'
  | 'container:from-indigo-500'
  | 'container-mid:from-indigo-500'
  | 'container-large:from-indigo-500'
  | 'hover:from-indigo-500'
  | 'focus:from-indigo-500'
  | 'container:via-indigo-500'
  | 'container-mid:via-indigo-500'
  | 'container-large:via-indigo-500'
  | 'hover:via-indigo-500'
  | 'focus:via-indigo-500'
  | 'container:to-indigo-500'
  | 'container-mid:to-indigo-500'
  | 'container-large:to-indigo-500'
  | 'hover:to-indigo-500'
  | 'focus:to-indigo-500'
  | 'container:from-indigo-600'
  | 'container-mid:from-indigo-600'
  | 'container-large:from-indigo-600'
  | 'hover:from-indigo-600'
  | 'focus:from-indigo-600'
  | 'container:via-indigo-600'
  | 'container-mid:via-indigo-600'
  | 'container-large:via-indigo-600'
  | 'hover:via-indigo-600'
  | 'focus:via-indigo-600'
  | 'container:to-indigo-600'
  | 'container-mid:to-indigo-600'
  | 'container-large:to-indigo-600'
  | 'hover:to-indigo-600'
  | 'focus:to-indigo-600'
  | 'container:from-indigo-700'
  | 'container-mid:from-indigo-700'
  | 'container-large:from-indigo-700'
  | 'hover:from-indigo-700'
  | 'focus:from-indigo-700'
  | 'container:via-indigo-700'
  | 'container-mid:via-indigo-700'
  | 'container-large:via-indigo-700'
  | 'hover:via-indigo-700'
  | 'focus:via-indigo-700'
  | 'container:to-indigo-700'
  | 'container-mid:to-indigo-700'
  | 'container-large:to-indigo-700'
  | 'hover:to-indigo-700'
  | 'focus:to-indigo-700'
  | 'container:from-indigo-800'
  | 'container-mid:from-indigo-800'
  | 'container-large:from-indigo-800'
  | 'hover:from-indigo-800'
  | 'focus:from-indigo-800'
  | 'container:via-indigo-800'
  | 'container-mid:via-indigo-800'
  | 'container-large:via-indigo-800'
  | 'hover:via-indigo-800'
  | 'focus:via-indigo-800'
  | 'container:to-indigo-800'
  | 'container-mid:to-indigo-800'
  | 'container-large:to-indigo-800'
  | 'hover:to-indigo-800'
  | 'focus:to-indigo-800'
  | 'container:from-indigo-900'
  | 'container-mid:from-indigo-900'
  | 'container-large:from-indigo-900'
  | 'hover:from-indigo-900'
  | 'focus:from-indigo-900'
  | 'container:via-indigo-900'
  | 'container-mid:via-indigo-900'
  | 'container-large:via-indigo-900'
  | 'hover:via-indigo-900'
  | 'focus:via-indigo-900'
  | 'container:to-indigo-900'
  | 'container-mid:to-indigo-900'
  | 'container-large:to-indigo-900'
  | 'hover:to-indigo-900'
  | 'focus:to-indigo-900'
  | 'container:from-purple'
  | 'container-mid:from-purple'
  | 'container-large:from-purple'
  | 'hover:from-purple'
  | 'focus:from-purple'
  | 'container:via-purple'
  | 'container-mid:via-purple'
  | 'container-large:via-purple'
  | 'hover:via-purple'
  | 'focus:via-purple'
  | 'container:to-purple'
  | 'container-mid:to-purple'
  | 'container-large:to-purple'
  | 'hover:to-purple'
  | 'focus:to-purple'
  | 'container:from-pink'
  | 'container-mid:from-pink'
  | 'container-large:from-pink'
  | 'hover:from-pink'
  | 'focus:from-pink'
  | 'container:via-pink'
  | 'container-mid:via-pink'
  | 'container-large:via-pink'
  | 'hover:via-pink'
  | 'focus:via-pink'
  | 'container:to-pink'
  | 'container-mid:to-pink'
  | 'container-large:to-pink'
  | 'hover:to-pink'
  | 'focus:to-pink'
  | 'container:from-soft-gold'
  | 'container-mid:from-soft-gold'
  | 'container-large:from-soft-gold'
  | 'hover:from-soft-gold'
  | 'focus:from-soft-gold'
  | 'container:via-soft-gold'
  | 'container-mid:via-soft-gold'
  | 'container-large:via-soft-gold'
  | 'hover:via-soft-gold'
  | 'focus:via-soft-gold'
  | 'container:to-soft-gold'
  | 'container-mid:to-soft-gold'
  | 'container-large:to-soft-gold'
  | 'hover:to-soft-gold'
  | 'focus:to-soft-gold'
  | 'container:from-gold'
  | 'container-mid:from-gold'
  | 'container-large:from-gold'
  | 'hover:from-gold'
  | 'focus:from-gold'
  | 'container:via-gold'
  | 'container-mid:via-gold'
  | 'container-large:via-gold'
  | 'hover:via-gold'
  | 'focus:via-gold'
  | 'container:to-gold'
  | 'container-mid:to-gold'
  | 'container-large:to-gold'
  | 'hover:to-gold'
  | 'focus:to-gold'
  | 'container:from-darkGold'
  | 'container-mid:from-darkGold'
  | 'container-large:from-darkGold'
  | 'hover:from-darkGold'
  | 'focus:from-darkGold'
  | 'container:via-darkGold'
  | 'container-mid:via-darkGold'
  | 'container-large:via-darkGold'
  | 'hover:via-darkGold'
  | 'focus:via-darkGold'
  | 'container:to-darkGold'
  | 'container-mid:to-darkGold'
  | 'container-large:to-darkGold'
  | 'hover:to-darkGold'
  | 'focus:to-darkGold'
  | 'container:from-sand'
  | 'container-mid:from-sand'
  | 'container-large:from-sand'
  | 'hover:from-sand'
  | 'focus:from-sand'
  | 'container:via-sand'
  | 'container-mid:via-sand'
  | 'container-large:via-sand'
  | 'hover:via-sand'
  | 'focus:via-sand'
  | 'container:to-sand'
  | 'container-mid:to-sand'
  | 'container-large:to-sand'
  | 'hover:to-sand'
  | 'focus:to-sand'
  | 'container:from-lightSand'
  | 'container-mid:from-lightSand'
  | 'container-large:from-lightSand'
  | 'hover:from-lightSand'
  | 'focus:from-lightSand'
  | 'container:via-lightSand'
  | 'container-mid:via-lightSand'
  | 'container-large:via-lightSand'
  | 'hover:via-lightSand'
  | 'focus:via-lightSand'
  | 'container:to-lightSand'
  | 'container-mid:to-lightSand'
  | 'container-large:to-lightSand'
  | 'hover:to-lightSand'
  | 'focus:to-lightSand'
  | 'container:from-ecru'
  | 'container-mid:from-ecru'
  | 'container-large:from-ecru'
  | 'hover:from-ecru'
  | 'focus:from-ecru'
  | 'container:via-ecru'
  | 'container-mid:via-ecru'
  | 'container-large:via-ecru'
  | 'hover:via-ecru'
  | 'focus:via-ecru'
  | 'container:to-ecru'
  | 'container-mid:to-ecru'
  | 'container-large:to-ecru'
  | 'hover:to-ecru'
  | 'focus:to-ecru'
  | 'container:from-lemon'
  | 'container-mid:from-lemon'
  | 'container-large:from-lemon'
  | 'hover:from-lemon'
  | 'focus:from-lemon'
  | 'container:via-lemon'
  | 'container-mid:via-lemon'
  | 'container-large:via-lemon'
  | 'hover:via-lemon'
  | 'focus:via-lemon'
  | 'container:to-lemon'
  | 'container-mid:to-lemon'
  | 'container-large:to-lemon'
  | 'hover:to-lemon'
  | 'focus:to-lemon'
  | 'container:from-ink'
  | 'container-mid:from-ink'
  | 'container-large:from-ink'
  | 'hover:from-ink'
  | 'focus:from-ink'
  | 'container:via-ink'
  | 'container-mid:via-ink'
  | 'container-large:via-ink'
  | 'hover:via-ink'
  | 'focus:via-ink'
  | 'container:to-ink'
  | 'container-mid:to-ink'
  | 'container-large:to-ink'
  | 'hover:to-ink'
  | 'focus:to-ink'
  | 'container:from-steel'
  | 'container-mid:from-steel'
  | 'container-large:from-steel'
  | 'hover:from-steel'
  | 'focus:from-steel'
  | 'container:via-steel'
  | 'container-mid:via-steel'
  | 'container-large:via-steel'
  | 'hover:via-steel'
  | 'focus:via-steel'
  | 'container:to-steel'
  | 'container-mid:to-steel'
  | 'container-large:to-steel'
  | 'hover:to-steel'
  | 'focus:to-steel'
  | 'container:from-lightGray'
  | 'container-mid:from-lightGray'
  | 'container-large:from-lightGray'
  | 'hover:from-lightGray'
  | 'focus:from-lightGray'
  | 'container:via-lightGray'
  | 'container-mid:via-lightGray'
  | 'container-large:via-lightGray'
  | 'hover:via-lightGray'
  | 'focus:via-lightGray'
  | 'container:to-lightGray'
  | 'container-mid:to-lightGray'
  | 'container-large:to-lightGray'
  | 'hover:to-lightGray'
  | 'focus:to-lightGray'
  | 'container:from-unassumingGray'
  | 'container-mid:from-unassumingGray'
  | 'container-large:from-unassumingGray'
  | 'hover:from-unassumingGray'
  | 'focus:from-unassumingGray'
  | 'container:via-unassumingGray'
  | 'container-mid:via-unassumingGray'
  | 'container-large:via-unassumingGray'
  | 'hover:via-unassumingGray'
  | 'focus:via-unassumingGray'
  | 'container:to-unassumingGray'
  | 'container-mid:to-unassumingGray'
  | 'container-large:to-unassumingGray'
  | 'hover:to-unassumingGray'
  | 'focus:to-unassumingGray'
  | 'container:from-disabledTextGray'
  | 'container-mid:from-disabledTextGray'
  | 'container-large:from-disabledTextGray'
  | 'hover:from-disabledTextGray'
  | 'focus:from-disabledTextGray'
  | 'container:via-disabledTextGray'
  | 'container-mid:via-disabledTextGray'
  | 'container-large:via-disabledTextGray'
  | 'hover:via-disabledTextGray'
  | 'focus:via-disabledTextGray'
  | 'container:to-disabledTextGray'
  | 'container-mid:to-disabledTextGray'
  | 'container-large:to-disabledTextGray'
  | 'hover:to-disabledTextGray'
  | 'focus:to-disabledTextGray'
  | 'container:from-lightRed'
  | 'container-mid:from-lightRed'
  | 'container-large:from-lightRed'
  | 'hover:from-lightRed'
  | 'focus:from-lightRed'
  | 'container:via-lightRed'
  | 'container-mid:via-lightRed'
  | 'container-large:via-lightRed'
  | 'hover:via-lightRed'
  | 'focus:via-lightRed'
  | 'container:to-lightRed'
  | 'container-mid:to-lightRed'
  | 'container-large:to-lightRed'
  | 'hover:to-lightRed'
  | 'focus:to-lightRed'
  | 'container:from-mistakeRed'
  | 'container-mid:from-mistakeRed'
  | 'container-large:from-mistakeRed'
  | 'hover:from-mistakeRed'
  | 'focus:from-mistakeRed'
  | 'container:via-mistakeRed'
  | 'container-mid:via-mistakeRed'
  | 'container-large:via-mistakeRed'
  | 'hover:via-mistakeRed'
  | 'focus:via-mistakeRed'
  | 'container:to-mistakeRed'
  | 'container-mid:to-mistakeRed'
  | 'container-large:to-mistakeRed'
  | 'hover:to-mistakeRed'
  | 'focus:to-mistakeRed'
  | 'container:from-grey'
  | 'container-mid:from-grey'
  | 'container-large:from-grey'
  | 'hover:from-grey'
  | 'focus:from-grey'
  | 'container:via-grey'
  | 'container-mid:via-grey'
  | 'container-large:via-grey'
  | 'hover:via-grey'
  | 'focus:via-grey'
  | 'container:to-grey'
  | 'container-mid:to-grey'
  | 'container-large:to-grey'
  | 'hover:to-grey'
  | 'focus:to-grey'
  | 'container:from-olive-400'
  | 'container-mid:from-olive-400'
  | 'container-large:from-olive-400'
  | 'hover:from-olive-400'
  | 'focus:from-olive-400'
  | 'container:via-olive-400'
  | 'container-mid:via-olive-400'
  | 'container-large:via-olive-400'
  | 'hover:via-olive-400'
  | 'focus:via-olive-400'
  | 'container:to-olive-400'
  | 'container-mid:to-olive-400'
  | 'container-large:to-olive-400'
  | 'hover:to-olive-400'
  | 'focus:to-olive-400'
  | 'container:from-olive-600'
  | 'container-mid:from-olive-600'
  | 'container-large:from-olive-600'
  | 'hover:from-olive-600'
  | 'focus:from-olive-600'
  | 'container:via-olive-600'
  | 'container-mid:via-olive-600'
  | 'container-large:via-olive-600'
  | 'hover:via-olive-600'
  | 'focus:via-olive-600'
  | 'container:to-olive-600'
  | 'container-mid:to-olive-600'
  | 'container-large:to-olive-600'
  | 'hover:to-olive-600'
  | 'focus:to-olive-600'
  | 'container:from-olive-800'
  | 'container-mid:from-olive-800'
  | 'container-large:from-olive-800'
  | 'hover:from-olive-800'
  | 'focus:from-olive-800'
  | 'container:via-olive-800'
  | 'container-mid:via-olive-800'
  | 'container-large:via-olive-800'
  | 'hover:via-olive-800'
  | 'focus:via-olive-800'
  | 'container:to-olive-800'
  | 'container-mid:to-olive-800'
  | 'container-large:to-olive-800'
  | 'hover:to-olive-800'
  | 'focus:to-olive-800'
  | 'container:bg-opacity-0'
  | 'container-mid:bg-opacity-0'
  | 'container-large:bg-opacity-0'
  | 'hover:bg-opacity-0'
  | 'focus:bg-opacity-0'
  | 'container:bg-opacity-25'
  | 'container-mid:bg-opacity-25'
  | 'container-large:bg-opacity-25'
  | 'hover:bg-opacity-25'
  | 'focus:bg-opacity-25'
  | 'container:bg-opacity-50'
  | 'container-mid:bg-opacity-50'
  | 'container-large:bg-opacity-50'
  | 'hover:bg-opacity-50'
  | 'focus:bg-opacity-50'
  | 'container:bg-opacity-75'
  | 'container-mid:bg-opacity-75'
  | 'container-large:bg-opacity-75'
  | 'hover:bg-opacity-75'
  | 'focus:bg-opacity-75'
  | 'container:bg-opacity-100'
  | 'container-mid:bg-opacity-100'
  | 'container-large:bg-opacity-100'
  | 'hover:bg-opacity-100'
  | 'focus:bg-opacity-100'
  | 'container:bg-bottom'
  | 'container-mid:bg-bottom'
  | 'container-large:bg-bottom'
  | 'container:bg-center'
  | 'container-mid:bg-center'
  | 'container-large:bg-center'
  | 'container:bg-left'
  | 'container-mid:bg-left'
  | 'container-large:bg-left'
  | 'container:bg-left-bottom'
  | 'container-mid:bg-left-bottom'
  | 'container-large:bg-left-bottom'
  | 'container:bg-left-top'
  | 'container-mid:bg-left-top'
  | 'container-large:bg-left-top'
  | 'container:bg-right'
  | 'container-mid:bg-right'
  | 'container-large:bg-right'
  | 'container:bg-right-bottom'
  | 'container-mid:bg-right-bottom'
  | 'container-large:bg-right-bottom'
  | 'container:bg-right-top'
  | 'container-mid:bg-right-top'
  | 'container-large:bg-right-top'
  | 'container:bg-top'
  | 'container-mid:bg-top'
  | 'container-large:bg-top'
  | 'container:bg-repeat'
  | 'container-mid:bg-repeat'
  | 'container-large:bg-repeat'
  | 'container:bg-no-repeat'
  | 'container-mid:bg-no-repeat'
  | 'container-large:bg-no-repeat'
  | 'container:bg-repeat-x'
  | 'container-mid:bg-repeat-x'
  | 'container-large:bg-repeat-x'
  | 'container:bg-repeat-y'
  | 'container-mid:bg-repeat-y'
  | 'container-large:bg-repeat-y'
  | 'container:bg-repeat-round'
  | 'container-mid:bg-repeat-round'
  | 'container-large:bg-repeat-round'
  | 'container:bg-repeat-space'
  | 'container-mid:bg-repeat-space'
  | 'container-large:bg-repeat-space'
  | 'container:bg-auto'
  | 'container-mid:bg-auto'
  | 'container-large:bg-auto'
  | 'container:bg-cover'
  | 'container-mid:bg-cover'
  | 'container-large:bg-cover'
  | 'container:bg-contain'
  | 'container-mid:bg-contain'
  | 'container-large:bg-contain'
  | 'container:bg-fullWidth'
  | 'container-mid:bg-fullWidth'
  | 'container-large:bg-fullWidth'
  | 'container:border-collapse'
  | 'container-mid:border-collapse'
  | 'container-large:border-collapse'
  | 'container:border-separate'
  | 'container-mid:border-separate'
  | 'container-large:border-separate'
  | 'container:border-transparent'
  | 'container-mid:border-transparent'
  | 'container-large:border-transparent'
  | 'hover:border-transparent'
  | 'focus:border-transparent'
  | 'focus-within:border-transparent'
  | 'container:border-current'
  | 'container-mid:border-current'
  | 'container-large:border-current'
  | 'hover:border-current'
  | 'focus:border-current'
  | 'focus-within:border-current'
  | 'container:border-black'
  | 'container-mid:border-black'
  | 'container-large:border-black'
  | 'hover:border-black'
  | 'focus:border-black'
  | 'focus-within:border-black'
  | 'container:border-white'
  | 'container-mid:border-white'
  | 'container-large:border-white'
  | 'hover:border-white'
  | 'focus:border-white'
  | 'focus-within:border-white'
  | 'container:border-gray-100'
  | 'container-mid:border-gray-100'
  | 'container-large:border-gray-100'
  | 'hover:border-gray-100'
  | 'focus:border-gray-100'
  | 'focus-within:border-gray-100'
  | 'container:border-gray-200'
  | 'container-mid:border-gray-200'
  | 'container-large:border-gray-200'
  | 'hover:border-gray-200'
  | 'focus:border-gray-200'
  | 'focus-within:border-gray-200'
  | 'container:border-gray-300'
  | 'container-mid:border-gray-300'
  | 'container-large:border-gray-300'
  | 'hover:border-gray-300'
  | 'focus:border-gray-300'
  | 'focus-within:border-gray-300'
  | 'container:border-gray-400'
  | 'container-mid:border-gray-400'
  | 'container-large:border-gray-400'
  | 'hover:border-gray-400'
  | 'focus:border-gray-400'
  | 'focus-within:border-gray-400'
  | 'container:border-gray-500'
  | 'container-mid:border-gray-500'
  | 'container-large:border-gray-500'
  | 'hover:border-gray-500'
  | 'focus:border-gray-500'
  | 'focus-within:border-gray-500'
  | 'container:border-gray-600'
  | 'container-mid:border-gray-600'
  | 'container-large:border-gray-600'
  | 'hover:border-gray-600'
  | 'focus:border-gray-600'
  | 'focus-within:border-gray-600'
  | 'container:border-gray-700'
  | 'container-mid:border-gray-700'
  | 'container-large:border-gray-700'
  | 'hover:border-gray-700'
  | 'focus:border-gray-700'
  | 'focus-within:border-gray-700'
  | 'container:border-gray-800'
  | 'container-mid:border-gray-800'
  | 'container-large:border-gray-800'
  | 'hover:border-gray-800'
  | 'focus:border-gray-800'
  | 'focus-within:border-gray-800'
  | 'container:border-gray-900'
  | 'container-mid:border-gray-900'
  | 'container-large:border-gray-900'
  | 'hover:border-gray-900'
  | 'focus:border-gray-900'
  | 'focus-within:border-gray-900'
  | 'container:border-red-100'
  | 'container-mid:border-red-100'
  | 'container-large:border-red-100'
  | 'hover:border-red-100'
  | 'focus:border-red-100'
  | 'focus-within:border-red-100'
  | 'container:border-red-200'
  | 'container-mid:border-red-200'
  | 'container-large:border-red-200'
  | 'hover:border-red-200'
  | 'focus:border-red-200'
  | 'focus-within:border-red-200'
  | 'container:border-red-300'
  | 'container-mid:border-red-300'
  | 'container-large:border-red-300'
  | 'hover:border-red-300'
  | 'focus:border-red-300'
  | 'focus-within:border-red-300'
  | 'container:border-red-400'
  | 'container-mid:border-red-400'
  | 'container-large:border-red-400'
  | 'hover:border-red-400'
  | 'focus:border-red-400'
  | 'focus-within:border-red-400'
  | 'container:border-red-500'
  | 'container-mid:border-red-500'
  | 'container-large:border-red-500'
  | 'hover:border-red-500'
  | 'focus:border-red-500'
  | 'focus-within:border-red-500'
  | 'container:border-red-600'
  | 'container-mid:border-red-600'
  | 'container-large:border-red-600'
  | 'hover:border-red-600'
  | 'focus:border-red-600'
  | 'focus-within:border-red-600'
  | 'container:border-red-700'
  | 'container-mid:border-red-700'
  | 'container-large:border-red-700'
  | 'hover:border-red-700'
  | 'focus:border-red-700'
  | 'focus-within:border-red-700'
  | 'container:border-red-800'
  | 'container-mid:border-red-800'
  | 'container-large:border-red-800'
  | 'hover:border-red-800'
  | 'focus:border-red-800'
  | 'focus-within:border-red-800'
  | 'container:border-red-900'
  | 'container-mid:border-red-900'
  | 'container-large:border-red-900'
  | 'hover:border-red-900'
  | 'focus:border-red-900'
  | 'focus-within:border-red-900'
  | 'container:border-orange-100'
  | 'container-mid:border-orange-100'
  | 'container-large:border-orange-100'
  | 'hover:border-orange-100'
  | 'focus:border-orange-100'
  | 'focus-within:border-orange-100'
  | 'container:border-orange-200'
  | 'container-mid:border-orange-200'
  | 'container-large:border-orange-200'
  | 'hover:border-orange-200'
  | 'focus:border-orange-200'
  | 'focus-within:border-orange-200'
  | 'container:border-orange-300'
  | 'container-mid:border-orange-300'
  | 'container-large:border-orange-300'
  | 'hover:border-orange-300'
  | 'focus:border-orange-300'
  | 'focus-within:border-orange-300'
  | 'container:border-orange-400'
  | 'container-mid:border-orange-400'
  | 'container-large:border-orange-400'
  | 'hover:border-orange-400'
  | 'focus:border-orange-400'
  | 'focus-within:border-orange-400'
  | 'container:border-orange-500'
  | 'container-mid:border-orange-500'
  | 'container-large:border-orange-500'
  | 'hover:border-orange-500'
  | 'focus:border-orange-500'
  | 'focus-within:border-orange-500'
  | 'container:border-orange-600'
  | 'container-mid:border-orange-600'
  | 'container-large:border-orange-600'
  | 'hover:border-orange-600'
  | 'focus:border-orange-600'
  | 'focus-within:border-orange-600'
  | 'container:border-orange-700'
  | 'container-mid:border-orange-700'
  | 'container-large:border-orange-700'
  | 'hover:border-orange-700'
  | 'focus:border-orange-700'
  | 'focus-within:border-orange-700'
  | 'container:border-orange-800'
  | 'container-mid:border-orange-800'
  | 'container-large:border-orange-800'
  | 'hover:border-orange-800'
  | 'focus:border-orange-800'
  | 'focus-within:border-orange-800'
  | 'container:border-orange-900'
  | 'container-mid:border-orange-900'
  | 'container-large:border-orange-900'
  | 'hover:border-orange-900'
  | 'focus:border-orange-900'
  | 'focus-within:border-orange-900'
  | 'container:border-yellow-100'
  | 'container-mid:border-yellow-100'
  | 'container-large:border-yellow-100'
  | 'hover:border-yellow-100'
  | 'focus:border-yellow-100'
  | 'focus-within:border-yellow-100'
  | 'container:border-yellow-200'
  | 'container-mid:border-yellow-200'
  | 'container-large:border-yellow-200'
  | 'hover:border-yellow-200'
  | 'focus:border-yellow-200'
  | 'focus-within:border-yellow-200'
  | 'container:border-yellow-300'
  | 'container-mid:border-yellow-300'
  | 'container-large:border-yellow-300'
  | 'hover:border-yellow-300'
  | 'focus:border-yellow-300'
  | 'focus-within:border-yellow-300'
  | 'container:border-yellow-400'
  | 'container-mid:border-yellow-400'
  | 'container-large:border-yellow-400'
  | 'hover:border-yellow-400'
  | 'focus:border-yellow-400'
  | 'focus-within:border-yellow-400'
  | 'container:border-yellow-500'
  | 'container-mid:border-yellow-500'
  | 'container-large:border-yellow-500'
  | 'hover:border-yellow-500'
  | 'focus:border-yellow-500'
  | 'focus-within:border-yellow-500'
  | 'container:border-yellow-600'
  | 'container-mid:border-yellow-600'
  | 'container-large:border-yellow-600'
  | 'hover:border-yellow-600'
  | 'focus:border-yellow-600'
  | 'focus-within:border-yellow-600'
  | 'container:border-yellow-700'
  | 'container-mid:border-yellow-700'
  | 'container-large:border-yellow-700'
  | 'hover:border-yellow-700'
  | 'focus:border-yellow-700'
  | 'focus-within:border-yellow-700'
  | 'container:border-yellow-800'
  | 'container-mid:border-yellow-800'
  | 'container-large:border-yellow-800'
  | 'hover:border-yellow-800'
  | 'focus:border-yellow-800'
  | 'focus-within:border-yellow-800'
  | 'container:border-yellow-900'
  | 'container-mid:border-yellow-900'
  | 'container-large:border-yellow-900'
  | 'hover:border-yellow-900'
  | 'focus:border-yellow-900'
  | 'focus-within:border-yellow-900'
  | 'container:border-green-100'
  | 'container-mid:border-green-100'
  | 'container-large:border-green-100'
  | 'hover:border-green-100'
  | 'focus:border-green-100'
  | 'focus-within:border-green-100'
  | 'container:border-green-200'
  | 'container-mid:border-green-200'
  | 'container-large:border-green-200'
  | 'hover:border-green-200'
  | 'focus:border-green-200'
  | 'focus-within:border-green-200'
  | 'container:border-green-300'
  | 'container-mid:border-green-300'
  | 'container-large:border-green-300'
  | 'hover:border-green-300'
  | 'focus:border-green-300'
  | 'focus-within:border-green-300'
  | 'container:border-green-400'
  | 'container-mid:border-green-400'
  | 'container-large:border-green-400'
  | 'hover:border-green-400'
  | 'focus:border-green-400'
  | 'focus-within:border-green-400'
  | 'container:border-green-500'
  | 'container-mid:border-green-500'
  | 'container-large:border-green-500'
  | 'hover:border-green-500'
  | 'focus:border-green-500'
  | 'focus-within:border-green-500'
  | 'container:border-green-600'
  | 'container-mid:border-green-600'
  | 'container-large:border-green-600'
  | 'hover:border-green-600'
  | 'focus:border-green-600'
  | 'focus-within:border-green-600'
  | 'container:border-green-700'
  | 'container-mid:border-green-700'
  | 'container-large:border-green-700'
  | 'hover:border-green-700'
  | 'focus:border-green-700'
  | 'focus-within:border-green-700'
  | 'container:border-green-800'
  | 'container-mid:border-green-800'
  | 'container-large:border-green-800'
  | 'hover:border-green-800'
  | 'focus:border-green-800'
  | 'focus-within:border-green-800'
  | 'container:border-green-900'
  | 'container-mid:border-green-900'
  | 'container-large:border-green-900'
  | 'hover:border-green-900'
  | 'focus:border-green-900'
  | 'focus-within:border-green-900'
  | 'container:border-teal-100'
  | 'container-mid:border-teal-100'
  | 'container-large:border-teal-100'
  | 'hover:border-teal-100'
  | 'focus:border-teal-100'
  | 'focus-within:border-teal-100'
  | 'container:border-teal-200'
  | 'container-mid:border-teal-200'
  | 'container-large:border-teal-200'
  | 'hover:border-teal-200'
  | 'focus:border-teal-200'
  | 'focus-within:border-teal-200'
  | 'container:border-teal-300'
  | 'container-mid:border-teal-300'
  | 'container-large:border-teal-300'
  | 'hover:border-teal-300'
  | 'focus:border-teal-300'
  | 'focus-within:border-teal-300'
  | 'container:border-teal-400'
  | 'container-mid:border-teal-400'
  | 'container-large:border-teal-400'
  | 'hover:border-teal-400'
  | 'focus:border-teal-400'
  | 'focus-within:border-teal-400'
  | 'container:border-teal-500'
  | 'container-mid:border-teal-500'
  | 'container-large:border-teal-500'
  | 'hover:border-teal-500'
  | 'focus:border-teal-500'
  | 'focus-within:border-teal-500'
  | 'container:border-teal-600'
  | 'container-mid:border-teal-600'
  | 'container-large:border-teal-600'
  | 'hover:border-teal-600'
  | 'focus:border-teal-600'
  | 'focus-within:border-teal-600'
  | 'container:border-teal-700'
  | 'container-mid:border-teal-700'
  | 'container-large:border-teal-700'
  | 'hover:border-teal-700'
  | 'focus:border-teal-700'
  | 'focus-within:border-teal-700'
  | 'container:border-teal-800'
  | 'container-mid:border-teal-800'
  | 'container-large:border-teal-800'
  | 'hover:border-teal-800'
  | 'focus:border-teal-800'
  | 'focus-within:border-teal-800'
  | 'container:border-teal-900'
  | 'container-mid:border-teal-900'
  | 'container-large:border-teal-900'
  | 'hover:border-teal-900'
  | 'focus:border-teal-900'
  | 'focus-within:border-teal-900'
  | 'container:border-blue-100'
  | 'container-mid:border-blue-100'
  | 'container-large:border-blue-100'
  | 'hover:border-blue-100'
  | 'focus:border-blue-100'
  | 'focus-within:border-blue-100'
  | 'container:border-blue-200'
  | 'container-mid:border-blue-200'
  | 'container-large:border-blue-200'
  | 'hover:border-blue-200'
  | 'focus:border-blue-200'
  | 'focus-within:border-blue-200'
  | 'container:border-blue-300'
  | 'container-mid:border-blue-300'
  | 'container-large:border-blue-300'
  | 'hover:border-blue-300'
  | 'focus:border-blue-300'
  | 'focus-within:border-blue-300'
  | 'container:border-blue-400'
  | 'container-mid:border-blue-400'
  | 'container-large:border-blue-400'
  | 'hover:border-blue-400'
  | 'focus:border-blue-400'
  | 'focus-within:border-blue-400'
  | 'container:border-blue-500'
  | 'container-mid:border-blue-500'
  | 'container-large:border-blue-500'
  | 'hover:border-blue-500'
  | 'focus:border-blue-500'
  | 'focus-within:border-blue-500'
  | 'container:border-blue-600'
  | 'container-mid:border-blue-600'
  | 'container-large:border-blue-600'
  | 'hover:border-blue-600'
  | 'focus:border-blue-600'
  | 'focus-within:border-blue-600'
  | 'container:border-blue-700'
  | 'container-mid:border-blue-700'
  | 'container-large:border-blue-700'
  | 'hover:border-blue-700'
  | 'focus:border-blue-700'
  | 'focus-within:border-blue-700'
  | 'container:border-blue-800'
  | 'container-mid:border-blue-800'
  | 'container-large:border-blue-800'
  | 'hover:border-blue-800'
  | 'focus:border-blue-800'
  | 'focus-within:border-blue-800'
  | 'container:border-blue-900'
  | 'container-mid:border-blue-900'
  | 'container-large:border-blue-900'
  | 'hover:border-blue-900'
  | 'focus:border-blue-900'
  | 'focus-within:border-blue-900'
  | 'container:border-indigo-100'
  | 'container-mid:border-indigo-100'
  | 'container-large:border-indigo-100'
  | 'hover:border-indigo-100'
  | 'focus:border-indigo-100'
  | 'focus-within:border-indigo-100'
  | 'container:border-indigo-200'
  | 'container-mid:border-indigo-200'
  | 'container-large:border-indigo-200'
  | 'hover:border-indigo-200'
  | 'focus:border-indigo-200'
  | 'focus-within:border-indigo-200'
  | 'container:border-indigo-300'
  | 'container-mid:border-indigo-300'
  | 'container-large:border-indigo-300'
  | 'hover:border-indigo-300'
  | 'focus:border-indigo-300'
  | 'focus-within:border-indigo-300'
  | 'container:border-indigo-400'
  | 'container-mid:border-indigo-400'
  | 'container-large:border-indigo-400'
  | 'hover:border-indigo-400'
  | 'focus:border-indigo-400'
  | 'focus-within:border-indigo-400'
  | 'container:border-indigo-500'
  | 'container-mid:border-indigo-500'
  | 'container-large:border-indigo-500'
  | 'hover:border-indigo-500'
  | 'focus:border-indigo-500'
  | 'focus-within:border-indigo-500'
  | 'container:border-indigo-600'
  | 'container-mid:border-indigo-600'
  | 'container-large:border-indigo-600'
  | 'hover:border-indigo-600'
  | 'focus:border-indigo-600'
  | 'focus-within:border-indigo-600'
  | 'container:border-indigo-700'
  | 'container-mid:border-indigo-700'
  | 'container-large:border-indigo-700'
  | 'hover:border-indigo-700'
  | 'focus:border-indigo-700'
  | 'focus-within:border-indigo-700'
  | 'container:border-indigo-800'
  | 'container-mid:border-indigo-800'
  | 'container-large:border-indigo-800'
  | 'hover:border-indigo-800'
  | 'focus:border-indigo-800'
  | 'focus-within:border-indigo-800'
  | 'container:border-indigo-900'
  | 'container-mid:border-indigo-900'
  | 'container-large:border-indigo-900'
  | 'hover:border-indigo-900'
  | 'focus:border-indigo-900'
  | 'focus-within:border-indigo-900'
  | 'container:border-purple-100'
  | 'container-mid:border-purple-100'
  | 'container-large:border-purple-100'
  | 'hover:border-purple-100'
  | 'focus:border-purple-100'
  | 'focus-within:border-purple-100'
  | 'container:border-purple-200'
  | 'container-mid:border-purple-200'
  | 'container-large:border-purple-200'
  | 'hover:border-purple-200'
  | 'focus:border-purple-200'
  | 'focus-within:border-purple-200'
  | 'container:border-purple-300'
  | 'container-mid:border-purple-300'
  | 'container-large:border-purple-300'
  | 'hover:border-purple-300'
  | 'focus:border-purple-300'
  | 'focus-within:border-purple-300'
  | 'container:border-purple-400'
  | 'container-mid:border-purple-400'
  | 'container-large:border-purple-400'
  | 'hover:border-purple-400'
  | 'focus:border-purple-400'
  | 'focus-within:border-purple-400'
  | 'container:border-purple-500'
  | 'container-mid:border-purple-500'
  | 'container-large:border-purple-500'
  | 'hover:border-purple-500'
  | 'focus:border-purple-500'
  | 'focus-within:border-purple-500'
  | 'container:border-purple-600'
  | 'container-mid:border-purple-600'
  | 'container-large:border-purple-600'
  | 'hover:border-purple-600'
  | 'focus:border-purple-600'
  | 'focus-within:border-purple-600'
  | 'container:border-purple-700'
  | 'container-mid:border-purple-700'
  | 'container-large:border-purple-700'
  | 'hover:border-purple-700'
  | 'focus:border-purple-700'
  | 'focus-within:border-purple-700'
  | 'container:border-purple-800'
  | 'container-mid:border-purple-800'
  | 'container-large:border-purple-800'
  | 'hover:border-purple-800'
  | 'focus:border-purple-800'
  | 'focus-within:border-purple-800'
  | 'container:border-purple-900'
  | 'container-mid:border-purple-900'
  | 'container-large:border-purple-900'
  | 'hover:border-purple-900'
  | 'focus:border-purple-900'
  | 'focus-within:border-purple-900'
  | 'container:border-pink-100'
  | 'container-mid:border-pink-100'
  | 'container-large:border-pink-100'
  | 'hover:border-pink-100'
  | 'focus:border-pink-100'
  | 'focus-within:border-pink-100'
  | 'container:border-pink-200'
  | 'container-mid:border-pink-200'
  | 'container-large:border-pink-200'
  | 'hover:border-pink-200'
  | 'focus:border-pink-200'
  | 'focus-within:border-pink-200'
  | 'container:border-pink-300'
  | 'container-mid:border-pink-300'
  | 'container-large:border-pink-300'
  | 'hover:border-pink-300'
  | 'focus:border-pink-300'
  | 'focus-within:border-pink-300'
  | 'container:border-pink-400'
  | 'container-mid:border-pink-400'
  | 'container-large:border-pink-400'
  | 'hover:border-pink-400'
  | 'focus:border-pink-400'
  | 'focus-within:border-pink-400'
  | 'container:border-pink-500'
  | 'container-mid:border-pink-500'
  | 'container-large:border-pink-500'
  | 'hover:border-pink-500'
  | 'focus:border-pink-500'
  | 'focus-within:border-pink-500'
  | 'container:border-pink-600'
  | 'container-mid:border-pink-600'
  | 'container-large:border-pink-600'
  | 'hover:border-pink-600'
  | 'focus:border-pink-600'
  | 'focus-within:border-pink-600'
  | 'container:border-pink-700'
  | 'container-mid:border-pink-700'
  | 'container-large:border-pink-700'
  | 'hover:border-pink-700'
  | 'focus:border-pink-700'
  | 'focus-within:border-pink-700'
  | 'container:border-pink-800'
  | 'container-mid:border-pink-800'
  | 'container-large:border-pink-800'
  | 'hover:border-pink-800'
  | 'focus:border-pink-800'
  | 'focus-within:border-pink-800'
  | 'container:border-pink-900'
  | 'container-mid:border-pink-900'
  | 'container-large:border-pink-900'
  | 'hover:border-pink-900'
  | 'focus:border-pink-900'
  | 'focus-within:border-pink-900'
  | 'container:border-opacity-0'
  | 'container-mid:border-opacity-0'
  | 'container-large:border-opacity-0'
  | 'hover:border-opacity-0'
  | 'focus:border-opacity-0'
  | 'container:border-opacity-25'
  | 'container-mid:border-opacity-25'
  | 'container-large:border-opacity-25'
  | 'hover:border-opacity-25'
  | 'focus:border-opacity-25'
  | 'container:border-opacity-50'
  | 'container-mid:border-opacity-50'
  | 'container-large:border-opacity-50'
  | 'hover:border-opacity-50'
  | 'focus:border-opacity-50'
  | 'container:border-opacity-75'
  | 'container-mid:border-opacity-75'
  | 'container-large:border-opacity-75'
  | 'hover:border-opacity-75'
  | 'focus:border-opacity-75'
  | 'container:border-opacity-100'
  | 'container-mid:border-opacity-100'
  | 'container-large:border-opacity-100'
  | 'hover:border-opacity-100'
  | 'focus:border-opacity-100'
  | 'container:rounded-t-4'
  | 'container-mid:rounded-t-4'
  | 'container-large:rounded-t-4'
  | 'container:rounded-r-4'
  | 'container-mid:rounded-r-4'
  | 'container-large:rounded-r-4'
  | 'container:rounded-b-4'
  | 'container-mid:rounded-b-4'
  | 'container-large:rounded-b-4'
  | 'container:rounded-l-4'
  | 'container-mid:rounded-l-4'
  | 'container-large:rounded-l-4'
  | 'container:rounded-tr-4'
  | 'container-mid:rounded-tr-4'
  | 'container-large:rounded-tr-4'
  | 'container:rounded-tl-4'
  | 'container-mid:rounded-tl-4'
  | 'container-large:rounded-tl-4'
  | 'container:rounded-br-4'
  | 'container-mid:rounded-br-4'
  | 'container-large:rounded-br-4'
  | 'container:rounded-bl-4'
  | 'container-mid:rounded-bl-4'
  | 'container-large:rounded-bl-4'
  | 'container:rounded-4'
  | 'container-mid:rounded-4'
  | 'container-large:rounded-4'
  | 'container:rounded-t-8'
  | 'container-mid:rounded-t-8'
  | 'container-large:rounded-t-8'
  | 'container:rounded-r-8'
  | 'container-mid:rounded-r-8'
  | 'container-large:rounded-r-8'
  | 'container:rounded-b-8'
  | 'container-mid:rounded-b-8'
  | 'container-large:rounded-b-8'
  | 'container:rounded-l-8'
  | 'container-mid:rounded-l-8'
  | 'container-large:rounded-l-8'
  | 'container:rounded-tr-8'
  | 'container-mid:rounded-tr-8'
  | 'container-large:rounded-tr-8'
  | 'container:rounded-tl-8'
  | 'container-mid:rounded-tl-8'
  | 'container-large:rounded-tl-8'
  | 'container:rounded-br-8'
  | 'container-mid:rounded-br-8'
  | 'container-large:rounded-br-8'
  | 'container:rounded-bl-8'
  | 'container-mid:rounded-bl-8'
  | 'container-large:rounded-bl-8'
  | 'container:rounded-8'
  | 'container-mid:rounded-8'
  | 'container-large:rounded-8'
  | 'container:rounded-t-none'
  | 'container-mid:rounded-t-none'
  | 'container-large:rounded-t-none'
  | 'container:rounded-r-none'
  | 'container-mid:rounded-r-none'
  | 'container-large:rounded-r-none'
  | 'container:rounded-b-none'
  | 'container-mid:rounded-b-none'
  | 'container-large:rounded-b-none'
  | 'container:rounded-l-none'
  | 'container-mid:rounded-l-none'
  | 'container-large:rounded-l-none'
  | 'container:rounded-tr-none'
  | 'container-mid:rounded-tr-none'
  | 'container-large:rounded-tr-none'
  | 'container:rounded-tl-none'
  | 'container-mid:rounded-tl-none'
  | 'container-large:rounded-tl-none'
  | 'container:rounded-br-none'
  | 'container-mid:rounded-br-none'
  | 'container-large:rounded-br-none'
  | 'container:rounded-bl-none'
  | 'container-mid:rounded-bl-none'
  | 'container-large:rounded-bl-none'
  | 'container:rounded-none'
  | 'container-mid:rounded-none'
  | 'container-large:rounded-none'
  | 'container:rounded-t-full'
  | 'container-mid:rounded-t-full'
  | 'container-large:rounded-t-full'
  | 'container:rounded-r-full'
  | 'container-mid:rounded-r-full'
  | 'container-large:rounded-r-full'
  | 'container:rounded-b-full'
  | 'container-mid:rounded-b-full'
  | 'container-large:rounded-b-full'
  | 'container:rounded-l-full'
  | 'container-mid:rounded-l-full'
  | 'container-large:rounded-l-full'
  | 'container:rounded-tr-full'
  | 'container-mid:rounded-tr-full'
  | 'container-large:rounded-tr-full'
  | 'container:rounded-tl-full'
  | 'container-mid:rounded-tl-full'
  | 'container-large:rounded-tl-full'
  | 'container:rounded-br-full'
  | 'container-mid:rounded-br-full'
  | 'container-large:rounded-br-full'
  | 'container:rounded-bl-full'
  | 'container-mid:rounded-bl-full'
  | 'container-large:rounded-bl-full'
  | 'container:rounded-full'
  | 'container-mid:rounded-full'
  | 'container-large:rounded-full'
  | 'container:border-solid'
  | 'container-mid:border-solid'
  | 'container-large:border-solid'
  | 'container:border-dashed'
  | 'container-mid:border-dashed'
  | 'container-large:border-dashed'
  | 'container:border-dotted'
  | 'container-mid:border-dotted'
  | 'container-large:border-dotted'
  | 'container:border-double'
  | 'container-mid:border-double'
  | 'container-large:border-double'
  | 'container:border-none'
  | 'container-mid:border-none'
  | 'container-large:border-none'
  | 'container:border-t-0'
  | 'container-mid:border-t-0'
  | 'container-large:border-t-0'
  | 'hover:border-t-0'
  | 'focus:border-t-0'
  | 'container:border-r-0'
  | 'container-mid:border-r-0'
  | 'container-large:border-r-0'
  | 'hover:border-r-0'
  | 'focus:border-r-0'
  | 'container:border-b-0'
  | 'container-mid:border-b-0'
  | 'container-large:border-b-0'
  | 'hover:border-b-0'
  | 'focus:border-b-0'
  | 'container:border-l-0'
  | 'container-mid:border-l-0'
  | 'container-large:border-l-0'
  | 'hover:border-l-0'
  | 'focus:border-l-0'
  | 'container:border-0'
  | 'container-mid:border-0'
  | 'container-large:border-0'
  | 'hover:border-0'
  | 'focus:border-0'
  | 'container:border-t-2'
  | 'container-mid:border-t-2'
  | 'container-large:border-t-2'
  | 'hover:border-t-2'
  | 'focus:border-t-2'
  | 'container:border-r-2'
  | 'container-mid:border-r-2'
  | 'container-large:border-r-2'
  | 'hover:border-r-2'
  | 'focus:border-r-2'
  | 'container:border-b-2'
  | 'container-mid:border-b-2'
  | 'container-large:border-b-2'
  | 'hover:border-b-2'
  | 'focus:border-b-2'
  | 'container:border-l-2'
  | 'container-mid:border-l-2'
  | 'container-large:border-l-2'
  | 'hover:border-l-2'
  | 'focus:border-l-2'
  | 'container:border-2'
  | 'container-mid:border-2'
  | 'container-large:border-2'
  | 'hover:border-2'
  | 'focus:border-2'
  | 'container:border-t-4'
  | 'container-mid:border-t-4'
  | 'container-large:border-t-4'
  | 'hover:border-t-4'
  | 'focus:border-t-4'
  | 'container:border-r-4'
  | 'container-mid:border-r-4'
  | 'container-large:border-r-4'
  | 'hover:border-r-4'
  | 'focus:border-r-4'
  | 'container:border-b-4'
  | 'container-mid:border-b-4'
  | 'container-large:border-b-4'
  | 'hover:border-b-4'
  | 'focus:border-b-4'
  | 'container:border-l-4'
  | 'container-mid:border-l-4'
  | 'container-large:border-l-4'
  | 'hover:border-l-4'
  | 'focus:border-l-4'
  | 'container:border-4'
  | 'container-mid:border-4'
  | 'container-large:border-4'
  | 'hover:border-4'
  | 'focus:border-4'
  | 'container:border-t-8'
  | 'container-mid:border-t-8'
  | 'container-large:border-t-8'
  | 'hover:border-t-8'
  | 'focus:border-t-8'
  | 'container:border-r-8'
  | 'container-mid:border-r-8'
  | 'container-large:border-r-8'
  | 'hover:border-r-8'
  | 'focus:border-r-8'
  | 'container:border-b-8'
  | 'container-mid:border-b-8'
  | 'container-large:border-b-8'
  | 'hover:border-b-8'
  | 'focus:border-b-8'
  | 'container:border-l-8'
  | 'container-mid:border-l-8'
  | 'container-large:border-l-8'
  | 'hover:border-l-8'
  | 'focus:border-l-8'
  | 'container:border-8'
  | 'container-mid:border-8'
  | 'container-large:border-8'
  | 'hover:border-8'
  | 'focus:border-8'
  | 'container:border-t'
  | 'container-mid:border-t'
  | 'container-large:border-t'
  | 'hover:border-t'
  | 'focus:border-t'
  | 'container:border-r'
  | 'container-mid:border-r'
  | 'container-large:border-r'
  | 'hover:border-r'
  | 'focus:border-r'
  | 'container:border-b'
  | 'container-mid:border-b'
  | 'container-large:border-b'
  | 'hover:border-b'
  | 'focus:border-b'
  | 'container:border-l'
  | 'container-mid:border-l'
  | 'container-large:border-l'
  | 'hover:border-l'
  | 'focus:border-l'
  | 'container:border'
  | 'container-mid:border'
  | 'container-large:border'
  | 'hover:border'
  | 'focus:border'
  | 'container:shadow-xs'
  | 'container-mid:shadow-xs'
  | 'container-large:shadow-xs'
  | 'hover:shadow-xs'
  | 'active:shadow-xs'
  | 'focus:shadow-xs'
  | 'container:shadow-sm'
  | 'container-mid:shadow-sm'
  | 'container-large:shadow-sm'
  | 'hover:shadow-sm'
  | 'active:shadow-sm'
  | 'focus:shadow-sm'
  | 'container:shadow'
  | 'container-mid:shadow'
  | 'container-large:shadow'
  | 'hover:shadow'
  | 'active:shadow'
  | 'focus:shadow'
  | 'container:shadow-md'
  | 'container-mid:shadow-md'
  | 'container-large:shadow-md'
  | 'hover:shadow-md'
  | 'active:shadow-md'
  | 'focus:shadow-md'
  | 'container:shadow-lg'
  | 'container-mid:shadow-lg'
  | 'container-large:shadow-lg'
  | 'hover:shadow-lg'
  | 'active:shadow-lg'
  | 'focus:shadow-lg'
  | 'container:shadow-xl'
  | 'container-mid:shadow-xl'
  | 'container-large:shadow-xl'
  | 'hover:shadow-xl'
  | 'active:shadow-xl'
  | 'focus:shadow-xl'
  | 'container:shadow-2xl'
  | 'container-mid:shadow-2xl'
  | 'container-large:shadow-2xl'
  | 'hover:shadow-2xl'
  | 'active:shadow-2xl'
  | 'focus:shadow-2xl'
  | 'container:shadow-inner'
  | 'container-mid:shadow-inner'
  | 'container-large:shadow-inner'
  | 'hover:shadow-inner'
  | 'active:shadow-inner'
  | 'focus:shadow-inner'
  | 'container:shadow-outline'
  | 'container-mid:shadow-outline'
  | 'container-large:shadow-outline'
  | 'hover:shadow-outline'
  | 'active:shadow-outline'
  | 'focus:shadow-outline'
  | 'container:shadow-none'
  | 'container-mid:shadow-none'
  | 'container-large:shadow-none'
  | 'hover:shadow-none'
  | 'active:shadow-none'
  | 'focus:shadow-none'
  | 'container:shadow-outlineGold'
  | 'container-mid:shadow-outlineGold'
  | 'container-large:shadow-outlineGold'
  | 'hover:shadow-outlineGold'
  | 'active:shadow-outlineGold'
  | 'focus:shadow-outlineGold'
  | 'container:shadow-outlineRed'
  | 'container-mid:shadow-outlineRed'
  | 'container-large:shadow-outlineRed'
  | 'hover:shadow-outlineRed'
  | 'active:shadow-outlineRed'
  | 'focus:shadow-outlineRed'
  | 'container:shadow-omni'
  | 'container-mid:shadow-omni'
  | 'container-large:shadow-omni'
  | 'hover:shadow-omni'
  | 'active:shadow-omni'
  | 'focus:shadow-omni'
  | 'container:shadow-omni-small'
  | 'container-mid:shadow-omni-small'
  | 'container-large:shadow-omni-small'
  | 'hover:shadow-omni-small'
  | 'active:shadow-omni-small'
  | 'focus:shadow-omni-small'
  | 'container:box-border'
  | 'container-mid:box-border'
  | 'container-large:box-border'
  | 'container:box-content'
  | 'container-mid:box-content'
  | 'container-large:box-content'
  | 'container:container'
  | 'container-mid:container'
  | 'container-large:container'
  | 'container:cursor-auto'
  | 'container-mid:cursor-auto'
  | 'container-large:cursor-auto'
  | 'container:cursor-default'
  | 'container-mid:cursor-default'
  | 'container-large:cursor-default'
  | 'container:cursor-pointer'
  | 'container-mid:cursor-pointer'
  | 'container-large:cursor-pointer'
  | 'container:cursor-wait'
  | 'container-mid:cursor-wait'
  | 'container-large:cursor-wait'
  | 'container:cursor-text'
  | 'container-mid:cursor-text'
  | 'container-large:cursor-text'
  | 'container:cursor-move'
  | 'container-mid:cursor-move'
  | 'container-large:cursor-move'
  | 'container:cursor-not-allowed'
  | 'container-mid:cursor-not-allowed'
  | 'container-large:cursor-not-allowed'
  | 'container:block'
  | 'container-mid:block'
  | 'container-large:block'
  | 'container:inline-block'
  | 'container-mid:inline-block'
  | 'container-large:inline-block'
  | 'container:inline'
  | 'container-mid:inline'
  | 'container-large:inline'
  | 'container:flex'
  | 'container-mid:flex'
  | 'container-large:flex'
  | 'container:inline-flex'
  | 'container-mid:inline-flex'
  | 'container-large:inline-flex'
  | 'container:grid'
  | 'container-mid:grid'
  | 'container-large:grid'
  | 'container:inline-grid'
  | 'container-mid:inline-grid'
  | 'container-large:inline-grid'
  | 'container:table'
  | 'container-mid:table'
  | 'container-large:table'
  | 'container:table-row'
  | 'container-mid:table-row'
  | 'container-large:table-row'
  | 'container:table-cell'
  | 'container-mid:table-cell'
  | 'container-large:table-cell'
  | 'container:contents'
  | 'container-mid:contents'
  | 'container-large:contents'
  | 'container:hidden'
  | 'container-mid:hidden'
  | 'container-large:hidden'
  | 'container:divide-transparent'
  | 'container-mid:divide-transparent'
  | 'container-large:divide-transparent'
  | 'container:divide-current'
  | 'container-mid:divide-current'
  | 'container-large:divide-current'
  | 'container:divide-black'
  | 'container-mid:divide-black'
  | 'container-large:divide-black'
  | 'container:divide-white'
  | 'container-mid:divide-white'
  | 'container-large:divide-white'
  | 'container:divide-gray-100'
  | 'container-mid:divide-gray-100'
  | 'container-large:divide-gray-100'
  | 'container:divide-gray-200'
  | 'container-mid:divide-gray-200'
  | 'container-large:divide-gray-200'
  | 'container:divide-gray-300'
  | 'container-mid:divide-gray-300'
  | 'container-large:divide-gray-300'
  | 'container:divide-gray-400'
  | 'container-mid:divide-gray-400'
  | 'container-large:divide-gray-400'
  | 'container:divide-gray-500'
  | 'container-mid:divide-gray-500'
  | 'container-large:divide-gray-500'
  | 'container:divide-gray-600'
  | 'container-mid:divide-gray-600'
  | 'container-large:divide-gray-600'
  | 'container:divide-gray-700'
  | 'container-mid:divide-gray-700'
  | 'container-large:divide-gray-700'
  | 'container:divide-gray-800'
  | 'container-mid:divide-gray-800'
  | 'container-large:divide-gray-800'
  | 'container:divide-gray-900'
  | 'container-mid:divide-gray-900'
  | 'container-large:divide-gray-900'
  | 'container:divide-red-100'
  | 'container-mid:divide-red-100'
  | 'container-large:divide-red-100'
  | 'container:divide-red-200'
  | 'container-mid:divide-red-200'
  | 'container-large:divide-red-200'
  | 'container:divide-red-300'
  | 'container-mid:divide-red-300'
  | 'container-large:divide-red-300'
  | 'container:divide-red-400'
  | 'container-mid:divide-red-400'
  | 'container-large:divide-red-400'
  | 'container:divide-red-500'
  | 'container-mid:divide-red-500'
  | 'container-large:divide-red-500'
  | 'container:divide-red-600'
  | 'container-mid:divide-red-600'
  | 'container-large:divide-red-600'
  | 'container:divide-red-700'
  | 'container-mid:divide-red-700'
  | 'container-large:divide-red-700'
  | 'container:divide-red-800'
  | 'container-mid:divide-red-800'
  | 'container-large:divide-red-800'
  | 'container:divide-red-900'
  | 'container-mid:divide-red-900'
  | 'container-large:divide-red-900'
  | 'container:divide-orange-100'
  | 'container-mid:divide-orange-100'
  | 'container-large:divide-orange-100'
  | 'container:divide-orange-200'
  | 'container-mid:divide-orange-200'
  | 'container-large:divide-orange-200'
  | 'container:divide-orange-300'
  | 'container-mid:divide-orange-300'
  | 'container-large:divide-orange-300'
  | 'container:divide-orange-400'
  | 'container-mid:divide-orange-400'
  | 'container-large:divide-orange-400'
  | 'container:divide-orange-500'
  | 'container-mid:divide-orange-500'
  | 'container-large:divide-orange-500'
  | 'container:divide-orange-600'
  | 'container-mid:divide-orange-600'
  | 'container-large:divide-orange-600'
  | 'container:divide-orange-700'
  | 'container-mid:divide-orange-700'
  | 'container-large:divide-orange-700'
  | 'container:divide-orange-800'
  | 'container-mid:divide-orange-800'
  | 'container-large:divide-orange-800'
  | 'container:divide-orange-900'
  | 'container-mid:divide-orange-900'
  | 'container-large:divide-orange-900'
  | 'container:divide-yellow-100'
  | 'container-mid:divide-yellow-100'
  | 'container-large:divide-yellow-100'
  | 'container:divide-yellow-200'
  | 'container-mid:divide-yellow-200'
  | 'container-large:divide-yellow-200'
  | 'container:divide-yellow-300'
  | 'container-mid:divide-yellow-300'
  | 'container-large:divide-yellow-300'
  | 'container:divide-yellow-400'
  | 'container-mid:divide-yellow-400'
  | 'container-large:divide-yellow-400'
  | 'container:divide-yellow-500'
  | 'container-mid:divide-yellow-500'
  | 'container-large:divide-yellow-500'
  | 'container:divide-yellow-600'
  | 'container-mid:divide-yellow-600'
  | 'container-large:divide-yellow-600'
  | 'container:divide-yellow-700'
  | 'container-mid:divide-yellow-700'
  | 'container-large:divide-yellow-700'
  | 'container:divide-yellow-800'
  | 'container-mid:divide-yellow-800'
  | 'container-large:divide-yellow-800'
  | 'container:divide-yellow-900'
  | 'container-mid:divide-yellow-900'
  | 'container-large:divide-yellow-900'
  | 'container:divide-green-100'
  | 'container-mid:divide-green-100'
  | 'container-large:divide-green-100'
  | 'container:divide-green-200'
  | 'container-mid:divide-green-200'
  | 'container-large:divide-green-200'
  | 'container:divide-green-300'
  | 'container-mid:divide-green-300'
  | 'container-large:divide-green-300'
  | 'container:divide-green-400'
  | 'container-mid:divide-green-400'
  | 'container-large:divide-green-400'
  | 'container:divide-green-500'
  | 'container-mid:divide-green-500'
  | 'container-large:divide-green-500'
  | 'container:divide-green-600'
  | 'container-mid:divide-green-600'
  | 'container-large:divide-green-600'
  | 'container:divide-green-700'
  | 'container-mid:divide-green-700'
  | 'container-large:divide-green-700'
  | 'container:divide-green-800'
  | 'container-mid:divide-green-800'
  | 'container-large:divide-green-800'
  | 'container:divide-green-900'
  | 'container-mid:divide-green-900'
  | 'container-large:divide-green-900'
  | 'container:divide-teal-100'
  | 'container-mid:divide-teal-100'
  | 'container-large:divide-teal-100'
  | 'container:divide-teal-200'
  | 'container-mid:divide-teal-200'
  | 'container-large:divide-teal-200'
  | 'container:divide-teal-300'
  | 'container-mid:divide-teal-300'
  | 'container-large:divide-teal-300'
  | 'container:divide-teal-400'
  | 'container-mid:divide-teal-400'
  | 'container-large:divide-teal-400'
  | 'container:divide-teal-500'
  | 'container-mid:divide-teal-500'
  | 'container-large:divide-teal-500'
  | 'container:divide-teal-600'
  | 'container-mid:divide-teal-600'
  | 'container-large:divide-teal-600'
  | 'container:divide-teal-700'
  | 'container-mid:divide-teal-700'
  | 'container-large:divide-teal-700'
  | 'container:divide-teal-800'
  | 'container-mid:divide-teal-800'
  | 'container-large:divide-teal-800'
  | 'container:divide-teal-900'
  | 'container-mid:divide-teal-900'
  | 'container-large:divide-teal-900'
  | 'container:divide-blue-100'
  | 'container-mid:divide-blue-100'
  | 'container-large:divide-blue-100'
  | 'container:divide-blue-200'
  | 'container-mid:divide-blue-200'
  | 'container-large:divide-blue-200'
  | 'container:divide-blue-300'
  | 'container-mid:divide-blue-300'
  | 'container-large:divide-blue-300'
  | 'container:divide-blue-400'
  | 'container-mid:divide-blue-400'
  | 'container-large:divide-blue-400'
  | 'container:divide-blue-500'
  | 'container-mid:divide-blue-500'
  | 'container-large:divide-blue-500'
  | 'container:divide-blue-600'
  | 'container-mid:divide-blue-600'
  | 'container-large:divide-blue-600'
  | 'container:divide-blue-700'
  | 'container-mid:divide-blue-700'
  | 'container-large:divide-blue-700'
  | 'container:divide-blue-800'
  | 'container-mid:divide-blue-800'
  | 'container-large:divide-blue-800'
  | 'container:divide-blue-900'
  | 'container-mid:divide-blue-900'
  | 'container-large:divide-blue-900'
  | 'container:divide-indigo-100'
  | 'container-mid:divide-indigo-100'
  | 'container-large:divide-indigo-100'
  | 'container:divide-indigo-200'
  | 'container-mid:divide-indigo-200'
  | 'container-large:divide-indigo-200'
  | 'container:divide-indigo-300'
  | 'container-mid:divide-indigo-300'
  | 'container-large:divide-indigo-300'
  | 'container:divide-indigo-400'
  | 'container-mid:divide-indigo-400'
  | 'container-large:divide-indigo-400'
  | 'container:divide-indigo-500'
  | 'container-mid:divide-indigo-500'
  | 'container-large:divide-indigo-500'
  | 'container:divide-indigo-600'
  | 'container-mid:divide-indigo-600'
  | 'container-large:divide-indigo-600'
  | 'container:divide-indigo-700'
  | 'container-mid:divide-indigo-700'
  | 'container-large:divide-indigo-700'
  | 'container:divide-indigo-800'
  | 'container-mid:divide-indigo-800'
  | 'container-large:divide-indigo-800'
  | 'container:divide-indigo-900'
  | 'container-mid:divide-indigo-900'
  | 'container-large:divide-indigo-900'
  | 'container:divide-purple-100'
  | 'container-mid:divide-purple-100'
  | 'container-large:divide-purple-100'
  | 'container:divide-purple-200'
  | 'container-mid:divide-purple-200'
  | 'container-large:divide-purple-200'
  | 'container:divide-purple-300'
  | 'container-mid:divide-purple-300'
  | 'container-large:divide-purple-300'
  | 'container:divide-purple-400'
  | 'container-mid:divide-purple-400'
  | 'container-large:divide-purple-400'
  | 'container:divide-purple-500'
  | 'container-mid:divide-purple-500'
  | 'container-large:divide-purple-500'
  | 'container:divide-purple-600'
  | 'container-mid:divide-purple-600'
  | 'container-large:divide-purple-600'
  | 'container:divide-purple-700'
  | 'container-mid:divide-purple-700'
  | 'container-large:divide-purple-700'
  | 'container:divide-purple-800'
  | 'container-mid:divide-purple-800'
  | 'container-large:divide-purple-800'
  | 'container:divide-purple-900'
  | 'container-mid:divide-purple-900'
  | 'container-large:divide-purple-900'
  | 'container:divide-pink-100'
  | 'container-mid:divide-pink-100'
  | 'container-large:divide-pink-100'
  | 'container:divide-pink-200'
  | 'container-mid:divide-pink-200'
  | 'container-large:divide-pink-200'
  | 'container:divide-pink-300'
  | 'container-mid:divide-pink-300'
  | 'container-large:divide-pink-300'
  | 'container:divide-pink-400'
  | 'container-mid:divide-pink-400'
  | 'container-large:divide-pink-400'
  | 'container:divide-pink-500'
  | 'container-mid:divide-pink-500'
  | 'container-large:divide-pink-500'
  | 'container:divide-pink-600'
  | 'container-mid:divide-pink-600'
  | 'container-large:divide-pink-600'
  | 'container:divide-pink-700'
  | 'container-mid:divide-pink-700'
  | 'container-large:divide-pink-700'
  | 'container:divide-pink-800'
  | 'container-mid:divide-pink-800'
  | 'container-large:divide-pink-800'
  | 'container:divide-pink-900'
  | 'container-mid:divide-pink-900'
  | 'container-large:divide-pink-900'
  | 'container:divide-opacity-0'
  | 'container-mid:divide-opacity-0'
  | 'container-large:divide-opacity-0'
  | 'container:divide-opacity-25'
  | 'container-mid:divide-opacity-25'
  | 'container-large:divide-opacity-25'
  | 'container:divide-opacity-50'
  | 'container-mid:divide-opacity-50'
  | 'container-large:divide-opacity-50'
  | 'container:divide-opacity-75'
  | 'container-mid:divide-opacity-75'
  | 'container-large:divide-opacity-75'
  | 'container:divide-opacity-100'
  | 'container-mid:divide-opacity-100'
  | 'container-large:divide-opacity-100'
  | 'container:divide-solid'
  | 'container-mid:divide-solid'
  | 'container-large:divide-solid'
  | 'container:divide-dashed'
  | 'container-mid:divide-dashed'
  | 'container-large:divide-dashed'
  | 'container:divide-dotted'
  | 'container-mid:divide-dotted'
  | 'container-large:divide-dotted'
  | 'container:divide-double'
  | 'container-mid:divide-double'
  | 'container-large:divide-double'
  | 'container:divide-none'
  | 'container-mid:divide-none'
  | 'container-large:divide-none'
  | 'container:divide-x-0'
  | 'container-mid:divide-x-0'
  | 'container-large:divide-x-0'
  | 'container:divide-y-0'
  | 'container-mid:divide-y-0'
  | 'container-large:divide-y-0'
  | 'container:divide-x-2'
  | 'container-mid:divide-x-2'
  | 'container-large:divide-x-2'
  | 'container:divide-y-2'
  | 'container-mid:divide-y-2'
  | 'container-large:divide-y-2'
  | 'container:divide-x-4'
  | 'container-mid:divide-x-4'
  | 'container-large:divide-x-4'
  | 'container:divide-y-4'
  | 'container-mid:divide-y-4'
  | 'container-large:divide-y-4'
  | 'container:divide-x-8'
  | 'container-mid:divide-x-8'
  | 'container-large:divide-x-8'
  | 'container:divide-y-8'
  | 'container-mid:divide-y-8'
  | 'container-large:divide-y-8'
  | 'container:divide-x'
  | 'container-mid:divide-x'
  | 'container-large:divide-x'
  | 'container:divide-y'
  | 'container-mid:divide-y'
  | 'container-large:divide-y'
  | 'container:divide-x-reverse'
  | 'container-mid:divide-x-reverse'
  | 'container-large:divide-x-reverse'
  | 'container:divide-y-reverse'
  | 'container-mid:divide-y-reverse'
  | 'container-large:divide-y-reverse'
  | 'container:fill-current'
  | 'container-mid:fill-current'
  | 'container-large:fill-current'
  | 'container:flex-initial'
  | 'container-mid:flex-initial'
  | 'container-large:flex-initial'
  | 'container:flex-1'
  | 'container-mid:flex-1'
  | 'container-large:flex-1'
  | 'container:flex-auto'
  | 'container-mid:flex-auto'
  | 'container-large:flex-auto'
  | 'container:flex-none'
  | 'container-mid:flex-none'
  | 'container-large:flex-none'
  | 'container:flex-row'
  | 'container-mid:flex-row'
  | 'container-large:flex-row'
  | 'container:flex-row-reverse'
  | 'container-mid:flex-row-reverse'
  | 'container-large:flex-row-reverse'
  | 'container:flex-col'
  | 'container-mid:flex-col'
  | 'container-large:flex-col'
  | 'container:flex-col-reverse'
  | 'container-mid:flex-col-reverse'
  | 'container-large:flex-col-reverse'
  | 'container:flex-grow-0'
  | 'container-mid:flex-grow-0'
  | 'container-large:flex-grow-0'
  | 'container:flex-grow'
  | 'container-mid:flex-grow'
  | 'container-large:flex-grow'
  | 'container:flex-shrink-0'
  | 'container-mid:flex-shrink-0'
  | 'container-large:flex-shrink-0'
  | 'container:flex-shrink'
  | 'container-mid:flex-shrink'
  | 'container-large:flex-shrink'
  | 'container:flex-no-wrap'
  | 'container-mid:flex-no-wrap'
  | 'container-large:flex-no-wrap'
  | 'container:flex-wrap'
  | 'container-mid:flex-wrap'
  | 'container-large:flex-wrap'
  | 'container:flex-wrap-reverse'
  | 'container-mid:flex-wrap-reverse'
  | 'container-large:flex-wrap-reverse'
  | 'container:float-right'
  | 'container-mid:float-right'
  | 'container-large:float-right'
  | 'container:float-left'
  | 'container-mid:float-left'
  | 'container-large:float-left'
  | 'container:float-none'
  | 'container-mid:float-none'
  | 'container-large:float-none'
  | 'container:clearfix'
  | 'container-mid:clearfix'
  | 'container-large:clearfix'
  | 'container:clear-left'
  | 'container-mid:clear-left'
  | 'container-large:clear-left'
  | 'container:clear-right'
  | 'container-mid:clear-right'
  | 'container-large:clear-right'
  | 'container:clear-both'
  | 'container-mid:clear-both'
  | 'container-large:clear-both'
  | 'container:clear-none'
  | 'container-mid:clear-none'
  | 'container-large:clear-none'
  | 'container:font-montserrat'
  | 'container-mid:font-montserrat'
  | 'container-large:font-montserrat'
  | 'container:text-xs'
  | 'container-mid:text-xs'
  | 'container-large:text-xs'
  | 'container:text-sm'
  | 'container-mid:text-sm'
  | 'container-large:text-sm'
  | 'container:text-base'
  | 'container-mid:text-base'
  | 'container-large:text-base'
  | 'container:text-l'
  | 'container-mid:text-l'
  | 'container-large:text-l'
  | 'container:text-xl'
  | 'container-mid:text-xl'
  | 'container-large:text-xl'
  | 'container:antialiased'
  | 'container-mid:antialiased'
  | 'container-large:antialiased'
  | 'container:subpixel-antialiased'
  | 'container-mid:subpixel-antialiased'
  | 'container-large:subpixel-antialiased'
  | 'container:normal-nums'
  | 'container-mid:normal-nums'
  | 'container-large:normal-nums'
  | 'container:ordinal'
  | 'container-mid:ordinal'
  | 'container-large:ordinal'
  | 'container:slashed-zero'
  | 'container-mid:slashed-zero'
  | 'container-large:slashed-zero'
  | 'container:lining-nums'
  | 'container-mid:lining-nums'
  | 'container-large:lining-nums'
  | 'container:oldstyle-nums'
  | 'container-mid:oldstyle-nums'
  | 'container-large:oldstyle-nums'
  | 'container:proportional-nums'
  | 'container-mid:proportional-nums'
  | 'container-large:proportional-nums'
  | 'container:tabular-nums'
  | 'container-mid:tabular-nums'
  | 'container-large:tabular-nums'
  | 'container:diagonal-fractions'
  | 'container-mid:diagonal-fractions'
  | 'container-large:diagonal-fractions'
  | 'container:stacked-fractions'
  | 'container-mid:stacked-fractions'
  | 'container-large:stacked-fractions'
  | 'container:italic'
  | 'container-mid:italic'
  | 'container-large:italic'
  | 'container:non-italic'
  | 'container-mid:non-italic'
  | 'container-large:non-italic'
  | 'container:font-normal'
  | 'container-mid:font-normal'
  | 'container-large:font-normal'
  | 'hover:font-normal'
  | 'focus:font-normal'
  | 'container:font-medium'
  | 'container-mid:font-medium'
  | 'container-large:font-medium'
  | 'hover:font-medium'
  | 'focus:font-medium'
  | 'container:font-bold'
  | 'container-mid:font-bold'
  | 'container-large:font-bold'
  | 'hover:font-bold'
  | 'focus:font-bold'
  | 'container:font-extrabold'
  | 'container-mid:font-extrabold'
  | 'container-large:font-extrabold'
  | 'hover:font-extrabold'
  | 'focus:font-extrabold'
  | 'container:h-0'
  | 'container-mid:h-0'
  | 'container-large:h-0'
  | 'container:h-1'
  | 'container-mid:h-1'
  | 'container-large:h-1'
  | 'container:h-2'
  | 'container-mid:h-2'
  | 'container-large:h-2'
  | 'container:h-4'
  | 'container-mid:h-4'
  | 'container-large:h-4'
  | 'container:h-8'
  | 'container-mid:h-8'
  | 'container-large:h-8'
  | 'container:h-10'
  | 'container-mid:h-10'
  | 'container-large:h-10'
  | 'container:h-12'
  | 'container-mid:h-12'
  | 'container-large:h-12'
  | 'container:h-16'
  | 'container-mid:h-16'
  | 'container-large:h-16'
  | 'container:h-20'
  | 'container-mid:h-20'
  | 'container-large:h-20'
  | 'container:h-24'
  | 'container-mid:h-24'
  | 'container-large:h-24'
  | 'container:h-32'
  | 'container-mid:h-32'
  | 'container-large:h-32'
  | 'container:h-48'
  | 'container-mid:h-48'
  | 'container-large:h-48'
  | 'container:h-80'
  | 'container-mid:h-80'
  | 'container-large:h-80'
  | 'container:h-100'
  | 'container-mid:h-100'
  | 'container-large:h-100'
  | 'container:h-160'
  | 'container-mid:h-160'
  | 'container-large:h-160'
  | 'container:h-200'
  | 'container-mid:h-200'
  | 'container-large:h-200'
  | 'container:h-280'
  | 'container-mid:h-280'
  | 'container-large:h-280'
  | 'container:h-auto'
  | 'container-mid:h-auto'
  | 'container-large:h-auto'
  | 'container:h-full'
  | 'container-mid:h-full'
  | 'container-large:h-full'
  | 'container:h-screen'
  | 'container-mid:h-screen'
  | 'container-large:h-screen'
  | 'container:inset-0'
  | 'container-mid:inset-0'
  | 'container-large:inset-0'
  | 'container:inset-x-0'
  | 'container-mid:inset-x-0'
  | 'container-large:inset-x-0'
  | 'container:inset-y-0'
  | 'container-mid:inset-y-0'
  | 'container-large:inset-y-0'
  | 'container:top-0'
  | 'container-mid:top-0'
  | 'container-large:top-0'
  | 'container:right-0'
  | 'container-mid:right-0'
  | 'container-large:right-0'
  | 'container:bottom-0'
  | 'container-mid:bottom-0'
  | 'container-large:bottom-0'
  | 'container:left-0'
  | 'container-mid:left-0'
  | 'container-large:left-0'
  | 'container:inset-16'
  | 'container-mid:inset-16'
  | 'container-large:inset-16'
  | 'container:inset-x-16'
  | 'container-mid:inset-x-16'
  | 'container-large:inset-x-16'
  | 'container:inset-y-16'
  | 'container-mid:inset-y-16'
  | 'container-large:inset-y-16'
  | 'container:top-16'
  | 'container-mid:top-16'
  | 'container-large:top-16'
  | 'container:right-16'
  | 'container-mid:right-16'
  | 'container-large:right-16'
  | 'container:bottom-16'
  | 'container-mid:bottom-16'
  | 'container-large:bottom-16'
  | 'container:left-16'
  | 'container-mid:left-16'
  | 'container-large:left-16'
  | 'container:inset-auto'
  | 'container-mid:inset-auto'
  | 'container-large:inset-auto'
  | 'container:inset-x-auto'
  | 'container-mid:inset-x-auto'
  | 'container-large:inset-x-auto'
  | 'container:inset-y-auto'
  | 'container-mid:inset-y-auto'
  | 'container-large:inset-y-auto'
  | 'container:top-auto'
  | 'container-mid:top-auto'
  | 'container-large:top-auto'
  | 'container:right-auto'
  | 'container-mid:right-auto'
  | 'container-large:right-auto'
  | 'container:bottom-auto'
  | 'container-mid:bottom-auto'
  | 'container-large:bottom-auto'
  | 'container:left-auto'
  | 'container-mid:left-auto'
  | 'container-large:left-auto'
  | 'container:justify-start'
  | 'container-mid:justify-start'
  | 'container-large:justify-start'
  | 'container:justify-center'
  | 'container-mid:justify-center'
  | 'container-large:justify-center'
  | 'container:justify-end'
  | 'container-mid:justify-end'
  | 'container-large:justify-end'
  | 'container:justify-between'
  | 'container-mid:justify-between'
  | 'container-large:justify-between'
  | 'container:justify-around'
  | 'container-mid:justify-around'
  | 'container-large:justify-around'
  | 'container:justify-items-auto'
  | 'container-mid:justify-items-auto'
  | 'container-large:justify-items-auto'
  | 'container:justify-items-start'
  | 'container-mid:justify-items-start'
  | 'container-large:justify-items-start'
  | 'container:justify-items-center'
  | 'container-mid:justify-items-center'
  | 'container-large:justify-items-center'
  | 'container:justify-items-end'
  | 'container-mid:justify-items-end'
  | 'container-large:justify-items-end'
  | 'container:justify-items-stretch'
  | 'container-mid:justify-items-stretch'
  | 'container-large:justify-items-stretch'
  | 'container:justify-self-auto'
  | 'container-mid:justify-self-auto'
  | 'container-large:justify-self-auto'
  | 'container:justify-self-start'
  | 'container-mid:justify-self-start'
  | 'container-large:justify-self-start'
  | 'container:justify-self-center'
  | 'container-mid:justify-self-center'
  | 'container-large:justify-self-center'
  | 'container:justify-self-end'
  | 'container-mid:justify-self-end'
  | 'container-large:justify-self-end'
  | 'container:justify-self-stretch'
  | 'container-mid:justify-self-stretch'
  | 'container-large:justify-self-stretch'
  | 'container:tracking-tighter'
  | 'container-mid:tracking-tighter'
  | 'container-large:tracking-tighter'
  | 'container:tracking-tight'
  | 'container-mid:tracking-tight'
  | 'container-large:tracking-tight'
  | 'container:tracking-normal'
  | 'container-mid:tracking-normal'
  | 'container-large:tracking-normal'
  | 'container:tracking-wide'
  | 'container-mid:tracking-wide'
  | 'container-large:tracking-wide'
  | 'container:tracking-wider'
  | 'container-mid:tracking-wider'
  | 'container-large:tracking-wider'
  | 'container:tracking-widest'
  | 'container-mid:tracking-widest'
  | 'container-large:tracking-widest'
  | 'container:leading-3'
  | 'container-mid:leading-3'
  | 'container-large:leading-3'
  | 'container:leading-4'
  | 'container-mid:leading-4'
  | 'container-large:leading-4'
  | 'container:leading-5'
  | 'container-mid:leading-5'
  | 'container-large:leading-5'
  | 'container:leading-6'
  | 'container-mid:leading-6'
  | 'container-large:leading-6'
  | 'container:leading-7'
  | 'container-mid:leading-7'
  | 'container-large:leading-7'
  | 'container:leading-8'
  | 'container-mid:leading-8'
  | 'container-large:leading-8'
  | 'container:leading-9'
  | 'container-mid:leading-9'
  | 'container-large:leading-9'
  | 'container:leading-10'
  | 'container-mid:leading-10'
  | 'container-large:leading-10'
  | 'container:leading-none'
  | 'container-mid:leading-none'
  | 'container-large:leading-none'
  | 'container:leading-tight'
  | 'container-mid:leading-tight'
  | 'container-large:leading-tight'
  | 'container:leading-snug'
  | 'container-mid:leading-snug'
  | 'container-large:leading-snug'
  | 'container:leading-normal'
  | 'container-mid:leading-normal'
  | 'container-large:leading-normal'
  | 'container:leading-relaxed'
  | 'container-mid:leading-relaxed'
  | 'container-large:leading-relaxed'
  | 'container:leading-loose'
  | 'container-mid:leading-loose'
  | 'container-large:leading-loose'
  | 'container:list-inside'
  | 'container-mid:list-inside'
  | 'container-large:list-inside'
  | 'container:list-outside'
  | 'container-mid:list-outside'
  | 'container-large:list-outside'
  | 'container:list-none'
  | 'container-mid:list-none'
  | 'container-large:list-none'
  | 'container:list-disc'
  | 'container-mid:list-disc'
  | 'container-large:list-disc'
  | 'container:list-decimal'
  | 'container-mid:list-decimal'
  | 'container-large:list-decimal'
  | 'container:m-0'
  | 'container-mid:m-0'
  | 'container-large:m-0'
  | 'container:m-1'
  | 'container-mid:m-1'
  | 'container-large:m-1'
  | 'container:m-2'
  | 'container-mid:m-2'
  | 'container-large:m-2'
  | 'container:m-4'
  | 'container-mid:m-4'
  | 'container-large:m-4'
  | 'container:m-8'
  | 'container-mid:m-8'
  | 'container-large:m-8'
  | 'container:m-10'
  | 'container-mid:m-10'
  | 'container-large:m-10'
  | 'container:m-12'
  | 'container-mid:m-12'
  | 'container-large:m-12'
  | 'container:m-16'
  | 'container-mid:m-16'
  | 'container-large:m-16'
  | 'container:m-20'
  | 'container-mid:m-20'
  | 'container-large:m-20'
  | 'container:m-24'
  | 'container-mid:m-24'
  | 'container-large:m-24'
  | 'container:m-32'
  | 'container-mid:m-32'
  | 'container-large:m-32'
  | 'container:m-48'
  | 'container-mid:m-48'
  | 'container-large:m-48'
  | 'container:m-80'
  | 'container-mid:m-80'
  | 'container-large:m-80'
  | 'container:m-100'
  | 'container-mid:m-100'
  | 'container-large:m-100'
  | 'container:m-160'
  | 'container-mid:m-160'
  | 'container-large:m-160'
  | 'container:m-200'
  | 'container-mid:m-200'
  | 'container-large:m-200'
  | 'container:m-280'
  | 'container-mid:m-280'
  | 'container-large:m-280'
  | 'container:m-auto'
  | 'container-mid:m-auto'
  | 'container-large:m-auto'
  | 'container:-m-0'
  | 'container-mid:-m-0'
  | 'container-large:-m-0'
  | 'container:-m-1'
  | 'container-mid:-m-1'
  | 'container-large:-m-1'
  | 'container:-m-2'
  | 'container-mid:-m-2'
  | 'container-large:-m-2'
  | 'container:-m-4'
  | 'container-mid:-m-4'
  | 'container-large:-m-4'
  | 'container:-m-8'
  | 'container-mid:-m-8'
  | 'container-large:-m-8'
  | 'container:-m-10'
  | 'container-mid:-m-10'
  | 'container-large:-m-10'
  | 'container:-m-12'
  | 'container-mid:-m-12'
  | 'container-large:-m-12'
  | 'container:-m-16'
  | 'container-mid:-m-16'
  | 'container-large:-m-16'
  | 'container:-m-20'
  | 'container-mid:-m-20'
  | 'container-large:-m-20'
  | 'container:-m-24'
  | 'container-mid:-m-24'
  | 'container-large:-m-24'
  | 'container:-m-32'
  | 'container-mid:-m-32'
  | 'container-large:-m-32'
  | 'container:-m-48'
  | 'container-mid:-m-48'
  | 'container-large:-m-48'
  | 'container:-m-80'
  | 'container-mid:-m-80'
  | 'container-large:-m-80'
  | 'container:-m-100'
  | 'container-mid:-m-100'
  | 'container-large:-m-100'
  | 'container:-m-160'
  | 'container-mid:-m-160'
  | 'container-large:-m-160'
  | 'container:-m-200'
  | 'container-mid:-m-200'
  | 'container-large:-m-200'
  | 'container:-m-280'
  | 'container-mid:-m-280'
  | 'container-large:-m-280'
  | 'container:my-0'
  | 'container-mid:my-0'
  | 'container-large:my-0'
  | 'container:my-1'
  | 'container-mid:my-1'
  | 'container-large:my-1'
  | 'container:my-2'
  | 'container-mid:my-2'
  | 'container-large:my-2'
  | 'container:my-4'
  | 'container-mid:my-4'
  | 'container-large:my-4'
  | 'container:my-8'
  | 'container-mid:my-8'
  | 'container-large:my-8'
  | 'container:my-10'
  | 'container-mid:my-10'
  | 'container-large:my-10'
  | 'container:my-12'
  | 'container-mid:my-12'
  | 'container-large:my-12'
  | 'container:my-16'
  | 'container-mid:my-16'
  | 'container-large:my-16'
  | 'container:my-20'
  | 'container-mid:my-20'
  | 'container-large:my-20'
  | 'container:my-24'
  | 'container-mid:my-24'
  | 'container-large:my-24'
  | 'container:my-32'
  | 'container-mid:my-32'
  | 'container-large:my-32'
  | 'container:my-48'
  | 'container-mid:my-48'
  | 'container-large:my-48'
  | 'container:my-80'
  | 'container-mid:my-80'
  | 'container-large:my-80'
  | 'container:my-100'
  | 'container-mid:my-100'
  | 'container-large:my-100'
  | 'container:my-160'
  | 'container-mid:my-160'
  | 'container-large:my-160'
  | 'container:my-200'
  | 'container-mid:my-200'
  | 'container-large:my-200'
  | 'container:my-280'
  | 'container-mid:my-280'
  | 'container-large:my-280'
  | 'container:my-auto'
  | 'container-mid:my-auto'
  | 'container-large:my-auto'
  | 'container:-my-0'
  | 'container-mid:-my-0'
  | 'container-large:-my-0'
  | 'container:-my-1'
  | 'container-mid:-my-1'
  | 'container-large:-my-1'
  | 'container:-my-2'
  | 'container-mid:-my-2'
  | 'container-large:-my-2'
  | 'container:-my-4'
  | 'container-mid:-my-4'
  | 'container-large:-my-4'
  | 'container:-my-8'
  | 'container-mid:-my-8'
  | 'container-large:-my-8'
  | 'container:-my-10'
  | 'container-mid:-my-10'
  | 'container-large:-my-10'
  | 'container:-my-12'
  | 'container-mid:-my-12'
  | 'container-large:-my-12'
  | 'container:-my-16'
  | 'container-mid:-my-16'
  | 'container-large:-my-16'
  | 'container:-my-20'
  | 'container-mid:-my-20'
  | 'container-large:-my-20'
  | 'container:-my-24'
  | 'container-mid:-my-24'
  | 'container-large:-my-24'
  | 'container:-my-32'
  | 'container-mid:-my-32'
  | 'container-large:-my-32'
  | 'container:-my-48'
  | 'container-mid:-my-48'
  | 'container-large:-my-48'
  | 'container:-my-80'
  | 'container-mid:-my-80'
  | 'container-large:-my-80'
  | 'container:-my-100'
  | 'container-mid:-my-100'
  | 'container-large:-my-100'
  | 'container:-my-160'
  | 'container-mid:-my-160'
  | 'container-large:-my-160'
  | 'container:-my-200'
  | 'container-mid:-my-200'
  | 'container-large:-my-200'
  | 'container:-my-280'
  | 'container-mid:-my-280'
  | 'container-large:-my-280'
  | 'container:mx-0'
  | 'container-mid:mx-0'
  | 'container-large:mx-0'
  | 'container:mx-1'
  | 'container-mid:mx-1'
  | 'container-large:mx-1'
  | 'container:mx-2'
  | 'container-mid:mx-2'
  | 'container-large:mx-2'
  | 'container:mx-4'
  | 'container-mid:mx-4'
  | 'container-large:mx-4'
  | 'container:mx-8'
  | 'container-mid:mx-8'
  | 'container-large:mx-8'
  | 'container:mx-10'
  | 'container-mid:mx-10'
  | 'container-large:mx-10'
  | 'container:mx-12'
  | 'container-mid:mx-12'
  | 'container-large:mx-12'
  | 'container:mx-16'
  | 'container-mid:mx-16'
  | 'container-large:mx-16'
  | 'container:mx-20'
  | 'container-mid:mx-20'
  | 'container-large:mx-20'
  | 'container:mx-24'
  | 'container-mid:mx-24'
  | 'container-large:mx-24'
  | 'container:mx-32'
  | 'container-mid:mx-32'
  | 'container-large:mx-32'
  | 'container:mx-48'
  | 'container-mid:mx-48'
  | 'container-large:mx-48'
  | 'container:mx-80'
  | 'container-mid:mx-80'
  | 'container-large:mx-80'
  | 'container:mx-100'
  | 'container-mid:mx-100'
  | 'container-large:mx-100'
  | 'container:mx-160'
  | 'container-mid:mx-160'
  | 'container-large:mx-160'
  | 'container:mx-200'
  | 'container-mid:mx-200'
  | 'container-large:mx-200'
  | 'container:mx-280'
  | 'container-mid:mx-280'
  | 'container-large:mx-280'
  | 'container:mx-auto'
  | 'container-mid:mx-auto'
  | 'container-large:mx-auto'
  | 'container:-mx-0'
  | 'container-mid:-mx-0'
  | 'container-large:-mx-0'
  | 'container:-mx-1'
  | 'container-mid:-mx-1'
  | 'container-large:-mx-1'
  | 'container:-mx-2'
  | 'container-mid:-mx-2'
  | 'container-large:-mx-2'
  | 'container:-mx-4'
  | 'container-mid:-mx-4'
  | 'container-large:-mx-4'
  | 'container:-mx-8'
  | 'container-mid:-mx-8'
  | 'container-large:-mx-8'
  | 'container:-mx-10'
  | 'container-mid:-mx-10'
  | 'container-large:-mx-10'
  | 'container:-mx-12'
  | 'container-mid:-mx-12'
  | 'container-large:-mx-12'
  | 'container:-mx-16'
  | 'container-mid:-mx-16'
  | 'container-large:-mx-16'
  | 'container:-mx-20'
  | 'container-mid:-mx-20'
  | 'container-large:-mx-20'
  | 'container:-mx-24'
  | 'container-mid:-mx-24'
  | 'container-large:-mx-24'
  | 'container:-mx-32'
  | 'container-mid:-mx-32'
  | 'container-large:-mx-32'
  | 'container:-mx-48'
  | 'container-mid:-mx-48'
  | 'container-large:-mx-48'
  | 'container:-mx-80'
  | 'container-mid:-mx-80'
  | 'container-large:-mx-80'
  | 'container:-mx-100'
  | 'container-mid:-mx-100'
  | 'container-large:-mx-100'
  | 'container:-mx-160'
  | 'container-mid:-mx-160'
  | 'container-large:-mx-160'
  | 'container:-mx-200'
  | 'container-mid:-mx-200'
  | 'container-large:-mx-200'
  | 'container:-mx-280'
  | 'container-mid:-mx-280'
  | 'container-large:-mx-280'
  | 'container:mt-0'
  | 'container-mid:mt-0'
  | 'container-large:mt-0'
  | 'container:mt-1'
  | 'container-mid:mt-1'
  | 'container-large:mt-1'
  | 'container:mt-2'
  | 'container-mid:mt-2'
  | 'container-large:mt-2'
  | 'container:mt-4'
  | 'container-mid:mt-4'
  | 'container-large:mt-4'
  | 'container:mt-8'
  | 'container-mid:mt-8'
  | 'container-large:mt-8'
  | 'container:mt-10'
  | 'container-mid:mt-10'
  | 'container-large:mt-10'
  | 'container:mt-12'
  | 'container-mid:mt-12'
  | 'container-large:mt-12'
  | 'container:mt-16'
  | 'container-mid:mt-16'
  | 'container-large:mt-16'
  | 'container:mt-20'
  | 'container-mid:mt-20'
  | 'container-large:mt-20'
  | 'container:mt-24'
  | 'container-mid:mt-24'
  | 'container-large:mt-24'
  | 'container:mt-32'
  | 'container-mid:mt-32'
  | 'container-large:mt-32'
  | 'container:mt-48'
  | 'container-mid:mt-48'
  | 'container-large:mt-48'
  | 'container:mt-80'
  | 'container-mid:mt-80'
  | 'container-large:mt-80'
  | 'container:mt-100'
  | 'container-mid:mt-100'
  | 'container-large:mt-100'
  | 'container:mt-160'
  | 'container-mid:mt-160'
  | 'container-large:mt-160'
  | 'container:mt-200'
  | 'container-mid:mt-200'
  | 'container-large:mt-200'
  | 'container:mt-280'
  | 'container-mid:mt-280'
  | 'container-large:mt-280'
  | 'container:mt-auto'
  | 'container-mid:mt-auto'
  | 'container-large:mt-auto'
  | 'container:-mt-0'
  | 'container-mid:-mt-0'
  | 'container-large:-mt-0'
  | 'container:-mt-1'
  | 'container-mid:-mt-1'
  | 'container-large:-mt-1'
  | 'container:-mt-2'
  | 'container-mid:-mt-2'
  | 'container-large:-mt-2'
  | 'container:-mt-4'
  | 'container-mid:-mt-4'
  | 'container-large:-mt-4'
  | 'container:-mt-8'
  | 'container-mid:-mt-8'
  | 'container-large:-mt-8'
  | 'container:-mt-10'
  | 'container-mid:-mt-10'
  | 'container-large:-mt-10'
  | 'container:-mt-12'
  | 'container-mid:-mt-12'
  | 'container-large:-mt-12'
  | 'container:-mt-16'
  | 'container-mid:-mt-16'
  | 'container-large:-mt-16'
  | 'container:-mt-20'
  | 'container-mid:-mt-20'
  | 'container-large:-mt-20'
  | 'container:-mt-24'
  | 'container-mid:-mt-24'
  | 'container-large:-mt-24'
  | 'container:-mt-32'
  | 'container-mid:-mt-32'
  | 'container-large:-mt-32'
  | 'container:-mt-48'
  | 'container-mid:-mt-48'
  | 'container-large:-mt-48'
  | 'container:-mt-80'
  | 'container-mid:-mt-80'
  | 'container-large:-mt-80'
  | 'container:-mt-100'
  | 'container-mid:-mt-100'
  | 'container-large:-mt-100'
  | 'container:-mt-160'
  | 'container-mid:-mt-160'
  | 'container-large:-mt-160'
  | 'container:-mt-200'
  | 'container-mid:-mt-200'
  | 'container-large:-mt-200'
  | 'container:-mt-280'
  | 'container-mid:-mt-280'
  | 'container-large:-mt-280'
  | 'container:mr-0'
  | 'container-mid:mr-0'
  | 'container-large:mr-0'
  | 'container:mr-1'
  | 'container-mid:mr-1'
  | 'container-large:mr-1'
  | 'container:mr-2'
  | 'container-mid:mr-2'
  | 'container-large:mr-2'
  | 'container:mr-4'
  | 'container-mid:mr-4'
  | 'container-large:mr-4'
  | 'container:mr-8'
  | 'container-mid:mr-8'
  | 'container-large:mr-8'
  | 'container:mr-10'
  | 'container-mid:mr-10'
  | 'container-large:mr-10'
  | 'container:mr-12'
  | 'container-mid:mr-12'
  | 'container-large:mr-12'
  | 'container:mr-16'
  | 'container-mid:mr-16'
  | 'container-large:mr-16'
  | 'container:mr-20'
  | 'container-mid:mr-20'
  | 'container-large:mr-20'
  | 'container:mr-24'
  | 'container-mid:mr-24'
  | 'container-large:mr-24'
  | 'container:mr-32'
  | 'container-mid:mr-32'
  | 'container-large:mr-32'
  | 'container:mr-48'
  | 'container-mid:mr-48'
  | 'container-large:mr-48'
  | 'container:mr-80'
  | 'container-mid:mr-80'
  | 'container-large:mr-80'
  | 'container:mr-100'
  | 'container-mid:mr-100'
  | 'container-large:mr-100'
  | 'container:mr-160'
  | 'container-mid:mr-160'
  | 'container-large:mr-160'
  | 'container:mr-200'
  | 'container-mid:mr-200'
  | 'container-large:mr-200'
  | 'container:mr-280'
  | 'container-mid:mr-280'
  | 'container-large:mr-280'
  | 'container:mr-auto'
  | 'container-mid:mr-auto'
  | 'container-large:mr-auto'
  | 'container:-mr-0'
  | 'container-mid:-mr-0'
  | 'container-large:-mr-0'
  | 'container:-mr-1'
  | 'container-mid:-mr-1'
  | 'container-large:-mr-1'
  | 'container:-mr-2'
  | 'container-mid:-mr-2'
  | 'container-large:-mr-2'
  | 'container:-mr-4'
  | 'container-mid:-mr-4'
  | 'container-large:-mr-4'
  | 'container:-mr-8'
  | 'container-mid:-mr-8'
  | 'container-large:-mr-8'
  | 'container:-mr-10'
  | 'container-mid:-mr-10'
  | 'container-large:-mr-10'
  | 'container:-mr-12'
  | 'container-mid:-mr-12'
  | 'container-large:-mr-12'
  | 'container:-mr-16'
  | 'container-mid:-mr-16'
  | 'container-large:-mr-16'
  | 'container:-mr-20'
  | 'container-mid:-mr-20'
  | 'container-large:-mr-20'
  | 'container:-mr-24'
  | 'container-mid:-mr-24'
  | 'container-large:-mr-24'
  | 'container:-mr-32'
  | 'container-mid:-mr-32'
  | 'container-large:-mr-32'
  | 'container:-mr-48'
  | 'container-mid:-mr-48'
  | 'container-large:-mr-48'
  | 'container:-mr-80'
  | 'container-mid:-mr-80'
  | 'container-large:-mr-80'
  | 'container:-mr-100'
  | 'container-mid:-mr-100'
  | 'container-large:-mr-100'
  | 'container:-mr-160'
  | 'container-mid:-mr-160'
  | 'container-large:-mr-160'
  | 'container:-mr-200'
  | 'container-mid:-mr-200'
  | 'container-large:-mr-200'
  | 'container:-mr-280'
  | 'container-mid:-mr-280'
  | 'container-large:-mr-280'
  | 'container:mb-0'
  | 'container-mid:mb-0'
  | 'container-large:mb-0'
  | 'container:mb-1'
  | 'container-mid:mb-1'
  | 'container-large:mb-1'
  | 'container:mb-2'
  | 'container-mid:mb-2'
  | 'container-large:mb-2'
  | 'container:mb-4'
  | 'container-mid:mb-4'
  | 'container-large:mb-4'
  | 'container:mb-8'
  | 'container-mid:mb-8'
  | 'container-large:mb-8'
  | 'container:mb-10'
  | 'container-mid:mb-10'
  | 'container-large:mb-10'
  | 'container:mb-12'
  | 'container-mid:mb-12'
  | 'container-large:mb-12'
  | 'container:mb-16'
  | 'container-mid:mb-16'
  | 'container-large:mb-16'
  | 'container:mb-20'
  | 'container-mid:mb-20'
  | 'container-large:mb-20'
  | 'container:mb-24'
  | 'container-mid:mb-24'
  | 'container-large:mb-24'
  | 'container:mb-32'
  | 'container-mid:mb-32'
  | 'container-large:mb-32'
  | 'container:mb-48'
  | 'container-mid:mb-48'
  | 'container-large:mb-48'
  | 'container:mb-80'
  | 'container-mid:mb-80'
  | 'container-large:mb-80'
  | 'container:mb-100'
  | 'container-mid:mb-100'
  | 'container-large:mb-100'
  | 'container:mb-160'
  | 'container-mid:mb-160'
  | 'container-large:mb-160'
  | 'container:mb-200'
  | 'container-mid:mb-200'
  | 'container-large:mb-200'
  | 'container:mb-280'
  | 'container-mid:mb-280'
  | 'container-large:mb-280'
  | 'container:mb-auto'
  | 'container-mid:mb-auto'
  | 'container-large:mb-auto'
  | 'container:-mb-0'
  | 'container-mid:-mb-0'
  | 'container-large:-mb-0'
  | 'container:-mb-1'
  | 'container-mid:-mb-1'
  | 'container-large:-mb-1'
  | 'container:-mb-2'
  | 'container-mid:-mb-2'
  | 'container-large:-mb-2'
  | 'container:-mb-4'
  | 'container-mid:-mb-4'
  | 'container-large:-mb-4'
  | 'container:-mb-8'
  | 'container-mid:-mb-8'
  | 'container-large:-mb-8'
  | 'container:-mb-10'
  | 'container-mid:-mb-10'
  | 'container-large:-mb-10'
  | 'container:-mb-12'
  | 'container-mid:-mb-12'
  | 'container-large:-mb-12'
  | 'container:-mb-16'
  | 'container-mid:-mb-16'
  | 'container-large:-mb-16'
  | 'container:-mb-20'
  | 'container-mid:-mb-20'
  | 'container-large:-mb-20'
  | 'container:-mb-24'
  | 'container-mid:-mb-24'
  | 'container-large:-mb-24'
  | 'container:-mb-32'
  | 'container-mid:-mb-32'
  | 'container-large:-mb-32'
  | 'container:-mb-48'
  | 'container-mid:-mb-48'
  | 'container-large:-mb-48'
  | 'container:-mb-80'
  | 'container-mid:-mb-80'
  | 'container-large:-mb-80'
  | 'container:-mb-100'
  | 'container-mid:-mb-100'
  | 'container-large:-mb-100'
  | 'container:-mb-160'
  | 'container-mid:-mb-160'
  | 'container-large:-mb-160'
  | 'container:-mb-200'
  | 'container-mid:-mb-200'
  | 'container-large:-mb-200'
  | 'container:-mb-280'
  | 'container-mid:-mb-280'
  | 'container-large:-mb-280'
  | 'container:ml-0'
  | 'container-mid:ml-0'
  | 'container-large:ml-0'
  | 'container:ml-1'
  | 'container-mid:ml-1'
  | 'container-large:ml-1'
  | 'container:ml-2'
  | 'container-mid:ml-2'
  | 'container-large:ml-2'
  | 'container:ml-4'
  | 'container-mid:ml-4'
  | 'container-large:ml-4'
  | 'container:ml-8'
  | 'container-mid:ml-8'
  | 'container-large:ml-8'
  | 'container:ml-10'
  | 'container-mid:ml-10'
  | 'container-large:ml-10'
  | 'container:ml-12'
  | 'container-mid:ml-12'
  | 'container-large:ml-12'
  | 'container:ml-16'
  | 'container-mid:ml-16'
  | 'container-large:ml-16'
  | 'container:ml-20'
  | 'container-mid:ml-20'
  | 'container-large:ml-20'
  | 'container:ml-24'
  | 'container-mid:ml-24'
  | 'container-large:ml-24'
  | 'container:ml-32'
  | 'container-mid:ml-32'
  | 'container-large:ml-32'
  | 'container:ml-48'
  | 'container-mid:ml-48'
  | 'container-large:ml-48'
  | 'container:ml-80'
  | 'container-mid:ml-80'
  | 'container-large:ml-80'
  | 'container:ml-100'
  | 'container-mid:ml-100'
  | 'container-large:ml-100'
  | 'container:ml-160'
  | 'container-mid:ml-160'
  | 'container-large:ml-160'
  | 'container:ml-200'
  | 'container-mid:ml-200'
  | 'container-large:ml-200'
  | 'container:ml-280'
  | 'container-mid:ml-280'
  | 'container-large:ml-280'
  | 'container:ml-auto'
  | 'container-mid:ml-auto'
  | 'container-large:ml-auto'
  | 'container:-ml-0'
  | 'container-mid:-ml-0'
  | 'container-large:-ml-0'
  | 'container:-ml-1'
  | 'container-mid:-ml-1'
  | 'container-large:-ml-1'
  | 'container:-ml-2'
  | 'container-mid:-ml-2'
  | 'container-large:-ml-2'
  | 'container:-ml-4'
  | 'container-mid:-ml-4'
  | 'container-large:-ml-4'
  | 'container:-ml-8'
  | 'container-mid:-ml-8'
  | 'container-large:-ml-8'
  | 'container:-ml-10'
  | 'container-mid:-ml-10'
  | 'container-large:-ml-10'
  | 'container:-ml-12'
  | 'container-mid:-ml-12'
  | 'container-large:-ml-12'
  | 'container:-ml-16'
  | 'container-mid:-ml-16'
  | 'container-large:-ml-16'
  | 'container:-ml-20'
  | 'container-mid:-ml-20'
  | 'container-large:-ml-20'
  | 'container:-ml-24'
  | 'container-mid:-ml-24'
  | 'container-large:-ml-24'
  | 'container:-ml-32'
  | 'container-mid:-ml-32'
  | 'container-large:-ml-32'
  | 'container:-ml-48'
  | 'container-mid:-ml-48'
  | 'container-large:-ml-48'
  | 'container:-ml-80'
  | 'container-mid:-ml-80'
  | 'container-large:-ml-80'
  | 'container:-ml-100'
  | 'container-mid:-ml-100'
  | 'container-large:-ml-100'
  | 'container:-ml-160'
  | 'container-mid:-ml-160'
  | 'container-large:-ml-160'
  | 'container:-ml-200'
  | 'container-mid:-ml-200'
  | 'container-large:-ml-200'
  | 'container:-ml-280'
  | 'container-mid:-ml-280'
  | 'container-large:-ml-280'
  | 'container:max-h-full'
  | 'container-mid:max-h-full'
  | 'container-large:max-h-full'
  | 'container:max-h-screen'
  | 'container-mid:max-h-screen'
  | 'container-large:max-h-screen'
  | 'container:max-w-none'
  | 'container-mid:max-w-none'
  | 'container-large:max-w-none'
  | 'container:max-w-xs'
  | 'container-mid:max-w-xs'
  | 'container-large:max-w-xs'
  | 'container:max-w-sm'
  | 'container-mid:max-w-sm'
  | 'container-large:max-w-sm'
  | 'container:max-w-md'
  | 'container-mid:max-w-md'
  | 'container-large:max-w-md'
  | 'container:max-w-lg'
  | 'container-mid:max-w-lg'
  | 'container-large:max-w-lg'
  | 'container:max-w-xl'
  | 'container-mid:max-w-xl'
  | 'container-large:max-w-xl'
  | 'container:max-w-2xl'
  | 'container-mid:max-w-2xl'
  | 'container-large:max-w-2xl'
  | 'container:max-w-3xl'
  | 'container-mid:max-w-3xl'
  | 'container-large:max-w-3xl'
  | 'container:max-w-4xl'
  | 'container-mid:max-w-4xl'
  | 'container-large:max-w-4xl'
  | 'container:max-w-5xl'
  | 'container-mid:max-w-5xl'
  | 'container-large:max-w-5xl'
  | 'container:max-w-6xl'
  | 'container-mid:max-w-6xl'
  | 'container-large:max-w-6xl'
  | 'container:max-w-full'
  | 'container-mid:max-w-full'
  | 'container-large:max-w-full'
  | 'container:max-w-screen-container'
  | 'container-mid:max-w-screen-container'
  | 'container-large:max-w-screen-container'
  | 'container:max-w-screen-container-mid'
  | 'container-mid:max-w-screen-container-mid'
  | 'container-large:max-w-screen-container-mid'
  | 'container:max-w-screen-container-large'
  | 'container-mid:max-w-screen-container-large'
  | 'container-large:max-w-screen-container-large'
  | 'container:min-h-0'
  | 'container-mid:min-h-0'
  | 'container-large:min-h-0'
  | 'container:min-h-full'
  | 'container-mid:min-h-full'
  | 'container-large:min-h-full'
  | 'container:min-h-screen'
  | 'container-mid:min-h-screen'
  | 'container-large:min-h-screen'
  | 'container:min-w-0'
  | 'container-mid:min-w-0'
  | 'container-large:min-w-0'
  | 'container:min-w-full'
  | 'container-mid:min-w-full'
  | 'container-large:min-w-full'
  | 'container:object-contain'
  | 'container-mid:object-contain'
  | 'container-large:object-contain'
  | 'container:object-cover'
  | 'container-mid:object-cover'
  | 'container-large:object-cover'
  | 'container:object-fill'
  | 'container-mid:object-fill'
  | 'container-large:object-fill'
  | 'container:object-none'
  | 'container-mid:object-none'
  | 'container-large:object-none'
  | 'container:object-scale-down'
  | 'container-mid:object-scale-down'
  | 'container-large:object-scale-down'
  | 'container:object-bottom'
  | 'container-mid:object-bottom'
  | 'container-large:object-bottom'
  | 'container:object-center'
  | 'container-mid:object-center'
  | 'container-large:object-center'
  | 'container:object-left'
  | 'container-mid:object-left'
  | 'container-large:object-left'
  | 'container:object-left-bottom'
  | 'container-mid:object-left-bottom'
  | 'container-large:object-left-bottom'
  | 'container:object-left-top'
  | 'container-mid:object-left-top'
  | 'container-large:object-left-top'
  | 'container:object-right'
  | 'container-mid:object-right'
  | 'container-large:object-right'
  | 'container:object-right-bottom'
  | 'container-mid:object-right-bottom'
  | 'container-large:object-right-bottom'
  | 'container:object-right-top'
  | 'container-mid:object-right-top'
  | 'container-large:object-right-top'
  | 'container:object-top'
  | 'container-mid:object-top'
  | 'container-large:object-top'
  | 'container:opacity-0'
  | 'container-mid:opacity-0'
  | 'container-large:opacity-0'
  | 'hover:opacity-0'
  | 'focus:opacity-0'
  | 'container:opacity-25'
  | 'container-mid:opacity-25'
  | 'container-large:opacity-25'
  | 'hover:opacity-25'
  | 'focus:opacity-25'
  | 'container:opacity-50'
  | 'container-mid:opacity-50'
  | 'container-large:opacity-50'
  | 'hover:opacity-50'
  | 'focus:opacity-50'
  | 'container:opacity-75'
  | 'container-mid:opacity-75'
  | 'container-large:opacity-75'
  | 'hover:opacity-75'
  | 'focus:opacity-75'
  | 'container:opacity-100'
  | 'container-mid:opacity-100'
  | 'container-large:opacity-100'
  | 'hover:opacity-100'
  | 'focus:opacity-100'
  | 'container:order-1'
  | 'container-mid:order-1'
  | 'container-large:order-1'
  | 'container:order-2'
  | 'container-mid:order-2'
  | 'container-large:order-2'
  | 'container:order-3'
  | 'container-mid:order-3'
  | 'container-large:order-3'
  | 'container:order-4'
  | 'container-mid:order-4'
  | 'container-large:order-4'
  | 'container:order-5'
  | 'container-mid:order-5'
  | 'container-large:order-5'
  | 'container:order-6'
  | 'container-mid:order-6'
  | 'container-large:order-6'
  | 'container:order-7'
  | 'container-mid:order-7'
  | 'container-large:order-7'
  | 'container:order-8'
  | 'container-mid:order-8'
  | 'container-large:order-8'
  | 'container:order-9'
  | 'container-mid:order-9'
  | 'container-large:order-9'
  | 'container:order-10'
  | 'container-mid:order-10'
  | 'container-large:order-10'
  | 'container:order-11'
  | 'container-mid:order-11'
  | 'container-large:order-11'
  | 'container:order-12'
  | 'container-mid:order-12'
  | 'container-large:order-12'
  | 'container:order-first'
  | 'container-mid:order-first'
  | 'container-large:order-first'
  | 'container:order-last'
  | 'container-mid:order-last'
  | 'container-large:order-last'
  | 'container:order-none'
  | 'container-mid:order-none'
  | 'container-large:order-none'
  | 'container:outline-none'
  | 'container-mid:outline-none'
  | 'container-large:outline-none'
  | 'focus:outline-none'
  | 'container:outline-white'
  | 'container-mid:outline-white'
  | 'container-large:outline-white'
  | 'focus:outline-white'
  | 'container:outline-black'
  | 'container-mid:outline-black'
  | 'container-large:outline-black'
  | 'focus:outline-black'
  | 'container:overflow-auto'
  | 'container-mid:overflow-auto'
  | 'container-large:overflow-auto'
  | 'container:overflow-hidden'
  | 'container-mid:overflow-hidden'
  | 'container-large:overflow-hidden'
  | 'container:overflow-visible'
  | 'container-mid:overflow-visible'
  | 'container-large:overflow-visible'
  | 'container:overflow-scroll'
  | 'container-mid:overflow-scroll'
  | 'container-large:overflow-scroll'
  | 'container:overflow-x-auto'
  | 'container-mid:overflow-x-auto'
  | 'container-large:overflow-x-auto'
  | 'container:overflow-y-auto'
  | 'container-mid:overflow-y-auto'
  | 'container-large:overflow-y-auto'
  | 'container:overflow-x-hidden'
  | 'container-mid:overflow-x-hidden'
  | 'container-large:overflow-x-hidden'
  | 'container:overflow-y-hidden'
  | 'container-mid:overflow-y-hidden'
  | 'container-large:overflow-y-hidden'
  | 'container:overflow-x-visible'
  | 'container-mid:overflow-x-visible'
  | 'container-large:overflow-x-visible'
  | 'container:overflow-y-visible'
  | 'container-mid:overflow-y-visible'
  | 'container-large:overflow-y-visible'
  | 'container:overflow-x-scroll'
  | 'container-mid:overflow-x-scroll'
  | 'container-large:overflow-x-scroll'
  | 'container:overflow-y-scroll'
  | 'container-mid:overflow-y-scroll'
  | 'container-large:overflow-y-scroll'
  | 'container:scrolling-touch'
  | 'container-mid:scrolling-touch'
  | 'container-large:scrolling-touch'
  | 'container:scrolling-auto'
  | 'container-mid:scrolling-auto'
  | 'container-large:scrolling-auto'
  | 'container:overscroll-auto'
  | 'container-mid:overscroll-auto'
  | 'container-large:overscroll-auto'
  | 'container:overscroll-contain'
  | 'container-mid:overscroll-contain'
  | 'container-large:overscroll-contain'
  | 'container:overscroll-none'
  | 'container-mid:overscroll-none'
  | 'container-large:overscroll-none'
  | 'container:overscroll-y-auto'
  | 'container-mid:overscroll-y-auto'
  | 'container-large:overscroll-y-auto'
  | 'container:overscroll-y-contain'
  | 'container-mid:overscroll-y-contain'
  | 'container-large:overscroll-y-contain'
  | 'container:overscroll-y-none'
  | 'container-mid:overscroll-y-none'
  | 'container-large:overscroll-y-none'
  | 'container:overscroll-x-auto'
  | 'container-mid:overscroll-x-auto'
  | 'container-large:overscroll-x-auto'
  | 'container:overscroll-x-contain'
  | 'container-mid:overscroll-x-contain'
  | 'container-large:overscroll-x-contain'
  | 'container:overscroll-x-none'
  | 'container-mid:overscroll-x-none'
  | 'container-large:overscroll-x-none'
  | 'container:p-0'
  | 'container-mid:p-0'
  | 'container-large:p-0'
  | 'container:p-1'
  | 'container-mid:p-1'
  | 'container-large:p-1'
  | 'container:p-2'
  | 'container-mid:p-2'
  | 'container-large:p-2'
  | 'container:p-4'
  | 'container-mid:p-4'
  | 'container-large:p-4'
  | 'container:p-8'
  | 'container-mid:p-8'
  | 'container-large:p-8'
  | 'container:p-10'
  | 'container-mid:p-10'
  | 'container-large:p-10'
  | 'container:p-12'
  | 'container-mid:p-12'
  | 'container-large:p-12'
  | 'container:p-16'
  | 'container-mid:p-16'
  | 'container-large:p-16'
  | 'container:p-20'
  | 'container-mid:p-20'
  | 'container-large:p-20'
  | 'container:p-24'
  | 'container-mid:p-24'
  | 'container-large:p-24'
  | 'container:p-32'
  | 'container-mid:p-32'
  | 'container-large:p-32'
  | 'container:p-48'
  | 'container-mid:p-48'
  | 'container-large:p-48'
  | 'container:p-80'
  | 'container-mid:p-80'
  | 'container-large:p-80'
  | 'container:p-100'
  | 'container-mid:p-100'
  | 'container-large:p-100'
  | 'container:p-160'
  | 'container-mid:p-160'
  | 'container-large:p-160'
  | 'container:p-200'
  | 'container-mid:p-200'
  | 'container-large:p-200'
  | 'container:p-280'
  | 'container-mid:p-280'
  | 'container-large:p-280'
  | 'container:py-0'
  | 'container-mid:py-0'
  | 'container-large:py-0'
  | 'container:py-1'
  | 'container-mid:py-1'
  | 'container-large:py-1'
  | 'container:py-2'
  | 'container-mid:py-2'
  | 'container-large:py-2'
  | 'container:py-4'
  | 'container-mid:py-4'
  | 'container-large:py-4'
  | 'container:py-8'
  | 'container-mid:py-8'
  | 'container-large:py-8'
  | 'container:py-10'
  | 'container-mid:py-10'
  | 'container-large:py-10'
  | 'container:py-12'
  | 'container-mid:py-12'
  | 'container-large:py-12'
  | 'container:py-16'
  | 'container-mid:py-16'
  | 'container-large:py-16'
  | 'container:py-20'
  | 'container-mid:py-20'
  | 'container-large:py-20'
  | 'container:py-24'
  | 'container-mid:py-24'
  | 'container-large:py-24'
  | 'container:py-32'
  | 'container-mid:py-32'
  | 'container-large:py-32'
  | 'container:py-48'
  | 'container-mid:py-48'
  | 'container-large:py-48'
  | 'container:py-80'
  | 'container-mid:py-80'
  | 'container-large:py-80'
  | 'container:py-100'
  | 'container-mid:py-100'
  | 'container-large:py-100'
  | 'container:py-160'
  | 'container-mid:py-160'
  | 'container-large:py-160'
  | 'container:py-200'
  | 'container-mid:py-200'
  | 'container-large:py-200'
  | 'container:py-280'
  | 'container-mid:py-280'
  | 'container-large:py-280'
  | 'container:px-0'
  | 'container-mid:px-0'
  | 'container-large:px-0'
  | 'container:px-1'
  | 'container-mid:px-1'
  | 'container-large:px-1'
  | 'container:px-2'
  | 'container-mid:px-2'
  | 'container-large:px-2'
  | 'container:px-4'
  | 'container-mid:px-4'
  | 'container-large:px-4'
  | 'container:px-8'
  | 'container-mid:px-8'
  | 'container-large:px-8'
  | 'container:px-10'
  | 'container-mid:px-10'
  | 'container-large:px-10'
  | 'container:px-12'
  | 'container-mid:px-12'
  | 'container-large:px-12'
  | 'container:px-16'
  | 'container-mid:px-16'
  | 'container-large:px-16'
  | 'container:px-20'
  | 'container-mid:px-20'
  | 'container-large:px-20'
  | 'container:px-24'
  | 'container-mid:px-24'
  | 'container-large:px-24'
  | 'container:px-32'
  | 'container-mid:px-32'
  | 'container-large:px-32'
  | 'container:px-48'
  | 'container-mid:px-48'
  | 'container-large:px-48'
  | 'container:px-80'
  | 'container-mid:px-80'
  | 'container-large:px-80'
  | 'container:px-100'
  | 'container-mid:px-100'
  | 'container-large:px-100'
  | 'container:px-160'
  | 'container-mid:px-160'
  | 'container-large:px-160'
  | 'container:px-200'
  | 'container-mid:px-200'
  | 'container-large:px-200'
  | 'container:px-280'
  | 'container-mid:px-280'
  | 'container-large:px-280'
  | 'container:pt-0'
  | 'container-mid:pt-0'
  | 'container-large:pt-0'
  | 'container:pt-1'
  | 'container-mid:pt-1'
  | 'container-large:pt-1'
  | 'container:pt-2'
  | 'container-mid:pt-2'
  | 'container-large:pt-2'
  | 'container:pt-4'
  | 'container-mid:pt-4'
  | 'container-large:pt-4'
  | 'container:pt-8'
  | 'container-mid:pt-8'
  | 'container-large:pt-8'
  | 'container:pt-10'
  | 'container-mid:pt-10'
  | 'container-large:pt-10'
  | 'container:pt-12'
  | 'container-mid:pt-12'
  | 'container-large:pt-12'
  | 'container:pt-16'
  | 'container-mid:pt-16'
  | 'container-large:pt-16'
  | 'container:pt-20'
  | 'container-mid:pt-20'
  | 'container-large:pt-20'
  | 'container:pt-24'
  | 'container-mid:pt-24'
  | 'container-large:pt-24'
  | 'container:pt-32'
  | 'container-mid:pt-32'
  | 'container-large:pt-32'
  | 'container:pt-48'
  | 'container-mid:pt-48'
  | 'container-large:pt-48'
  | 'container:pt-80'
  | 'container-mid:pt-80'
  | 'container-large:pt-80'
  | 'container:pt-100'
  | 'container-mid:pt-100'
  | 'container-large:pt-100'
  | 'container:pt-160'
  | 'container-mid:pt-160'
  | 'container-large:pt-160'
  | 'container:pt-200'
  | 'container-mid:pt-200'
  | 'container-large:pt-200'
  | 'container:pt-280'
  | 'container-mid:pt-280'
  | 'container-large:pt-280'
  | 'container:pr-0'
  | 'container-mid:pr-0'
  | 'container-large:pr-0'
  | 'container:pr-1'
  | 'container-mid:pr-1'
  | 'container-large:pr-1'
  | 'container:pr-2'
  | 'container-mid:pr-2'
  | 'container-large:pr-2'
  | 'container:pr-4'
  | 'container-mid:pr-4'
  | 'container-large:pr-4'
  | 'container:pr-8'
  | 'container-mid:pr-8'
  | 'container-large:pr-8'
  | 'container:pr-10'
  | 'container-mid:pr-10'
  | 'container-large:pr-10'
  | 'container:pr-12'
  | 'container-mid:pr-12'
  | 'container-large:pr-12'
  | 'container:pr-16'
  | 'container-mid:pr-16'
  | 'container-large:pr-16'
  | 'container:pr-20'
  | 'container-mid:pr-20'
  | 'container-large:pr-20'
  | 'container:pr-24'
  | 'container-mid:pr-24'
  | 'container-large:pr-24'
  | 'container:pr-32'
  | 'container-mid:pr-32'
  | 'container-large:pr-32'
  | 'container:pr-48'
  | 'container-mid:pr-48'
  | 'container-large:pr-48'
  | 'container:pr-80'
  | 'container-mid:pr-80'
  | 'container-large:pr-80'
  | 'container:pr-100'
  | 'container-mid:pr-100'
  | 'container-large:pr-100'
  | 'container:pr-160'
  | 'container-mid:pr-160'
  | 'container-large:pr-160'
  | 'container:pr-200'
  | 'container-mid:pr-200'
  | 'container-large:pr-200'
  | 'container:pr-280'
  | 'container-mid:pr-280'
  | 'container-large:pr-280'
  | 'container:pb-0'
  | 'container-mid:pb-0'
  | 'container-large:pb-0'
  | 'container:pb-1'
  | 'container-mid:pb-1'
  | 'container-large:pb-1'
  | 'container:pb-2'
  | 'container-mid:pb-2'
  | 'container-large:pb-2'
  | 'container:pb-4'
  | 'container-mid:pb-4'
  | 'container-large:pb-4'
  | 'container:pb-8'
  | 'container-mid:pb-8'
  | 'container-large:pb-8'
  | 'container:pb-10'
  | 'container-mid:pb-10'
  | 'container-large:pb-10'
  | 'container:pb-12'
  | 'container-mid:pb-12'
  | 'container-large:pb-12'
  | 'container:pb-16'
  | 'container-mid:pb-16'
  | 'container-large:pb-16'
  | 'container:pb-20'
  | 'container-mid:pb-20'
  | 'container-large:pb-20'
  | 'container:pb-24'
  | 'container-mid:pb-24'
  | 'container-large:pb-24'
  | 'container:pb-32'
  | 'container-mid:pb-32'
  | 'container-large:pb-32'
  | 'container:pb-48'
  | 'container-mid:pb-48'
  | 'container-large:pb-48'
  | 'container:pb-80'
  | 'container-mid:pb-80'
  | 'container-large:pb-80'
  | 'container:pb-100'
  | 'container-mid:pb-100'
  | 'container-large:pb-100'
  | 'container:pb-160'
  | 'container-mid:pb-160'
  | 'container-large:pb-160'
  | 'container:pb-200'
  | 'container-mid:pb-200'
  | 'container-large:pb-200'
  | 'container:pb-280'
  | 'container-mid:pb-280'
  | 'container-large:pb-280'
  | 'container:pl-0'
  | 'container-mid:pl-0'
  | 'container-large:pl-0'
  | 'container:pl-1'
  | 'container-mid:pl-1'
  | 'container-large:pl-1'
  | 'container:pl-2'
  | 'container-mid:pl-2'
  | 'container-large:pl-2'
  | 'container:pl-4'
  | 'container-mid:pl-4'
  | 'container-large:pl-4'
  | 'container:pl-8'
  | 'container-mid:pl-8'
  | 'container-large:pl-8'
  | 'container:pl-10'
  | 'container-mid:pl-10'
  | 'container-large:pl-10'
  | 'container:pl-12'
  | 'container-mid:pl-12'
  | 'container-large:pl-12'
  | 'container:pl-16'
  | 'container-mid:pl-16'
  | 'container-large:pl-16'
  | 'container:pl-20'
  | 'container-mid:pl-20'
  | 'container-large:pl-20'
  | 'container:pl-24'
  | 'container-mid:pl-24'
  | 'container-large:pl-24'
  | 'container:pl-32'
  | 'container-mid:pl-32'
  | 'container-large:pl-32'
  | 'container:pl-48'
  | 'container-mid:pl-48'
  | 'container-large:pl-48'
  | 'container:pl-80'
  | 'container-mid:pl-80'
  | 'container-large:pl-80'
  | 'container:pl-100'
  | 'container-mid:pl-100'
  | 'container-large:pl-100'
  | 'container:pl-160'
  | 'container-mid:pl-160'
  | 'container-large:pl-160'
  | 'container:pl-200'
  | 'container-mid:pl-200'
  | 'container-large:pl-200'
  | 'container:pl-280'
  | 'container-mid:pl-280'
  | 'container-large:pl-280'
  | 'container:place-content-start'
  | 'container-mid:place-content-start'
  | 'container-large:place-content-start'
  | 'container:place-content-center'
  | 'container-mid:place-content-center'
  | 'container-large:place-content-center'
  | 'container:place-content-end'
  | 'container-mid:place-content-end'
  | 'container-large:place-content-end'
  | 'container:place-content-between'
  | 'container-mid:place-content-between'
  | 'container-large:place-content-between'
  | 'container:place-content-around'
  | 'container-mid:place-content-around'
  | 'container-large:place-content-around'
  | 'container:place-content-evenly'
  | 'container-mid:place-content-evenly'
  | 'container-large:place-content-evenly'
  | 'container:place-content-stretch'
  | 'container-mid:place-content-stretch'
  | 'container-large:place-content-stretch'
  | 'container:place-items-auto'
  | 'container-mid:place-items-auto'
  | 'container-large:place-items-auto'
  | 'container:place-items-start'
  | 'container-mid:place-items-start'
  | 'container-large:place-items-start'
  | 'container:place-items-center'
  | 'container-mid:place-items-center'
  | 'container-large:place-items-center'
  | 'container:place-items-end'
  | 'container-mid:place-items-end'
  | 'container-large:place-items-end'
  | 'container:place-items-stretch'
  | 'container-mid:place-items-stretch'
  | 'container-large:place-items-stretch'
  | 'container:place-self-auto'
  | 'container-mid:place-self-auto'
  | 'container-large:place-self-auto'
  | 'container:place-self-start'
  | 'container-mid:place-self-start'
  | 'container-large:place-self-start'
  | 'container:place-self-center'
  | 'container-mid:place-self-center'
  | 'container-large:place-self-center'
  | 'container:place-self-end'
  | 'container-mid:place-self-end'
  | 'container-large:place-self-end'
  | 'container:place-self-stretch'
  | 'container-mid:place-self-stretch'
  | 'container-large:place-self-stretch'
  | 'container:placeholder-transparent'
  | 'container-mid:placeholder-transparent'
  | 'container-large:placeholder-transparent'
  | 'focus:placeholder-transparent'
  | 'container:placeholder-current'
  | 'container-mid:placeholder-current'
  | 'container-large:placeholder-current'
  | 'focus:placeholder-current'
  | 'container:placeholder-black'
  | 'container-mid:placeholder-black'
  | 'container-large:placeholder-black'
  | 'focus:placeholder-black'
  | 'container:placeholder-white'
  | 'container-mid:placeholder-white'
  | 'container-large:placeholder-white'
  | 'focus:placeholder-white'
  | 'container:placeholder-gray-100'
  | 'container-mid:placeholder-gray-100'
  | 'container-large:placeholder-gray-100'
  | 'focus:placeholder-gray-100'
  | 'container:placeholder-gray-200'
  | 'container-mid:placeholder-gray-200'
  | 'container-large:placeholder-gray-200'
  | 'focus:placeholder-gray-200'
  | 'container:placeholder-gray-300'
  | 'container-mid:placeholder-gray-300'
  | 'container-large:placeholder-gray-300'
  | 'focus:placeholder-gray-300'
  | 'container:placeholder-gray-400'
  | 'container-mid:placeholder-gray-400'
  | 'container-large:placeholder-gray-400'
  | 'focus:placeholder-gray-400'
  | 'container:placeholder-gray-500'
  | 'container-mid:placeholder-gray-500'
  | 'container-large:placeholder-gray-500'
  | 'focus:placeholder-gray-500'
  | 'container:placeholder-gray-600'
  | 'container-mid:placeholder-gray-600'
  | 'container-large:placeholder-gray-600'
  | 'focus:placeholder-gray-600'
  | 'container:placeholder-gray-700'
  | 'container-mid:placeholder-gray-700'
  | 'container-large:placeholder-gray-700'
  | 'focus:placeholder-gray-700'
  | 'container:placeholder-gray-800'
  | 'container-mid:placeholder-gray-800'
  | 'container-large:placeholder-gray-800'
  | 'focus:placeholder-gray-800'
  | 'container:placeholder-gray-900'
  | 'container-mid:placeholder-gray-900'
  | 'container-large:placeholder-gray-900'
  | 'focus:placeholder-gray-900'
  | 'container:placeholder-red-100'
  | 'container-mid:placeholder-red-100'
  | 'container-large:placeholder-red-100'
  | 'focus:placeholder-red-100'
  | 'container:placeholder-red-200'
  | 'container-mid:placeholder-red-200'
  | 'container-large:placeholder-red-200'
  | 'focus:placeholder-red-200'
  | 'container:placeholder-red-300'
  | 'container-mid:placeholder-red-300'
  | 'container-large:placeholder-red-300'
  | 'focus:placeholder-red-300'
  | 'container:placeholder-red-400'
  | 'container-mid:placeholder-red-400'
  | 'container-large:placeholder-red-400'
  | 'focus:placeholder-red-400'
  | 'container:placeholder-red-500'
  | 'container-mid:placeholder-red-500'
  | 'container-large:placeholder-red-500'
  | 'focus:placeholder-red-500'
  | 'container:placeholder-red-600'
  | 'container-mid:placeholder-red-600'
  | 'container-large:placeholder-red-600'
  | 'focus:placeholder-red-600'
  | 'container:placeholder-red-700'
  | 'container-mid:placeholder-red-700'
  | 'container-large:placeholder-red-700'
  | 'focus:placeholder-red-700'
  | 'container:placeholder-red-800'
  | 'container-mid:placeholder-red-800'
  | 'container-large:placeholder-red-800'
  | 'focus:placeholder-red-800'
  | 'container:placeholder-red-900'
  | 'container-mid:placeholder-red-900'
  | 'container-large:placeholder-red-900'
  | 'focus:placeholder-red-900'
  | 'container:placeholder-orange'
  | 'container-mid:placeholder-orange'
  | 'container-large:placeholder-orange'
  | 'focus:placeholder-orange'
  | 'container:placeholder-yellow'
  | 'container-mid:placeholder-yellow'
  | 'container-large:placeholder-yellow'
  | 'focus:placeholder-yellow'
  | 'container:placeholder-green'
  | 'container-mid:placeholder-green'
  | 'container-large:placeholder-green'
  | 'focus:placeholder-green'
  | 'container:placeholder-teal-100'
  | 'container-mid:placeholder-teal-100'
  | 'container-large:placeholder-teal-100'
  | 'focus:placeholder-teal-100'
  | 'container:placeholder-teal-200'
  | 'container-mid:placeholder-teal-200'
  | 'container-large:placeholder-teal-200'
  | 'focus:placeholder-teal-200'
  | 'container:placeholder-teal-300'
  | 'container-mid:placeholder-teal-300'
  | 'container-large:placeholder-teal-300'
  | 'focus:placeholder-teal-300'
  | 'container:placeholder-teal-400'
  | 'container-mid:placeholder-teal-400'
  | 'container-large:placeholder-teal-400'
  | 'focus:placeholder-teal-400'
  | 'container:placeholder-teal-500'
  | 'container-mid:placeholder-teal-500'
  | 'container-large:placeholder-teal-500'
  | 'focus:placeholder-teal-500'
  | 'container:placeholder-teal-600'
  | 'container-mid:placeholder-teal-600'
  | 'container-large:placeholder-teal-600'
  | 'focus:placeholder-teal-600'
  | 'container:placeholder-teal-700'
  | 'container-mid:placeholder-teal-700'
  | 'container-large:placeholder-teal-700'
  | 'focus:placeholder-teal-700'
  | 'container:placeholder-teal-800'
  | 'container-mid:placeholder-teal-800'
  | 'container-large:placeholder-teal-800'
  | 'focus:placeholder-teal-800'
  | 'container:placeholder-teal-900'
  | 'container-mid:placeholder-teal-900'
  | 'container-large:placeholder-teal-900'
  | 'focus:placeholder-teal-900'
  | 'container:placeholder-blue'
  | 'container-mid:placeholder-blue'
  | 'container-large:placeholder-blue'
  | 'focus:placeholder-blue'
  | 'container:placeholder-indigo-100'
  | 'container-mid:placeholder-indigo-100'
  | 'container-large:placeholder-indigo-100'
  | 'focus:placeholder-indigo-100'
  | 'container:placeholder-indigo-200'
  | 'container-mid:placeholder-indigo-200'
  | 'container-large:placeholder-indigo-200'
  | 'focus:placeholder-indigo-200'
  | 'container:placeholder-indigo-300'
  | 'container-mid:placeholder-indigo-300'
  | 'container-large:placeholder-indigo-300'
  | 'focus:placeholder-indigo-300'
  | 'container:placeholder-indigo-400'
  | 'container-mid:placeholder-indigo-400'
  | 'container-large:placeholder-indigo-400'
  | 'focus:placeholder-indigo-400'
  | 'container:placeholder-indigo-500'
  | 'container-mid:placeholder-indigo-500'
  | 'container-large:placeholder-indigo-500'
  | 'focus:placeholder-indigo-500'
  | 'container:placeholder-indigo-600'
  | 'container-mid:placeholder-indigo-600'
  | 'container-large:placeholder-indigo-600'
  | 'focus:placeholder-indigo-600'
  | 'container:placeholder-indigo-700'
  | 'container-mid:placeholder-indigo-700'
  | 'container-large:placeholder-indigo-700'
  | 'focus:placeholder-indigo-700'
  | 'container:placeholder-indigo-800'
  | 'container-mid:placeholder-indigo-800'
  | 'container-large:placeholder-indigo-800'
  | 'focus:placeholder-indigo-800'
  | 'container:placeholder-indigo-900'
  | 'container-mid:placeholder-indigo-900'
  | 'container-large:placeholder-indigo-900'
  | 'focus:placeholder-indigo-900'
  | 'container:placeholder-purple'
  | 'container-mid:placeholder-purple'
  | 'container-large:placeholder-purple'
  | 'focus:placeholder-purple'
  | 'container:placeholder-pink'
  | 'container-mid:placeholder-pink'
  | 'container-large:placeholder-pink'
  | 'focus:placeholder-pink'
  | 'container:placeholder-soft-gold'
  | 'container-mid:placeholder-soft-gold'
  | 'container-large:placeholder-soft-gold'
  | 'focus:placeholder-soft-gold'
  | 'container:placeholder-gold'
  | 'container-mid:placeholder-gold'
  | 'container-large:placeholder-gold'
  | 'focus:placeholder-gold'
  | 'container:placeholder-darkGold'
  | 'container-mid:placeholder-darkGold'
  | 'container-large:placeholder-darkGold'
  | 'focus:placeholder-darkGold'
  | 'container:placeholder-sand'
  | 'container-mid:placeholder-sand'
  | 'container-large:placeholder-sand'
  | 'focus:placeholder-sand'
  | 'container:placeholder-lightSand'
  | 'container-mid:placeholder-lightSand'
  | 'container-large:placeholder-lightSand'
  | 'focus:placeholder-lightSand'
  | 'container:placeholder-ecru'
  | 'container-mid:placeholder-ecru'
  | 'container-large:placeholder-ecru'
  | 'focus:placeholder-ecru'
  | 'container:placeholder-lemon'
  | 'container-mid:placeholder-lemon'
  | 'container-large:placeholder-lemon'
  | 'focus:placeholder-lemon'
  | 'container:placeholder-ink'
  | 'container-mid:placeholder-ink'
  | 'container-large:placeholder-ink'
  | 'focus:placeholder-ink'
  | 'container:placeholder-steel'
  | 'container-mid:placeholder-steel'
  | 'container-large:placeholder-steel'
  | 'focus:placeholder-steel'
  | 'container:placeholder-lightGray'
  | 'container-mid:placeholder-lightGray'
  | 'container-large:placeholder-lightGray'
  | 'focus:placeholder-lightGray'
  | 'container:placeholder-unassumingGray'
  | 'container-mid:placeholder-unassumingGray'
  | 'container-large:placeholder-unassumingGray'
  | 'focus:placeholder-unassumingGray'
  | 'container:placeholder-disabledTextGray'
  | 'container-mid:placeholder-disabledTextGray'
  | 'container-large:placeholder-disabledTextGray'
  | 'focus:placeholder-disabledTextGray'
  | 'container:placeholder-lightRed'
  | 'container-mid:placeholder-lightRed'
  | 'container-large:placeholder-lightRed'
  | 'focus:placeholder-lightRed'
  | 'container:placeholder-mistakeRed'
  | 'container-mid:placeholder-mistakeRed'
  | 'container-large:placeholder-mistakeRed'
  | 'focus:placeholder-mistakeRed'
  | 'container:placeholder-grey'
  | 'container-mid:placeholder-grey'
  | 'container-large:placeholder-grey'
  | 'focus:placeholder-grey'
  | 'container:placeholder-olive-400'
  | 'container-mid:placeholder-olive-400'
  | 'container-large:placeholder-olive-400'
  | 'focus:placeholder-olive-400'
  | 'container:placeholder-olive-600'
  | 'container-mid:placeholder-olive-600'
  | 'container-large:placeholder-olive-600'
  | 'focus:placeholder-olive-600'
  | 'container:placeholder-olive-800'
  | 'container-mid:placeholder-olive-800'
  | 'container-large:placeholder-olive-800'
  | 'focus:placeholder-olive-800'
  | 'container:placeholder-opacity-0'
  | 'container-mid:placeholder-opacity-0'
  | 'container-large:placeholder-opacity-0'
  | 'focus:placeholder-opacity-0'
  | 'container:placeholder-opacity-25'
  | 'container-mid:placeholder-opacity-25'
  | 'container-large:placeholder-opacity-25'
  | 'focus:placeholder-opacity-25'
  | 'container:placeholder-opacity-50'
  | 'container-mid:placeholder-opacity-50'
  | 'container-large:placeholder-opacity-50'
  | 'focus:placeholder-opacity-50'
  | 'container:placeholder-opacity-75'
  | 'container-mid:placeholder-opacity-75'
  | 'container-large:placeholder-opacity-75'
  | 'focus:placeholder-opacity-75'
  | 'container:placeholder-opacity-100'
  | 'container-mid:placeholder-opacity-100'
  | 'container-large:placeholder-opacity-100'
  | 'focus:placeholder-opacity-100'
  | 'container:pointer-events-none'
  | 'container-mid:pointer-events-none'
  | 'container-large:pointer-events-none'
  | 'container:pointer-events-auto'
  | 'container-mid:pointer-events-auto'
  | 'container-large:pointer-events-auto'
  | 'container:static'
  | 'container-mid:static'
  | 'container-large:static'
  | 'container:fixed'
  | 'container-mid:fixed'
  | 'container-large:fixed'
  | 'container:absolute'
  | 'container-mid:absolute'
  | 'container-large:absolute'
  | 'container:relative'
  | 'container-mid:relative'
  | 'container-large:relative'
  | 'container:sticky'
  | 'container-mid:sticky'
  | 'container-large:sticky'
  | 'container:resize-none'
  | 'container-mid:resize-none'
  | 'container-large:resize-none'
  | 'container:resize'
  | 'container-mid:resize'
  | 'container-large:resize'
  | 'container:resize-y'
  | 'container-mid:resize-y'
  | 'container-large:resize-y'
  | 'container:resize-x'
  | 'container-mid:resize-x'
  | 'container-large:resize-x'
  | 'container:space-x-0'
  | 'container-mid:space-x-0'
  | 'container-large:space-x-0'
  | 'container:space-x-1'
  | 'container-mid:space-x-1'
  | 'container-large:space-x-1'
  | 'container:space-x-2'
  | 'container-mid:space-x-2'
  | 'container-large:space-x-2'
  | 'container:space-x-4'
  | 'container-mid:space-x-4'
  | 'container-large:space-x-4'
  | 'container:space-x-8'
  | 'container-mid:space-x-8'
  | 'container-large:space-x-8'
  | 'container:space-x-10'
  | 'container-mid:space-x-10'
  | 'container-large:space-x-10'
  | 'container:space-x-12'
  | 'container-mid:space-x-12'
  | 'container-large:space-x-12'
  | 'container:space-x-16'
  | 'container-mid:space-x-16'
  | 'container-large:space-x-16'
  | 'container:space-x-20'
  | 'container-mid:space-x-20'
  | 'container-large:space-x-20'
  | 'container:space-x-24'
  | 'container-mid:space-x-24'
  | 'container-large:space-x-24'
  | 'container:space-x-32'
  | 'container-mid:space-x-32'
  | 'container-large:space-x-32'
  | 'container:space-x-48'
  | 'container-mid:space-x-48'
  | 'container-large:space-x-48'
  | 'container:space-x-80'
  | 'container-mid:space-x-80'
  | 'container-large:space-x-80'
  | 'container:space-x-100'
  | 'container-mid:space-x-100'
  | 'container-large:space-x-100'
  | 'container:space-x-160'
  | 'container-mid:space-x-160'
  | 'container-large:space-x-160'
  | 'container:space-x-200'
  | 'container-mid:space-x-200'
  | 'container-large:space-x-200'
  | 'container:space-x-280'
  | 'container-mid:space-x-280'
  | 'container-large:space-x-280'
  | 'container:-space-x-0'
  | 'container-mid:-space-x-0'
  | 'container-large:-space-x-0'
  | 'container:-space-x-1'
  | 'container-mid:-space-x-1'
  | 'container-large:-space-x-1'
  | 'container:-space-x-2'
  | 'container-mid:-space-x-2'
  | 'container-large:-space-x-2'
  | 'container:-space-x-4'
  | 'container-mid:-space-x-4'
  | 'container-large:-space-x-4'
  | 'container:-space-x-8'
  | 'container-mid:-space-x-8'
  | 'container-large:-space-x-8'
  | 'container:-space-x-10'
  | 'container-mid:-space-x-10'
  | 'container-large:-space-x-10'
  | 'container:-space-x-12'
  | 'container-mid:-space-x-12'
  | 'container-large:-space-x-12'
  | 'container:-space-x-16'
  | 'container-mid:-space-x-16'
  | 'container-large:-space-x-16'
  | 'container:-space-x-20'
  | 'container-mid:-space-x-20'
  | 'container-large:-space-x-20'
  | 'container:-space-x-24'
  | 'container-mid:-space-x-24'
  | 'container-large:-space-x-24'
  | 'container:-space-x-32'
  | 'container-mid:-space-x-32'
  | 'container-large:-space-x-32'
  | 'container:-space-x-48'
  | 'container-mid:-space-x-48'
  | 'container-large:-space-x-48'
  | 'container:-space-x-80'
  | 'container-mid:-space-x-80'
  | 'container-large:-space-x-80'
  | 'container:-space-x-100'
  | 'container-mid:-space-x-100'
  | 'container-large:-space-x-100'
  | 'container:-space-x-160'
  | 'container-mid:-space-x-160'
  | 'container-large:-space-x-160'
  | 'container:-space-x-200'
  | 'container-mid:-space-x-200'
  | 'container-large:-space-x-200'
  | 'container:-space-x-280'
  | 'container-mid:-space-x-280'
  | 'container-large:-space-x-280'
  | 'container:space-x-reverse'
  | 'container-mid:space-x-reverse'
  | 'container-large:space-x-reverse'
  | 'container:space-y-0'
  | 'container-mid:space-y-0'
  | 'container-large:space-y-0'
  | 'container:space-y-1'
  | 'container-mid:space-y-1'
  | 'container-large:space-y-1'
  | 'container:space-y-2'
  | 'container-mid:space-y-2'
  | 'container-large:space-y-2'
  | 'container:space-y-4'
  | 'container-mid:space-y-4'
  | 'container-large:space-y-4'
  | 'container:space-y-8'
  | 'container-mid:space-y-8'
  | 'container-large:space-y-8'
  | 'container:space-y-10'
  | 'container-mid:space-y-10'
  | 'container-large:space-y-10'
  | 'container:space-y-12'
  | 'container-mid:space-y-12'
  | 'container-large:space-y-12'
  | 'container:space-y-16'
  | 'container-mid:space-y-16'
  | 'container-large:space-y-16'
  | 'container:space-y-20'
  | 'container-mid:space-y-20'
  | 'container-large:space-y-20'
  | 'container:space-y-24'
  | 'container-mid:space-y-24'
  | 'container-large:space-y-24'
  | 'container:space-y-32'
  | 'container-mid:space-y-32'
  | 'container-large:space-y-32'
  | 'container:space-y-48'
  | 'container-mid:space-y-48'
  | 'container-large:space-y-48'
  | 'container:space-y-80'
  | 'container-mid:space-y-80'
  | 'container-large:space-y-80'
  | 'container:space-y-100'
  | 'container-mid:space-y-100'
  | 'container-large:space-y-100'
  | 'container:space-y-160'
  | 'container-mid:space-y-160'
  | 'container-large:space-y-160'
  | 'container:space-y-200'
  | 'container-mid:space-y-200'
  | 'container-large:space-y-200'
  | 'container:space-y-280'
  | 'container-mid:space-y-280'
  | 'container-large:space-y-280'
  | 'container:-space-y-0'
  | 'container-mid:-space-y-0'
  | 'container-large:-space-y-0'
  | 'container:-space-y-1'
  | 'container-mid:-space-y-1'
  | 'container-large:-space-y-1'
  | 'container:-space-y-2'
  | 'container-mid:-space-y-2'
  | 'container-large:-space-y-2'
  | 'container:-space-y-4'
  | 'container-mid:-space-y-4'
  | 'container-large:-space-y-4'
  | 'container:-space-y-8'
  | 'container-mid:-space-y-8'
  | 'container-large:-space-y-8'
  | 'container:-space-y-10'
  | 'container-mid:-space-y-10'
  | 'container-large:-space-y-10'
  | 'container:-space-y-12'
  | 'container-mid:-space-y-12'
  | 'container-large:-space-y-12'
  | 'container:-space-y-16'
  | 'container-mid:-space-y-16'
  | 'container-large:-space-y-16'
  | 'container:-space-y-20'
  | 'container-mid:-space-y-20'
  | 'container-large:-space-y-20'
  | 'container:-space-y-24'
  | 'container-mid:-space-y-24'
  | 'container-large:-space-y-24'
  | 'container:-space-y-32'
  | 'container-mid:-space-y-32'
  | 'container-large:-space-y-32'
  | 'container:-space-y-48'
  | 'container-mid:-space-y-48'
  | 'container-large:-space-y-48'
  | 'container:-space-y-80'
  | 'container-mid:-space-y-80'
  | 'container-large:-space-y-80'
  | 'container:-space-y-100'
  | 'container-mid:-space-y-100'
  | 'container-large:-space-y-100'
  | 'container:-space-y-160'
  | 'container-mid:-space-y-160'
  | 'container-large:-space-y-160'
  | 'container:-space-y-200'
  | 'container-mid:-space-y-200'
  | 'container-large:-space-y-200'
  | 'container:-space-y-280'
  | 'container-mid:-space-y-280'
  | 'container-large:-space-y-280'
  | 'container:space-y-reverse'
  | 'container-mid:space-y-reverse'
  | 'container-large:space-y-reverse'
  | 'container:stroke-current'
  | 'container-mid:stroke-current'
  | 'container-large:stroke-current'
  | 'container:stroke-0'
  | 'container-mid:stroke-0'
  | 'container-large:stroke-0'
  | 'container:stroke-1'
  | 'container-mid:stroke-1'
  | 'container-large:stroke-1'
  | 'container:stroke-2'
  | 'container-mid:stroke-2'
  | 'container-large:stroke-2'
  | 'container:table-auto'
  | 'container-mid:table-auto'
  | 'container-large:table-auto'
  | 'container:table-fixed'
  | 'container-mid:table-fixed'
  | 'container-large:table-fixed'
  | 'container:text-left'
  | 'container-mid:text-left'
  | 'container-large:text-left'
  | 'container:text-center'
  | 'container-mid:text-center'
  | 'container-large:text-center'
  | 'container:text-right'
  | 'container-mid:text-right'
  | 'container-large:text-right'
  | 'container:text-justify'
  | 'container-mid:text-justify'
  | 'container-large:text-justify'
  | 'container:text-transparent'
  | 'container-mid:text-transparent'
  | 'container-large:text-transparent'
  | 'hover:text-transparent'
  | 'focus:text-transparent'
  | 'container:text-current'
  | 'container-mid:text-current'
  | 'container-large:text-current'
  | 'hover:text-current'
  | 'focus:text-current'
  | 'container:text-black'
  | 'container-mid:text-black'
  | 'container-large:text-black'
  | 'hover:text-black'
  | 'focus:text-black'
  | 'container:text-white'
  | 'container-mid:text-white'
  | 'container-large:text-white'
  | 'hover:text-white'
  | 'focus:text-white'
  | 'container:text-gray-100'
  | 'container-mid:text-gray-100'
  | 'container-large:text-gray-100'
  | 'hover:text-gray-100'
  | 'focus:text-gray-100'
  | 'container:text-gray-200'
  | 'container-mid:text-gray-200'
  | 'container-large:text-gray-200'
  | 'hover:text-gray-200'
  | 'focus:text-gray-200'
  | 'container:text-gray-300'
  | 'container-mid:text-gray-300'
  | 'container-large:text-gray-300'
  | 'hover:text-gray-300'
  | 'focus:text-gray-300'
  | 'container:text-gray-400'
  | 'container-mid:text-gray-400'
  | 'container-large:text-gray-400'
  | 'hover:text-gray-400'
  | 'focus:text-gray-400'
  | 'container:text-gray-500'
  | 'container-mid:text-gray-500'
  | 'container-large:text-gray-500'
  | 'hover:text-gray-500'
  | 'focus:text-gray-500'
  | 'container:text-gray-600'
  | 'container-mid:text-gray-600'
  | 'container-large:text-gray-600'
  | 'hover:text-gray-600'
  | 'focus:text-gray-600'
  | 'container:text-gray-700'
  | 'container-mid:text-gray-700'
  | 'container-large:text-gray-700'
  | 'hover:text-gray-700'
  | 'focus:text-gray-700'
  | 'container:text-gray-800'
  | 'container-mid:text-gray-800'
  | 'container-large:text-gray-800'
  | 'hover:text-gray-800'
  | 'focus:text-gray-800'
  | 'container:text-gray-900'
  | 'container-mid:text-gray-900'
  | 'container-large:text-gray-900'
  | 'hover:text-gray-900'
  | 'focus:text-gray-900'
  | 'container:text-red-100'
  | 'container-mid:text-red-100'
  | 'container-large:text-red-100'
  | 'hover:text-red-100'
  | 'focus:text-red-100'
  | 'container:text-red-200'
  | 'container-mid:text-red-200'
  | 'container-large:text-red-200'
  | 'hover:text-red-200'
  | 'focus:text-red-200'
  | 'container:text-red-300'
  | 'container-mid:text-red-300'
  | 'container-large:text-red-300'
  | 'hover:text-red-300'
  | 'focus:text-red-300'
  | 'container:text-red-400'
  | 'container-mid:text-red-400'
  | 'container-large:text-red-400'
  | 'hover:text-red-400'
  | 'focus:text-red-400'
  | 'container:text-red-500'
  | 'container-mid:text-red-500'
  | 'container-large:text-red-500'
  | 'hover:text-red-500'
  | 'focus:text-red-500'
  | 'container:text-red-600'
  | 'container-mid:text-red-600'
  | 'container-large:text-red-600'
  | 'hover:text-red-600'
  | 'focus:text-red-600'
  | 'container:text-red-700'
  | 'container-mid:text-red-700'
  | 'container-large:text-red-700'
  | 'hover:text-red-700'
  | 'focus:text-red-700'
  | 'container:text-red-800'
  | 'container-mid:text-red-800'
  | 'container-large:text-red-800'
  | 'hover:text-red-800'
  | 'focus:text-red-800'
  | 'container:text-red-900'
  | 'container-mid:text-red-900'
  | 'container-large:text-red-900'
  | 'hover:text-red-900'
  | 'focus:text-red-900'
  | 'container:text-orange'
  | 'container-mid:text-orange'
  | 'container-large:text-orange'
  | 'hover:text-orange'
  | 'focus:text-orange'
  | 'container:text-yellow'
  | 'container-mid:text-yellow'
  | 'container-large:text-yellow'
  | 'hover:text-yellow'
  | 'focus:text-yellow'
  | 'container:text-green'
  | 'container-mid:text-green'
  | 'container-large:text-green'
  | 'hover:text-green'
  | 'focus:text-green'
  | 'container:text-teal-100'
  | 'container-mid:text-teal-100'
  | 'container-large:text-teal-100'
  | 'hover:text-teal-100'
  | 'focus:text-teal-100'
  | 'container:text-teal-200'
  | 'container-mid:text-teal-200'
  | 'container-large:text-teal-200'
  | 'hover:text-teal-200'
  | 'focus:text-teal-200'
  | 'container:text-teal-300'
  | 'container-mid:text-teal-300'
  | 'container-large:text-teal-300'
  | 'hover:text-teal-300'
  | 'focus:text-teal-300'
  | 'container:text-teal-400'
  | 'container-mid:text-teal-400'
  | 'container-large:text-teal-400'
  | 'hover:text-teal-400'
  | 'focus:text-teal-400'
  | 'container:text-teal-500'
  | 'container-mid:text-teal-500'
  | 'container-large:text-teal-500'
  | 'hover:text-teal-500'
  | 'focus:text-teal-500'
  | 'container:text-teal-600'
  | 'container-mid:text-teal-600'
  | 'container-large:text-teal-600'
  | 'hover:text-teal-600'
  | 'focus:text-teal-600'
  | 'container:text-teal-700'
  | 'container-mid:text-teal-700'
  | 'container-large:text-teal-700'
  | 'hover:text-teal-700'
  | 'focus:text-teal-700'
  | 'container:text-teal-800'
  | 'container-mid:text-teal-800'
  | 'container-large:text-teal-800'
  | 'hover:text-teal-800'
  | 'focus:text-teal-800'
  | 'container:text-teal-900'
  | 'container-mid:text-teal-900'
  | 'container-large:text-teal-900'
  | 'hover:text-teal-900'
  | 'focus:text-teal-900'
  | 'container:text-blue'
  | 'container-mid:text-blue'
  | 'container-large:text-blue'
  | 'hover:text-blue'
  | 'focus:text-blue'
  | 'container:text-indigo-100'
  | 'container-mid:text-indigo-100'
  | 'container-large:text-indigo-100'
  | 'hover:text-indigo-100'
  | 'focus:text-indigo-100'
  | 'container:text-indigo-200'
  | 'container-mid:text-indigo-200'
  | 'container-large:text-indigo-200'
  | 'hover:text-indigo-200'
  | 'focus:text-indigo-200'
  | 'container:text-indigo-300'
  | 'container-mid:text-indigo-300'
  | 'container-large:text-indigo-300'
  | 'hover:text-indigo-300'
  | 'focus:text-indigo-300'
  | 'container:text-indigo-400'
  | 'container-mid:text-indigo-400'
  | 'container-large:text-indigo-400'
  | 'hover:text-indigo-400'
  | 'focus:text-indigo-400'
  | 'container:text-indigo-500'
  | 'container-mid:text-indigo-500'
  | 'container-large:text-indigo-500'
  | 'hover:text-indigo-500'
  | 'focus:text-indigo-500'
  | 'container:text-indigo-600'
  | 'container-mid:text-indigo-600'
  | 'container-large:text-indigo-600'
  | 'hover:text-indigo-600'
  | 'focus:text-indigo-600'
  | 'container:text-indigo-700'
  | 'container-mid:text-indigo-700'
  | 'container-large:text-indigo-700'
  | 'hover:text-indigo-700'
  | 'focus:text-indigo-700'
  | 'container:text-indigo-800'
  | 'container-mid:text-indigo-800'
  | 'container-large:text-indigo-800'
  | 'hover:text-indigo-800'
  | 'focus:text-indigo-800'
  | 'container:text-indigo-900'
  | 'container-mid:text-indigo-900'
  | 'container-large:text-indigo-900'
  | 'hover:text-indigo-900'
  | 'focus:text-indigo-900'
  | 'container:text-purple'
  | 'container-mid:text-purple'
  | 'container-large:text-purple'
  | 'hover:text-purple'
  | 'focus:text-purple'
  | 'container:text-pink'
  | 'container-mid:text-pink'
  | 'container-large:text-pink'
  | 'hover:text-pink'
  | 'focus:text-pink'
  | 'container:text-soft-gold'
  | 'container-mid:text-soft-gold'
  | 'container-large:text-soft-gold'
  | 'hover:text-soft-gold'
  | 'focus:text-soft-gold'
  | 'container:text-gold'
  | 'container-mid:text-gold'
  | 'container-large:text-gold'
  | 'hover:text-gold'
  | 'focus:text-gold'
  | 'container:text-darkGold'
  | 'container-mid:text-darkGold'
  | 'container-large:text-darkGold'
  | 'hover:text-darkGold'
  | 'focus:text-darkGold'
  | 'container:text-sand'
  | 'container-mid:text-sand'
  | 'container-large:text-sand'
  | 'hover:text-sand'
  | 'focus:text-sand'
  | 'container:text-lightSand'
  | 'container-mid:text-lightSand'
  | 'container-large:text-lightSand'
  | 'hover:text-lightSand'
  | 'focus:text-lightSand'
  | 'container:text-ecru'
  | 'container-mid:text-ecru'
  | 'container-large:text-ecru'
  | 'hover:text-ecru'
  | 'focus:text-ecru'
  | 'container:text-lemon'
  | 'container-mid:text-lemon'
  | 'container-large:text-lemon'
  | 'hover:text-lemon'
  | 'focus:text-lemon'
  | 'container:text-ink'
  | 'container-mid:text-ink'
  | 'container-large:text-ink'
  | 'hover:text-ink'
  | 'focus:text-ink'
  | 'container:text-steel'
  | 'container-mid:text-steel'
  | 'container-large:text-steel'
  | 'hover:text-steel'
  | 'focus:text-steel'
  | 'container:text-lightGray'
  | 'container-mid:text-lightGray'
  | 'container-large:text-lightGray'
  | 'hover:text-lightGray'
  | 'focus:text-lightGray'
  | 'container:text-unassumingGray'
  | 'container-mid:text-unassumingGray'
  | 'container-large:text-unassumingGray'
  | 'hover:text-unassumingGray'
  | 'focus:text-unassumingGray'
  | 'container:text-disabledTextGray'
  | 'container-mid:text-disabledTextGray'
  | 'container-large:text-disabledTextGray'
  | 'hover:text-disabledTextGray'
  | 'focus:text-disabledTextGray'
  | 'container:text-lightRed'
  | 'container-mid:text-lightRed'
  | 'container-large:text-lightRed'
  | 'hover:text-lightRed'
  | 'focus:text-lightRed'
  | 'container:text-mistakeRed'
  | 'container-mid:text-mistakeRed'
  | 'container-large:text-mistakeRed'
  | 'hover:text-mistakeRed'
  | 'focus:text-mistakeRed'
  | 'container:text-grey'
  | 'container-mid:text-grey'
  | 'container-large:text-grey'
  | 'hover:text-grey'
  | 'focus:text-grey'
  | 'container:text-olive-400'
  | 'container-mid:text-olive-400'
  | 'container-large:text-olive-400'
  | 'hover:text-olive-400'
  | 'focus:text-olive-400'
  | 'container:text-olive-600'
  | 'container-mid:text-olive-600'
  | 'container-large:text-olive-600'
  | 'hover:text-olive-600'
  | 'focus:text-olive-600'
  | 'container:text-olive-800'
  | 'container-mid:text-olive-800'
  | 'container-large:text-olive-800'
  | 'hover:text-olive-800'
  | 'focus:text-olive-800'
  | 'container:text-opacity-0'
  | 'container-mid:text-opacity-0'
  | 'container-large:text-opacity-0'
  | 'hover:text-opacity-0'
  | 'focus:text-opacity-0'
  | 'container:text-opacity-25'
  | 'container-mid:text-opacity-25'
  | 'container-large:text-opacity-25'
  | 'hover:text-opacity-25'
  | 'focus:text-opacity-25'
  | 'container:text-opacity-50'
  | 'container-mid:text-opacity-50'
  | 'container-large:text-opacity-50'
  | 'hover:text-opacity-50'
  | 'focus:text-opacity-50'
  | 'container:text-opacity-75'
  | 'container-mid:text-opacity-75'
  | 'container-large:text-opacity-75'
  | 'hover:text-opacity-75'
  | 'focus:text-opacity-75'
  | 'container:text-opacity-100'
  | 'container-mid:text-opacity-100'
  | 'container-large:text-opacity-100'
  | 'hover:text-opacity-100'
  | 'focus:text-opacity-100'
  | 'container:underline'
  | 'container-mid:underline'
  | 'container-large:underline'
  | 'hover:underline'
  | 'focus:underline'
  | 'container:line-through'
  | 'container-mid:line-through'
  | 'container-large:line-through'
  | 'hover:line-through'
  | 'focus:line-through'
  | 'container:no-underline'
  | 'container-mid:no-underline'
  | 'container-large:no-underline'
  | 'hover:no-underline'
  | 'focus:no-underline'
  | 'container:uppercase'
  | 'container-mid:uppercase'
  | 'container-large:uppercase'
  | 'container:lowercase'
  | 'container-mid:lowercase'
  | 'container-large:lowercase'
  | 'container:capitalize'
  | 'container-mid:capitalize'
  | 'container-large:capitalize'
  | 'container:normal-case'
  | 'container-mid:normal-case'
  | 'container-large:normal-case'
  | 'container:select-none'
  | 'container-mid:select-none'
  | 'container-large:select-none'
  | 'container:select-text'
  | 'container-mid:select-text'
  | 'container-large:select-text'
  | 'container:select-all'
  | 'container-mid:select-all'
  | 'container-large:select-all'
  | 'container:select-auto'
  | 'container-mid:select-auto'
  | 'container-large:select-auto'
  | 'container:align-baseline'
  | 'container-mid:align-baseline'
  | 'container-large:align-baseline'
  | 'container:align-top'
  | 'container-mid:align-top'
  | 'container-large:align-top'
  | 'container:align-middle'
  | 'container-mid:align-middle'
  | 'container-large:align-middle'
  | 'container:align-bottom'
  | 'container-mid:align-bottom'
  | 'container-large:align-bottom'
  | 'container:align-text-top'
  | 'container-mid:align-text-top'
  | 'container-large:align-text-top'
  | 'container:align-text-bottom'
  | 'container-mid:align-text-bottom'
  | 'container-large:align-text-bottom'
  | 'container:visible'
  | 'container-mid:visible'
  | 'container-large:visible'
  | 'container:invisible'
  | 'container-mid:invisible'
  | 'container-large:invisible'
  | 'container:whitespace-normal'
  | 'container-mid:whitespace-normal'
  | 'container-large:whitespace-normal'
  | 'container:whitespace-no-wrap'
  | 'container-mid:whitespace-no-wrap'
  | 'container-large:whitespace-no-wrap'
  | 'container:whitespace-pre'
  | 'container-mid:whitespace-pre'
  | 'container-large:whitespace-pre'
  | 'container:whitespace-pre-line'
  | 'container-mid:whitespace-pre-line'
  | 'container-large:whitespace-pre-line'
  | 'container:whitespace-pre-wrap'
  | 'container-mid:whitespace-pre-wrap'
  | 'container-large:whitespace-pre-wrap'
  | 'container:w-0'
  | 'container-mid:w-0'
  | 'container-large:w-0'
  | 'container:w-1'
  | 'container-mid:w-1'
  | 'container-large:w-1'
  | 'container:w-2'
  | 'container-mid:w-2'
  | 'container-large:w-2'
  | 'container:w-4'
  | 'container-mid:w-4'
  | 'container-large:w-4'
  | 'container:w-8'
  | 'container-mid:w-8'
  | 'container-large:w-8'
  | 'container:w-10'
  | 'container-mid:w-10'
  | 'container-large:w-10'
  | 'container:w-12'
  | 'container-mid:w-12'
  | 'container-large:w-12'
  | 'container:w-16'
  | 'container-mid:w-16'
  | 'container-large:w-16'
  | 'container:w-20'
  | 'container-mid:w-20'
  | 'container-large:w-20'
  | 'container:w-24'
  | 'container-mid:w-24'
  | 'container-large:w-24'
  | 'container:w-32'
  | 'container-mid:w-32'
  | 'container-large:w-32'
  | 'container:w-48'
  | 'container-mid:w-48'
  | 'container-large:w-48'
  | 'container:w-80'
  | 'container-mid:w-80'
  | 'container-large:w-80'
  | 'container:w-100'
  | 'container-mid:w-100'
  | 'container-large:w-100'
  | 'container:w-160'
  | 'container-mid:w-160'
  | 'container-large:w-160'
  | 'container:w-200'
  | 'container-mid:w-200'
  | 'container-large:w-200'
  | 'container:w-280'
  | 'container-mid:w-280'
  | 'container-large:w-280'
  | 'container:w-auto'
  | 'container-mid:w-auto'
  | 'container-large:w-auto'
  | 'container:w-1/2'
  | 'container-mid:w-1/2'
  | 'container-large:w-1/2'
  | 'container:w-1/3'
  | 'container-mid:w-1/3'
  | 'container-large:w-1/3'
  | 'container:w-2/3'
  | 'container-mid:w-2/3'
  | 'container-large:w-2/3'
  | 'container:w-1/4'
  | 'container-mid:w-1/4'
  | 'container-large:w-1/4'
  | 'container:w-2/4'
  | 'container-mid:w-2/4'
  | 'container-large:w-2/4'
  | 'container:w-3/4'
  | 'container-mid:w-3/4'
  | 'container-large:w-3/4'
  | 'container:w-1/5'
  | 'container-mid:w-1/5'
  | 'container-large:w-1/5'
  | 'container:w-2/5'
  | 'container-mid:w-2/5'
  | 'container-large:w-2/5'
  | 'container:w-3/5'
  | 'container-mid:w-3/5'
  | 'container-large:w-3/5'
  | 'container:w-4/5'
  | 'container-mid:w-4/5'
  | 'container-large:w-4/5'
  | 'container:w-1/6'
  | 'container-mid:w-1/6'
  | 'container-large:w-1/6'
  | 'container:w-2/6'
  | 'container-mid:w-2/6'
  | 'container-large:w-2/6'
  | 'container:w-3/6'
  | 'container-mid:w-3/6'
  | 'container-large:w-3/6'
  | 'container:w-4/6'
  | 'container-mid:w-4/6'
  | 'container-large:w-4/6'
  | 'container:w-5/6'
  | 'container-mid:w-5/6'
  | 'container-large:w-5/6'
  | 'container:w-1/12'
  | 'container-mid:w-1/12'
  | 'container-large:w-1/12'
  | 'container:w-2/12'
  | 'container-mid:w-2/12'
  | 'container-large:w-2/12'
  | 'container:w-3/12'
  | 'container-mid:w-3/12'
  | 'container-large:w-3/12'
  | 'container:w-4/12'
  | 'container-mid:w-4/12'
  | 'container-large:w-4/12'
  | 'container:w-5/12'
  | 'container-mid:w-5/12'
  | 'container-large:w-5/12'
  | 'container:w-6/12'
  | 'container-mid:w-6/12'
  | 'container-large:w-6/12'
  | 'container:w-7/12'
  | 'container-mid:w-7/12'
  | 'container-large:w-7/12'
  | 'container:w-8/12'
  | 'container-mid:w-8/12'
  | 'container-large:w-8/12'
  | 'container:w-9/12'
  | 'container-mid:w-9/12'
  | 'container-large:w-9/12'
  | 'container:w-10/12'
  | 'container-mid:w-10/12'
  | 'container-large:w-10/12'
  | 'container:w-11/12'
  | 'container-mid:w-11/12'
  | 'container-large:w-11/12'
  | 'container:w-full'
  | 'container-mid:w-full'
  | 'container-large:w-full'
  | 'container:w-screen'
  | 'container-mid:w-screen'
  | 'container-large:w-screen'
  | 'container:break-normal'
  | 'container-mid:break-normal'
  | 'container-large:break-normal'
  | 'container:break-words'
  | 'container-mid:break-words'
  | 'container-large:break-words'
  | 'container:break-all'
  | 'container-mid:break-all'
  | 'container-large:break-all'
  | 'container:truncate'
  | 'container-mid:truncate'
  | 'container-large:truncate'
  | 'container:z-0'
  | 'container-mid:z-0'
  | 'container-large:z-0'
  | 'container:z-10'
  | 'container-mid:z-10'
  | 'container-large:z-10'
  | 'container:z-20'
  | 'container-mid:z-20'
  | 'container-large:z-20'
  | 'container:z-30'
  | 'container-mid:z-30'
  | 'container-large:z-30'
  | 'container:z-40'
  | 'container-mid:z-40'
  | 'container-large:z-40'
  | 'container:z-50'
  | 'container-mid:z-50'
  | 'container-large:z-50'
  | 'container:z-auto'
  | 'container-mid:z-auto'
  | 'container-large:z-auto'
  | 'container:-z-1'
  | 'container-mid:-z-1'
  | 'container-large:-z-1'
  | 'container:gap-0'
  | 'container-mid:gap-0'
  | 'container-large:gap-0'
  | 'container:gap-1'
  | 'container-mid:gap-1'
  | 'container-large:gap-1'
  | 'container:gap-2'
  | 'container-mid:gap-2'
  | 'container-large:gap-2'
  | 'container:gap-4'
  | 'container-mid:gap-4'
  | 'container-large:gap-4'
  | 'container:gap-8'
  | 'container-mid:gap-8'
  | 'container-large:gap-8'
  | 'container:gap-10'
  | 'container-mid:gap-10'
  | 'container-large:gap-10'
  | 'container:gap-12'
  | 'container-mid:gap-12'
  | 'container-large:gap-12'
  | 'container:gap-16'
  | 'container-mid:gap-16'
  | 'container-large:gap-16'
  | 'container:gap-20'
  | 'container-mid:gap-20'
  | 'container-large:gap-20'
  | 'container:gap-24'
  | 'container-mid:gap-24'
  | 'container-large:gap-24'
  | 'container:gap-32'
  | 'container-mid:gap-32'
  | 'container-large:gap-32'
  | 'container:gap-48'
  | 'container-mid:gap-48'
  | 'container-large:gap-48'
  | 'container:gap-80'
  | 'container-mid:gap-80'
  | 'container-large:gap-80'
  | 'container:gap-100'
  | 'container-mid:gap-100'
  | 'container-large:gap-100'
  | 'container:gap-160'
  | 'container-mid:gap-160'
  | 'container-large:gap-160'
  | 'container:gap-200'
  | 'container-mid:gap-200'
  | 'container-large:gap-200'
  | 'container:gap-280'
  | 'container-mid:gap-280'
  | 'container-large:gap-280'
  | 'container:gap-y-0'
  | 'container-mid:gap-y-0'
  | 'container-large:gap-y-0'
  | 'container:gap-y-1'
  | 'container-mid:gap-y-1'
  | 'container-large:gap-y-1'
  | 'container:gap-y-2'
  | 'container-mid:gap-y-2'
  | 'container-large:gap-y-2'
  | 'container:gap-y-4'
  | 'container-mid:gap-y-4'
  | 'container-large:gap-y-4'
  | 'container:gap-y-8'
  | 'container-mid:gap-y-8'
  | 'container-large:gap-y-8'
  | 'container:gap-y-10'
  | 'container-mid:gap-y-10'
  | 'container-large:gap-y-10'
  | 'container:gap-y-12'
  | 'container-mid:gap-y-12'
  | 'container-large:gap-y-12'
  | 'container:gap-y-16'
  | 'container-mid:gap-y-16'
  | 'container-large:gap-y-16'
  | 'container:gap-y-20'
  | 'container-mid:gap-y-20'
  | 'container-large:gap-y-20'
  | 'container:gap-y-24'
  | 'container-mid:gap-y-24'
  | 'container-large:gap-y-24'
  | 'container:gap-y-32'
  | 'container-mid:gap-y-32'
  | 'container-large:gap-y-32'
  | 'container:gap-y-48'
  | 'container-mid:gap-y-48'
  | 'container-large:gap-y-48'
  | 'container:gap-y-80'
  | 'container-mid:gap-y-80'
  | 'container-large:gap-y-80'
  | 'container:gap-y-100'
  | 'container-mid:gap-y-100'
  | 'container-large:gap-y-100'
  | 'container:gap-y-160'
  | 'container-mid:gap-y-160'
  | 'container-large:gap-y-160'
  | 'container:gap-y-200'
  | 'container-mid:gap-y-200'
  | 'container-large:gap-y-200'
  | 'container:gap-y-280'
  | 'container-mid:gap-y-280'
  | 'container-large:gap-y-280'
  | 'container:gap-x-0'
  | 'container-mid:gap-x-0'
  | 'container-large:gap-x-0'
  | 'container:gap-x-1'
  | 'container-mid:gap-x-1'
  | 'container-large:gap-x-1'
  | 'container:gap-x-2'
  | 'container-mid:gap-x-2'
  | 'container-large:gap-x-2'
  | 'container:gap-x-4'
  | 'container-mid:gap-x-4'
  | 'container-large:gap-x-4'
  | 'container:gap-x-8'
  | 'container-mid:gap-x-8'
  | 'container-large:gap-x-8'
  | 'container:gap-x-10'
  | 'container-mid:gap-x-10'
  | 'container-large:gap-x-10'
  | 'container:gap-x-12'
  | 'container-mid:gap-x-12'
  | 'container-large:gap-x-12'
  | 'container:gap-x-16'
  | 'container-mid:gap-x-16'
  | 'container-large:gap-x-16'
  | 'container:gap-x-20'
  | 'container-mid:gap-x-20'
  | 'container-large:gap-x-20'
  | 'container:gap-x-24'
  | 'container-mid:gap-x-24'
  | 'container-large:gap-x-24'
  | 'container:gap-x-32'
  | 'container-mid:gap-x-32'
  | 'container-large:gap-x-32'
  | 'container:gap-x-48'
  | 'container-mid:gap-x-48'
  | 'container-large:gap-x-48'
  | 'container:gap-x-80'
  | 'container-mid:gap-x-80'
  | 'container-large:gap-x-80'
  | 'container:gap-x-100'
  | 'container-mid:gap-x-100'
  | 'container-large:gap-x-100'
  | 'container:gap-x-160'
  | 'container-mid:gap-x-160'
  | 'container-large:gap-x-160'
  | 'container:gap-x-200'
  | 'container-mid:gap-x-200'
  | 'container-large:gap-x-200'
  | 'container:gap-x-280'
  | 'container-mid:gap-x-280'
  | 'container-large:gap-x-280'
  | 'container:row-gap-0'
  | 'container-mid:row-gap-0'
  | 'container-large:row-gap-0'
  | 'container:row-gap-1'
  | 'container-mid:row-gap-1'
  | 'container-large:row-gap-1'
  | 'container:row-gap-2'
  | 'container-mid:row-gap-2'
  | 'container-large:row-gap-2'
  | 'container:row-gap-4'
  | 'container-mid:row-gap-4'
  | 'container-large:row-gap-4'
  | 'container:row-gap-8'
  | 'container-mid:row-gap-8'
  | 'container-large:row-gap-8'
  | 'container:row-gap-10'
  | 'container-mid:row-gap-10'
  | 'container-large:row-gap-10'
  | 'container:row-gap-12'
  | 'container-mid:row-gap-12'
  | 'container-large:row-gap-12'
  | 'container:row-gap-16'
  | 'container-mid:row-gap-16'
  | 'container-large:row-gap-16'
  | 'container:row-gap-20'
  | 'container-mid:row-gap-20'
  | 'container-large:row-gap-20'
  | 'container:row-gap-24'
  | 'container-mid:row-gap-24'
  | 'container-large:row-gap-24'
  | 'container:row-gap-32'
  | 'container-mid:row-gap-32'
  | 'container-large:row-gap-32'
  | 'container:row-gap-48'
  | 'container-mid:row-gap-48'
  | 'container-large:row-gap-48'
  | 'container:row-gap-80'
  | 'container-mid:row-gap-80'
  | 'container-large:row-gap-80'
  | 'container:row-gap-100'
  | 'container-mid:row-gap-100'
  | 'container-large:row-gap-100'
  | 'container:row-gap-160'
  | 'container-mid:row-gap-160'
  | 'container-large:row-gap-160'
  | 'container:row-gap-200'
  | 'container-mid:row-gap-200'
  | 'container-large:row-gap-200'
  | 'container:row-gap-280'
  | 'container-mid:row-gap-280'
  | 'container-large:row-gap-280'
  | 'container:col-gap-0'
  | 'container-mid:col-gap-0'
  | 'container-large:col-gap-0'
  | 'container:col-gap-1'
  | 'container-mid:col-gap-1'
  | 'container-large:col-gap-1'
  | 'container:col-gap-2'
  | 'container-mid:col-gap-2'
  | 'container-large:col-gap-2'
  | 'container:col-gap-4'
  | 'container-mid:col-gap-4'
  | 'container-large:col-gap-4'
  | 'container:col-gap-8'
  | 'container-mid:col-gap-8'
  | 'container-large:col-gap-8'
  | 'container:col-gap-10'
  | 'container-mid:col-gap-10'
  | 'container-large:col-gap-10'
  | 'container:col-gap-12'
  | 'container-mid:col-gap-12'
  | 'container-large:col-gap-12'
  | 'container:col-gap-16'
  | 'container-mid:col-gap-16'
  | 'container-large:col-gap-16'
  | 'container:col-gap-20'
  | 'container-mid:col-gap-20'
  | 'container-large:col-gap-20'
  | 'container:col-gap-24'
  | 'container-mid:col-gap-24'
  | 'container-large:col-gap-24'
  | 'container:col-gap-32'
  | 'container-mid:col-gap-32'
  | 'container-large:col-gap-32'
  | 'container:col-gap-48'
  | 'container-mid:col-gap-48'
  | 'container-large:col-gap-48'
  | 'container:col-gap-80'
  | 'container-mid:col-gap-80'
  | 'container-large:col-gap-80'
  | 'container:col-gap-100'
  | 'container-mid:col-gap-100'
  | 'container-large:col-gap-100'
  | 'container:col-gap-160'
  | 'container-mid:col-gap-160'
  | 'container-large:col-gap-160'
  | 'container:col-gap-200'
  | 'container-mid:col-gap-200'
  | 'container-large:col-gap-200'
  | 'container:col-gap-280'
  | 'container-mid:col-gap-280'
  | 'container-large:col-gap-280'
  | 'container:grid-flow-row'
  | 'container-mid:grid-flow-row'
  | 'container-large:grid-flow-row'
  | 'container:grid-flow-col'
  | 'container-mid:grid-flow-col'
  | 'container-large:grid-flow-col'
  | 'container:grid-flow-row-dense'
  | 'container-mid:grid-flow-row-dense'
  | 'container-large:grid-flow-row-dense'
  | 'container:grid-flow-col-dense'
  | 'container-mid:grid-flow-col-dense'
  | 'container-large:grid-flow-col-dense'
  | 'container:grid-cols-1'
  | 'container-mid:grid-cols-1'
  | 'container-large:grid-cols-1'
  | 'container:grid-cols-2'
  | 'container-mid:grid-cols-2'
  | 'container-large:grid-cols-2'
  | 'container:grid-cols-3'
  | 'container-mid:grid-cols-3'
  | 'container-large:grid-cols-3'
  | 'container:grid-cols-4'
  | 'container-mid:grid-cols-4'
  | 'container-large:grid-cols-4'
  | 'container:grid-cols-5'
  | 'container-mid:grid-cols-5'
  | 'container-large:grid-cols-5'
  | 'container:grid-cols-6'
  | 'container-mid:grid-cols-6'
  | 'container-large:grid-cols-6'
  | 'container:grid-cols-7'
  | 'container-mid:grid-cols-7'
  | 'container-large:grid-cols-7'
  | 'container:grid-cols-8'
  | 'container-mid:grid-cols-8'
  | 'container-large:grid-cols-8'
  | 'container:grid-cols-9'
  | 'container-mid:grid-cols-9'
  | 'container-large:grid-cols-9'
  | 'container:grid-cols-10'
  | 'container-mid:grid-cols-10'
  | 'container-large:grid-cols-10'
  | 'container:grid-cols-11'
  | 'container-mid:grid-cols-11'
  | 'container-large:grid-cols-11'
  | 'container:grid-cols-12'
  | 'container-mid:grid-cols-12'
  | 'container-large:grid-cols-12'
  | 'container:grid-cols-none'
  | 'container-mid:grid-cols-none'
  | 'container-large:grid-cols-none'
  | 'container:auto-cols-auto'
  | 'container-mid:auto-cols-auto'
  | 'container-large:auto-cols-auto'
  | 'container:auto-cols-min'
  | 'container-mid:auto-cols-min'
  | 'container-large:auto-cols-min'
  | 'container:auto-cols-max'
  | 'container-mid:auto-cols-max'
  | 'container-large:auto-cols-max'
  | 'container:auto-cols-fr'
  | 'container-mid:auto-cols-fr'
  | 'container-large:auto-cols-fr'
  | 'container:col-auto'
  | 'container-mid:col-auto'
  | 'container-large:col-auto'
  | 'container:col-span-1'
  | 'container-mid:col-span-1'
  | 'container-large:col-span-1'
  | 'container:col-span-2'
  | 'container-mid:col-span-2'
  | 'container-large:col-span-2'
  | 'container:col-span-3'
  | 'container-mid:col-span-3'
  | 'container-large:col-span-3'
  | 'container:col-span-4'
  | 'container-mid:col-span-4'
  | 'container-large:col-span-4'
  | 'container:col-span-5'
  | 'container-mid:col-span-5'
  | 'container-large:col-span-5'
  | 'container:col-span-6'
  | 'container-mid:col-span-6'
  | 'container-large:col-span-6'
  | 'container:col-span-7'
  | 'container-mid:col-span-7'
  | 'container-large:col-span-7'
  | 'container:col-span-8'
  | 'container-mid:col-span-8'
  | 'container-large:col-span-8'
  | 'container:col-span-9'
  | 'container-mid:col-span-9'
  | 'container-large:col-span-9'
  | 'container:col-span-10'
  | 'container-mid:col-span-10'
  | 'container-large:col-span-10'
  | 'container:col-span-11'
  | 'container-mid:col-span-11'
  | 'container-large:col-span-11'
  | 'container:col-span-12'
  | 'container-mid:col-span-12'
  | 'container-large:col-span-12'
  | 'container:col-span-full'
  | 'container-mid:col-span-full'
  | 'container-large:col-span-full'
  | 'container:col-start-1'
  | 'container-mid:col-start-1'
  | 'container-large:col-start-1'
  | 'container:col-start-2'
  | 'container-mid:col-start-2'
  | 'container-large:col-start-2'
  | 'container:col-start-3'
  | 'container-mid:col-start-3'
  | 'container-large:col-start-3'
  | 'container:col-start-4'
  | 'container-mid:col-start-4'
  | 'container-large:col-start-4'
  | 'container:col-start-5'
  | 'container-mid:col-start-5'
  | 'container-large:col-start-5'
  | 'container:col-start-6'
  | 'container-mid:col-start-6'
  | 'container-large:col-start-6'
  | 'container:col-start-7'
  | 'container-mid:col-start-7'
  | 'container-large:col-start-7'
  | 'container:col-start-8'
  | 'container-mid:col-start-8'
  | 'container-large:col-start-8'
  | 'container:col-start-9'
  | 'container-mid:col-start-9'
  | 'container-large:col-start-9'
  | 'container:col-start-10'
  | 'container-mid:col-start-10'
  | 'container-large:col-start-10'
  | 'container:col-start-11'
  | 'container-mid:col-start-11'
  | 'container-large:col-start-11'
  | 'container:col-start-12'
  | 'container-mid:col-start-12'
  | 'container-large:col-start-12'
  | 'container:col-start-13'
  | 'container-mid:col-start-13'
  | 'container-large:col-start-13'
  | 'container:col-start-auto'
  | 'container-mid:col-start-auto'
  | 'container-large:col-start-auto'
  | 'container:col-end-1'
  | 'container-mid:col-end-1'
  | 'container-large:col-end-1'
  | 'container:col-end-2'
  | 'container-mid:col-end-2'
  | 'container-large:col-end-2'
  | 'container:col-end-3'
  | 'container-mid:col-end-3'
  | 'container-large:col-end-3'
  | 'container:col-end-4'
  | 'container-mid:col-end-4'
  | 'container-large:col-end-4'
  | 'container:col-end-5'
  | 'container-mid:col-end-5'
  | 'container-large:col-end-5'
  | 'container:col-end-6'
  | 'container-mid:col-end-6'
  | 'container-large:col-end-6'
  | 'container:col-end-7'
  | 'container-mid:col-end-7'
  | 'container-large:col-end-7'
  | 'container:col-end-8'
  | 'container-mid:col-end-8'
  | 'container-large:col-end-8'
  | 'container:col-end-9'
  | 'container-mid:col-end-9'
  | 'container-large:col-end-9'
  | 'container:col-end-10'
  | 'container-mid:col-end-10'
  | 'container-large:col-end-10'
  | 'container:col-end-11'
  | 'container-mid:col-end-11'
  | 'container-large:col-end-11'
  | 'container:col-end-12'
  | 'container-mid:col-end-12'
  | 'container-large:col-end-12'
  | 'container:col-end-13'
  | 'container-mid:col-end-13'
  | 'container-large:col-end-13'
  | 'container:col-end-auto'
  | 'container-mid:col-end-auto'
  | 'container-large:col-end-auto'
  | 'container:grid-rows-1'
  | 'container-mid:grid-rows-1'
  | 'container-large:grid-rows-1'
  | 'container:grid-rows-2'
  | 'container-mid:grid-rows-2'
  | 'container-large:grid-rows-2'
  | 'container:grid-rows-3'
  | 'container-mid:grid-rows-3'
  | 'container-large:grid-rows-3'
  | 'container:grid-rows-4'
  | 'container-mid:grid-rows-4'
  | 'container-large:grid-rows-4'
  | 'container:grid-rows-5'
  | 'container-mid:grid-rows-5'
  | 'container-large:grid-rows-5'
  | 'container:grid-rows-6'
  | 'container-mid:grid-rows-6'
  | 'container-large:grid-rows-6'
  | 'container:grid-rows-none'
  | 'container-mid:grid-rows-none'
  | 'container-large:grid-rows-none'
  | 'container:auto-rows-auto'
  | 'container-mid:auto-rows-auto'
  | 'container-large:auto-rows-auto'
  | 'container:auto-rows-min'
  | 'container-mid:auto-rows-min'
  | 'container-large:auto-rows-min'
  | 'container:auto-rows-max'
  | 'container-mid:auto-rows-max'
  | 'container-large:auto-rows-max'
  | 'container:auto-rows-fr'
  | 'container-mid:auto-rows-fr'
  | 'container-large:auto-rows-fr'
  | 'container:row-auto'
  | 'container-mid:row-auto'
  | 'container-large:row-auto'
  | 'container:row-span-1'
  | 'container-mid:row-span-1'
  | 'container-large:row-span-1'
  | 'container:row-span-2'
  | 'container-mid:row-span-2'
  | 'container-large:row-span-2'
  | 'container:row-span-3'
  | 'container-mid:row-span-3'
  | 'container-large:row-span-3'
  | 'container:row-span-4'
  | 'container-mid:row-span-4'
  | 'container-large:row-span-4'
  | 'container:row-span-5'
  | 'container-mid:row-span-5'
  | 'container-large:row-span-5'
  | 'container:row-span-6'
  | 'container-mid:row-span-6'
  | 'container-large:row-span-6'
  | 'container:row-span-full'
  | 'container-mid:row-span-full'
  | 'container-large:row-span-full'
  | 'container:row-start-1'
  | 'container-mid:row-start-1'
  | 'container-large:row-start-1'
  | 'container:row-start-2'
  | 'container-mid:row-start-2'
  | 'container-large:row-start-2'
  | 'container:row-start-3'
  | 'container-mid:row-start-3'
  | 'container-large:row-start-3'
  | 'container:row-start-4'
  | 'container-mid:row-start-4'
  | 'container-large:row-start-4'
  | 'container:row-start-5'
  | 'container-mid:row-start-5'
  | 'container-large:row-start-5'
  | 'container:row-start-6'
  | 'container-mid:row-start-6'
  | 'container-large:row-start-6'
  | 'container:row-start-7'
  | 'container-mid:row-start-7'
  | 'container-large:row-start-7'
  | 'container:row-start-auto'
  | 'container-mid:row-start-auto'
  | 'container-large:row-start-auto'
  | 'container:row-end-1'
  | 'container-mid:row-end-1'
  | 'container-large:row-end-1'
  | 'container:row-end-2'
  | 'container-mid:row-end-2'
  | 'container-large:row-end-2'
  | 'container:row-end-3'
  | 'container-mid:row-end-3'
  | 'container-large:row-end-3'
  | 'container:row-end-4'
  | 'container-mid:row-end-4'
  | 'container-large:row-end-4'
  | 'container:row-end-5'
  | 'container-mid:row-end-5'
  | 'container-large:row-end-5'
  | 'container:row-end-6'
  | 'container-mid:row-end-6'
  | 'container-large:row-end-6'
  | 'container:row-end-7'
  | 'container-mid:row-end-7'
  | 'container-large:row-end-7'
  | 'container:row-end-auto'
  | 'container-mid:row-end-auto'
  | 'container-large:row-end-auto'
  | 'container:origin-0'
  | 'container-mid:origin-0'
  | 'container-large:origin-0'
  | 'container:origin-center'
  | 'container-mid:origin-center'
  | 'container-large:origin-center'
  | 'container:origin-top'
  | 'container-mid:origin-top'
  | 'container-large:origin-top'
  | 'container:origin-top-right'
  | 'container-mid:origin-top-right'
  | 'container-large:origin-top-right'
  | 'container:origin-right'
  | 'container-mid:origin-right'
  | 'container-large:origin-right'
  | 'container:origin-bottom-right'
  | 'container-mid:origin-bottom-right'
  | 'container-large:origin-bottom-right'
  | 'container:origin-bottom'
  | 'container-mid:origin-bottom'
  | 'container-large:origin-bottom'
  | 'container:origin-bottom-left'
  | 'container-mid:origin-bottom-left'
  | 'container-large:origin-bottom-left'
  | 'container:origin-left'
  | 'container-mid:origin-left'
  | 'container-large:origin-left'
  | 'container:origin-top-left'
  | 'container-mid:origin-top-left'
  | 'container-large:origin-top-left'
  | 'active:scale-0'
  | 'group-hover:scale-0'
  | 'active:scale-50'
  | 'group-hover:scale-50'
  | 'active:scale-75'
  | 'group-hover:scale-75'
  | 'active:scale-90'
  | 'group-hover:scale-90'
  | 'active:scale-95'
  | 'group-hover:scale-95'
  | 'active:scale-100'
  | 'group-hover:scale-100'
  | 'active:scale-105'
  | 'group-hover:scale-105'
  | 'active:scale-110'
  | 'group-hover:scale-110'
  | 'active:scale-125'
  | 'group-hover:scale-125'
  | 'active:scale-150'
  | 'group-hover:scale-150'
  | 'active:scale-x-0'
  | 'group-hover:scale-x-0'
  | 'active:scale-x-50'
  | 'group-hover:scale-x-50'
  | 'active:scale-x-75'
  | 'group-hover:scale-x-75'
  | 'active:scale-x-90'
  | 'group-hover:scale-x-90'
  | 'active:scale-x-95'
  | 'group-hover:scale-x-95'
  | 'active:scale-x-100'
  | 'group-hover:scale-x-100'
  | 'active:scale-x-105'
  | 'group-hover:scale-x-105'
  | 'active:scale-x-110'
  | 'group-hover:scale-x-110'
  | 'active:scale-x-125'
  | 'group-hover:scale-x-125'
  | 'active:scale-x-150'
  | 'group-hover:scale-x-150'
  | 'active:scale-y-0'
  | 'group-hover:scale-y-0'
  | 'active:scale-y-50'
  | 'group-hover:scale-y-50'
  | 'active:scale-y-75'
  | 'group-hover:scale-y-75'
  | 'active:scale-y-90'
  | 'group-hover:scale-y-90'
  | 'active:scale-y-95'
  | 'group-hover:scale-y-95'
  | 'active:scale-y-100'
  | 'group-hover:scale-y-100'
  | 'active:scale-y-105'
  | 'group-hover:scale-y-105'
  | 'active:scale-y-110'
  | 'group-hover:scale-y-110'
  | 'active:scale-y-125'
  | 'group-hover:scale-y-125'
  | 'active:scale-y-150'
  | 'group-hover:scale-y-150'
  | 'container:rotate-0'
  | 'container-mid:rotate-0'
  | 'container-large:rotate-0'
  | 'hover:rotate-0'
  | 'focus:rotate-0'
  | 'container:rotate-1'
  | 'container-mid:rotate-1'
  | 'container-large:rotate-1'
  | 'hover:rotate-1'
  | 'focus:rotate-1'
  | 'container:rotate-2'
  | 'container-mid:rotate-2'
  | 'container-large:rotate-2'
  | 'hover:rotate-2'
  | 'focus:rotate-2'
  | 'container:rotate-3'
  | 'container-mid:rotate-3'
  | 'container-large:rotate-3'
  | 'hover:rotate-3'
  | 'focus:rotate-3'
  | 'container:rotate-6'
  | 'container-mid:rotate-6'
  | 'container-large:rotate-6'
  | 'hover:rotate-6'
  | 'focus:rotate-6'
  | 'container:rotate-12'
  | 'container-mid:rotate-12'
  | 'container-large:rotate-12'
  | 'hover:rotate-12'
  | 'focus:rotate-12'
  | 'container:rotate-45'
  | 'container-mid:rotate-45'
  | 'container-large:rotate-45'
  | 'hover:rotate-45'
  | 'focus:rotate-45'
  | 'container:rotate-90'
  | 'container-mid:rotate-90'
  | 'container-large:rotate-90'
  | 'hover:rotate-90'
  | 'focus:rotate-90'
  | 'container:rotate-180'
  | 'container-mid:rotate-180'
  | 'container-large:rotate-180'
  | 'hover:rotate-180'
  | 'focus:rotate-180'
  | 'container:-rotate-180'
  | 'container-mid:-rotate-180'
  | 'container-large:-rotate-180'
  | 'hover:-rotate-180'
  | 'focus:-rotate-180'
  | 'container:-rotate-90'
  | 'container-mid:-rotate-90'
  | 'container-large:-rotate-90'
  | 'hover:-rotate-90'
  | 'focus:-rotate-90'
  | 'container:-rotate-45'
  | 'container-mid:-rotate-45'
  | 'container-large:-rotate-45'
  | 'hover:-rotate-45'
  | 'focus:-rotate-45'
  | 'container:-rotate-12'
  | 'container-mid:-rotate-12'
  | 'container-large:-rotate-12'
  | 'hover:-rotate-12'
  | 'focus:-rotate-12'
  | 'container:-rotate-6'
  | 'container-mid:-rotate-6'
  | 'container-large:-rotate-6'
  | 'hover:-rotate-6'
  | 'focus:-rotate-6'
  | 'container:-rotate-3'
  | 'container-mid:-rotate-3'
  | 'container-large:-rotate-3'
  | 'hover:-rotate-3'
  | 'focus:-rotate-3'
  | 'container:-rotate-2'
  | 'container-mid:-rotate-2'
  | 'container-large:-rotate-2'
  | 'hover:-rotate-2'
  | 'focus:-rotate-2'
  | 'container:-rotate-1'
  | 'container-mid:-rotate-1'
  | 'container-large:-rotate-1'
  | 'hover:-rotate-1'
  | 'focus:-rotate-1'
  | 'container:translate-x-0'
  | 'container-mid:translate-x-0'
  | 'container-large:translate-x-0'
  | 'hover:translate-x-0'
  | 'focus:translate-x-0'
  | 'container:translate-x-1'
  | 'container-mid:translate-x-1'
  | 'container-large:translate-x-1'
  | 'hover:translate-x-1'
  | 'focus:translate-x-1'
  | 'container:translate-x-2'
  | 'container-mid:translate-x-2'
  | 'container-large:translate-x-2'
  | 'hover:translate-x-2'
  | 'focus:translate-x-2'
  | 'container:translate-x-4'
  | 'container-mid:translate-x-4'
  | 'container-large:translate-x-4'
  | 'hover:translate-x-4'
  | 'focus:translate-x-4'
  | 'container:translate-x-8'
  | 'container-mid:translate-x-8'
  | 'container-large:translate-x-8'
  | 'hover:translate-x-8'
  | 'focus:translate-x-8'
  | 'container:translate-x-10'
  | 'container-mid:translate-x-10'
  | 'container-large:translate-x-10'
  | 'hover:translate-x-10'
  | 'focus:translate-x-10'
  | 'container:translate-x-12'
  | 'container-mid:translate-x-12'
  | 'container-large:translate-x-12'
  | 'hover:translate-x-12'
  | 'focus:translate-x-12'
  | 'container:translate-x-16'
  | 'container-mid:translate-x-16'
  | 'container-large:translate-x-16'
  | 'hover:translate-x-16'
  | 'focus:translate-x-16'
  | 'container:translate-x-20'
  | 'container-mid:translate-x-20'
  | 'container-large:translate-x-20'
  | 'hover:translate-x-20'
  | 'focus:translate-x-20'
  | 'container:translate-x-24'
  | 'container-mid:translate-x-24'
  | 'container-large:translate-x-24'
  | 'hover:translate-x-24'
  | 'focus:translate-x-24'
  | 'container:translate-x-32'
  | 'container-mid:translate-x-32'
  | 'container-large:translate-x-32'
  | 'hover:translate-x-32'
  | 'focus:translate-x-32'
  | 'container:translate-x-48'
  | 'container-mid:translate-x-48'
  | 'container-large:translate-x-48'
  | 'hover:translate-x-48'
  | 'focus:translate-x-48'
  | 'container:translate-x-80'
  | 'container-mid:translate-x-80'
  | 'container-large:translate-x-80'
  | 'hover:translate-x-80'
  | 'focus:translate-x-80'
  | 'container:translate-x-100'
  | 'container-mid:translate-x-100'
  | 'container-large:translate-x-100'
  | 'hover:translate-x-100'
  | 'focus:translate-x-100'
  | 'container:translate-x-160'
  | 'container-mid:translate-x-160'
  | 'container-large:translate-x-160'
  | 'hover:translate-x-160'
  | 'focus:translate-x-160'
  | 'container:translate-x-200'
  | 'container-mid:translate-x-200'
  | 'container-large:translate-x-200'
  | 'hover:translate-x-200'
  | 'focus:translate-x-200'
  | 'container:translate-x-280'
  | 'container-mid:translate-x-280'
  | 'container-large:translate-x-280'
  | 'hover:translate-x-280'
  | 'focus:translate-x-280'
  | 'container:-translate-x-0'
  | 'container-mid:-translate-x-0'
  | 'container-large:-translate-x-0'
  | 'hover:-translate-x-0'
  | 'focus:-translate-x-0'
  | 'container:-translate-x-1'
  | 'container-mid:-translate-x-1'
  | 'container-large:-translate-x-1'
  | 'hover:-translate-x-1'
  | 'focus:-translate-x-1'
  | 'container:-translate-x-2'
  | 'container-mid:-translate-x-2'
  | 'container-large:-translate-x-2'
  | 'hover:-translate-x-2'
  | 'focus:-translate-x-2'
  | 'container:-translate-x-4'
  | 'container-mid:-translate-x-4'
  | 'container-large:-translate-x-4'
  | 'hover:-translate-x-4'
  | 'focus:-translate-x-4'
  | 'container:-translate-x-8'
  | 'container-mid:-translate-x-8'
  | 'container-large:-translate-x-8'
  | 'hover:-translate-x-8'
  | 'focus:-translate-x-8'
  | 'container:-translate-x-10'
  | 'container-mid:-translate-x-10'
  | 'container-large:-translate-x-10'
  | 'hover:-translate-x-10'
  | 'focus:-translate-x-10'
  | 'container:-translate-x-12'
  | 'container-mid:-translate-x-12'
  | 'container-large:-translate-x-12'
  | 'hover:-translate-x-12'
  | 'focus:-translate-x-12'
  | 'container:-translate-x-16'
  | 'container-mid:-translate-x-16'
  | 'container-large:-translate-x-16'
  | 'hover:-translate-x-16'
  | 'focus:-translate-x-16'
  | 'container:-translate-x-20'
  | 'container-mid:-translate-x-20'
  | 'container-large:-translate-x-20'
  | 'hover:-translate-x-20'
  | 'focus:-translate-x-20'
  | 'container:-translate-x-24'
  | 'container-mid:-translate-x-24'
  | 'container-large:-translate-x-24'
  | 'hover:-translate-x-24'
  | 'focus:-translate-x-24'
  | 'container:-translate-x-32'
  | 'container-mid:-translate-x-32'
  | 'container-large:-translate-x-32'
  | 'hover:-translate-x-32'
  | 'focus:-translate-x-32'
  | 'container:-translate-x-48'
  | 'container-mid:-translate-x-48'
  | 'container-large:-translate-x-48'
  | 'hover:-translate-x-48'
  | 'focus:-translate-x-48'
  | 'container:-translate-x-80'
  | 'container-mid:-translate-x-80'
  | 'container-large:-translate-x-80'
  | 'hover:-translate-x-80'
  | 'focus:-translate-x-80'
  | 'container:-translate-x-100'
  | 'container-mid:-translate-x-100'
  | 'container-large:-translate-x-100'
  | 'hover:-translate-x-100'
  | 'focus:-translate-x-100'
  | 'container:-translate-x-160'
  | 'container-mid:-translate-x-160'
  | 'container-large:-translate-x-160'
  | 'hover:-translate-x-160'
  | 'focus:-translate-x-160'
  | 'container:-translate-x-200'
  | 'container-mid:-translate-x-200'
  | 'container-large:-translate-x-200'
  | 'hover:-translate-x-200'
  | 'focus:-translate-x-200'
  | 'container:-translate-x-280'
  | 'container-mid:-translate-x-280'
  | 'container-large:-translate-x-280'
  | 'hover:-translate-x-280'
  | 'focus:-translate-x-280'
  | 'container:-translate-x-full'
  | 'container-mid:-translate-x-full'
  | 'container-large:-translate-x-full'
  | 'hover:-translate-x-full'
  | 'focus:-translate-x-full'
  | 'container:-translate-x-1/2'
  | 'container-mid:-translate-x-1/2'
  | 'container-large:-translate-x-1/2'
  | 'hover:-translate-x-1/2'
  | 'focus:-translate-x-1/2'
  | 'container:translate-x-1/2'
  | 'container-mid:translate-x-1/2'
  | 'container-large:translate-x-1/2'
  | 'hover:translate-x-1/2'
  | 'focus:translate-x-1/2'
  | 'container:translate-x-full'
  | 'container-mid:translate-x-full'
  | 'container-large:translate-x-full'
  | 'hover:translate-x-full'
  | 'focus:translate-x-full'
  | 'container:translate-y-0'
  | 'container-mid:translate-y-0'
  | 'container-large:translate-y-0'
  | 'hover:translate-y-0'
  | 'focus:translate-y-0'
  | 'container:translate-y-1'
  | 'container-mid:translate-y-1'
  | 'container-large:translate-y-1'
  | 'hover:translate-y-1'
  | 'focus:translate-y-1'
  | 'container:translate-y-2'
  | 'container-mid:translate-y-2'
  | 'container-large:translate-y-2'
  | 'hover:translate-y-2'
  | 'focus:translate-y-2'
  | 'container:translate-y-4'
  | 'container-mid:translate-y-4'
  | 'container-large:translate-y-4'
  | 'hover:translate-y-4'
  | 'focus:translate-y-4'
  | 'container:translate-y-8'
  | 'container-mid:translate-y-8'
  | 'container-large:translate-y-8'
  | 'hover:translate-y-8'
  | 'focus:translate-y-8'
  | 'container:translate-y-10'
  | 'container-mid:translate-y-10'
  | 'container-large:translate-y-10'
  | 'hover:translate-y-10'
  | 'focus:translate-y-10'
  | 'container:translate-y-12'
  | 'container-mid:translate-y-12'
  | 'container-large:translate-y-12'
  | 'hover:translate-y-12'
  | 'focus:translate-y-12'
  | 'container:translate-y-16'
  | 'container-mid:translate-y-16'
  | 'container-large:translate-y-16'
  | 'hover:translate-y-16'
  | 'focus:translate-y-16'
  | 'container:translate-y-20'
  | 'container-mid:translate-y-20'
  | 'container-large:translate-y-20'
  | 'hover:translate-y-20'
  | 'focus:translate-y-20'
  | 'container:translate-y-24'
  | 'container-mid:translate-y-24'
  | 'container-large:translate-y-24'
  | 'hover:translate-y-24'
  | 'focus:translate-y-24'
  | 'container:translate-y-32'
  | 'container-mid:translate-y-32'
  | 'container-large:translate-y-32'
  | 'hover:translate-y-32'
  | 'focus:translate-y-32'
  | 'container:translate-y-48'
  | 'container-mid:translate-y-48'
  | 'container-large:translate-y-48'
  | 'hover:translate-y-48'
  | 'focus:translate-y-48'
  | 'container:translate-y-80'
  | 'container-mid:translate-y-80'
  | 'container-large:translate-y-80'
  | 'hover:translate-y-80'
  | 'focus:translate-y-80'
  | 'container:translate-y-100'
  | 'container-mid:translate-y-100'
  | 'container-large:translate-y-100'
  | 'hover:translate-y-100'
  | 'focus:translate-y-100'
  | 'container:translate-y-160'
  | 'container-mid:translate-y-160'
  | 'container-large:translate-y-160'
  | 'hover:translate-y-160'
  | 'focus:translate-y-160'
  | 'container:translate-y-200'
  | 'container-mid:translate-y-200'
  | 'container-large:translate-y-200'
  | 'hover:translate-y-200'
  | 'focus:translate-y-200'
  | 'container:translate-y-280'
  | 'container-mid:translate-y-280'
  | 'container-large:translate-y-280'
  | 'hover:translate-y-280'
  | 'focus:translate-y-280'
  | 'container:-translate-y-0'
  | 'container-mid:-translate-y-0'
  | 'container-large:-translate-y-0'
  | 'hover:-translate-y-0'
  | 'focus:-translate-y-0'
  | 'container:-translate-y-1'
  | 'container-mid:-translate-y-1'
  | 'container-large:-translate-y-1'
  | 'hover:-translate-y-1'
  | 'focus:-translate-y-1'
  | 'container:-translate-y-2'
  | 'container-mid:-translate-y-2'
  | 'container-large:-translate-y-2'
  | 'hover:-translate-y-2'
  | 'focus:-translate-y-2'
  | 'container:-translate-y-4'
  | 'container-mid:-translate-y-4'
  | 'container-large:-translate-y-4'
  | 'hover:-translate-y-4'
  | 'focus:-translate-y-4'
  | 'container:-translate-y-8'
  | 'container-mid:-translate-y-8'
  | 'container-large:-translate-y-8'
  | 'hover:-translate-y-8'
  | 'focus:-translate-y-8'
  | 'container:-translate-y-10'
  | 'container-mid:-translate-y-10'
  | 'container-large:-translate-y-10'
  | 'hover:-translate-y-10'
  | 'focus:-translate-y-10'
  | 'container:-translate-y-12'
  | 'container-mid:-translate-y-12'
  | 'container-large:-translate-y-12'
  | 'hover:-translate-y-12'
  | 'focus:-translate-y-12'
  | 'container:-translate-y-16'
  | 'container-mid:-translate-y-16'
  | 'container-large:-translate-y-16'
  | 'hover:-translate-y-16'
  | 'focus:-translate-y-16'
  | 'container:-translate-y-20'
  | 'container-mid:-translate-y-20'
  | 'container-large:-translate-y-20'
  | 'hover:-translate-y-20'
  | 'focus:-translate-y-20'
  | 'container:-translate-y-24'
  | 'container-mid:-translate-y-24'
  | 'container-large:-translate-y-24'
  | 'hover:-translate-y-24'
  | 'focus:-translate-y-24'
  | 'container:-translate-y-32'
  | 'container-mid:-translate-y-32'
  | 'container-large:-translate-y-32'
  | 'hover:-translate-y-32'
  | 'focus:-translate-y-32'
  | 'container:-translate-y-48'
  | 'container-mid:-translate-y-48'
  | 'container-large:-translate-y-48'
  | 'hover:-translate-y-48'
  | 'focus:-translate-y-48'
  | 'container:-translate-y-80'
  | 'container-mid:-translate-y-80'
  | 'container-large:-translate-y-80'
  | 'hover:-translate-y-80'
  | 'focus:-translate-y-80'
  | 'container:-translate-y-100'
  | 'container-mid:-translate-y-100'
  | 'container-large:-translate-y-100'
  | 'hover:-translate-y-100'
  | 'focus:-translate-y-100'
  | 'container:-translate-y-160'
  | 'container-mid:-translate-y-160'
  | 'container-large:-translate-y-160'
  | 'hover:-translate-y-160'
  | 'focus:-translate-y-160'
  | 'container:-translate-y-200'
  | 'container-mid:-translate-y-200'
  | 'container-large:-translate-y-200'
  | 'hover:-translate-y-200'
  | 'focus:-translate-y-200'
  | 'container:-translate-y-280'
  | 'container-mid:-translate-y-280'
  | 'container-large:-translate-y-280'
  | 'hover:-translate-y-280'
  | 'focus:-translate-y-280'
  | 'container:-translate-y-full'
  | 'container-mid:-translate-y-full'
  | 'container-large:-translate-y-full'
  | 'hover:-translate-y-full'
  | 'focus:-translate-y-full'
  | 'container:-translate-y-1/2'
  | 'container-mid:-translate-y-1/2'
  | 'container-large:-translate-y-1/2'
  | 'hover:-translate-y-1/2'
  | 'focus:-translate-y-1/2'
  | 'container:translate-y-1/2'
  | 'container-mid:translate-y-1/2'
  | 'container-large:translate-y-1/2'
  | 'hover:translate-y-1/2'
  | 'focus:translate-y-1/2'
  | 'container:translate-y-full'
  | 'container-mid:translate-y-full'
  | 'container-large:translate-y-full'
  | 'hover:translate-y-full'
  | 'focus:translate-y-full'
  | 'container:skew-x-0'
  | 'container-mid:skew-x-0'
  | 'container-large:skew-x-0'
  | 'hover:skew-x-0'
  | 'focus:skew-x-0'
  | 'container:skew-x-1'
  | 'container-mid:skew-x-1'
  | 'container-large:skew-x-1'
  | 'hover:skew-x-1'
  | 'focus:skew-x-1'
  | 'container:skew-x-2'
  | 'container-mid:skew-x-2'
  | 'container-large:skew-x-2'
  | 'hover:skew-x-2'
  | 'focus:skew-x-2'
  | 'container:skew-x-3'
  | 'container-mid:skew-x-3'
  | 'container-large:skew-x-3'
  | 'hover:skew-x-3'
  | 'focus:skew-x-3'
  | 'container:skew-x-6'
  | 'container-mid:skew-x-6'
  | 'container-large:skew-x-6'
  | 'hover:skew-x-6'
  | 'focus:skew-x-6'
  | 'container:skew-x-12'
  | 'container-mid:skew-x-12'
  | 'container-large:skew-x-12'
  | 'hover:skew-x-12'
  | 'focus:skew-x-12'
  | 'container:-skew-x-12'
  | 'container-mid:-skew-x-12'
  | 'container-large:-skew-x-12'
  | 'hover:-skew-x-12'
  | 'focus:-skew-x-12'
  | 'container:-skew-x-6'
  | 'container-mid:-skew-x-6'
  | 'container-large:-skew-x-6'
  | 'hover:-skew-x-6'
  | 'focus:-skew-x-6'
  | 'container:-skew-x-3'
  | 'container-mid:-skew-x-3'
  | 'container-large:-skew-x-3'
  | 'hover:-skew-x-3'
  | 'focus:-skew-x-3'
  | 'container:-skew-x-2'
  | 'container-mid:-skew-x-2'
  | 'container-large:-skew-x-2'
  | 'hover:-skew-x-2'
  | 'focus:-skew-x-2'
  | 'container:-skew-x-1'
  | 'container-mid:-skew-x-1'
  | 'container-large:-skew-x-1'
  | 'hover:-skew-x-1'
  | 'focus:-skew-x-1'
  | 'container:skew-y-0'
  | 'container-mid:skew-y-0'
  | 'container-large:skew-y-0'
  | 'hover:skew-y-0'
  | 'focus:skew-y-0'
  | 'container:skew-y-1'
  | 'container-mid:skew-y-1'
  | 'container-large:skew-y-1'
  | 'hover:skew-y-1'
  | 'focus:skew-y-1'
  | 'container:skew-y-2'
  | 'container-mid:skew-y-2'
  | 'container-large:skew-y-2'
  | 'hover:skew-y-2'
  | 'focus:skew-y-2'
  | 'container:skew-y-3'
  | 'container-mid:skew-y-3'
  | 'container-large:skew-y-3'
  | 'hover:skew-y-3'
  | 'focus:skew-y-3'
  | 'container:skew-y-6'
  | 'container-mid:skew-y-6'
  | 'container-large:skew-y-6'
  | 'hover:skew-y-6'
  | 'focus:skew-y-6'
  | 'container:skew-y-12'
  | 'container-mid:skew-y-12'
  | 'container-large:skew-y-12'
  | 'hover:skew-y-12'
  | 'focus:skew-y-12'
  | 'container:-skew-y-12'
  | 'container-mid:-skew-y-12'
  | 'container-large:-skew-y-12'
  | 'hover:-skew-y-12'
  | 'focus:-skew-y-12'
  | 'container:-skew-y-6'
  | 'container-mid:-skew-y-6'
  | 'container-large:-skew-y-6'
  | 'hover:-skew-y-6'
  | 'focus:-skew-y-6'
  | 'container:-skew-y-3'
  | 'container-mid:-skew-y-3'
  | 'container-large:-skew-y-3'
  | 'hover:-skew-y-3'
  | 'focus:-skew-y-3'
  | 'container:-skew-y-2'
  | 'container-mid:-skew-y-2'
  | 'container-large:-skew-y-2'
  | 'hover:-skew-y-2'
  | 'focus:-skew-y-2'
  | 'container:-skew-y-1'
  | 'container-mid:-skew-y-1'
  | 'container-large:-skew-y-1'
  | 'hover:-skew-y-1'
  | 'focus:-skew-y-1'
  | 'container:transition-none'
  | 'container-mid:transition-none'
  | 'container-large:transition-none'
  | 'container:transition-all'
  | 'container-mid:transition-all'
  | 'container-large:transition-all'
  | 'container:transition'
  | 'container-mid:transition'
  | 'container-large:transition'
  | 'container:transition-colors'
  | 'container-mid:transition-colors'
  | 'container-large:transition-colors'
  | 'container:transition-opacity'
  | 'container-mid:transition-opacity'
  | 'container-large:transition-opacity'
  | 'container:transition-shadow'
  | 'container-mid:transition-shadow'
  | 'container-large:transition-shadow'
  | 'container:transition-transform'
  | 'container-mid:transition-transform'
  | 'container-large:transition-transform'
  | 'container:ease-linear'
  | 'container-mid:ease-linear'
  | 'container-large:ease-linear'
  | 'container:ease-in'
  | 'container-mid:ease-in'
  | 'container-large:ease-in'
  | 'container:ease-out'
  | 'container-mid:ease-out'
  | 'container-large:ease-out'
  | 'container:ease-in-out'
  | 'container-mid:ease-in-out'
  | 'container-large:ease-in-out'
  | 'container:duration-75'
  | 'container-mid:duration-75'
  | 'container-large:duration-75'
  | 'container:duration-100'
  | 'container-mid:duration-100'
  | 'container-large:duration-100'
  | 'container:duration-150'
  | 'container-mid:duration-150'
  | 'container-large:duration-150'
  | 'container:duration-200'
  | 'container-mid:duration-200'
  | 'container-large:duration-200'
  | 'container:duration-300'
  | 'container-mid:duration-300'
  | 'container-large:duration-300'
  | 'container:duration-500'
  | 'container-mid:duration-500'
  | 'container-large:duration-500'
  | 'container:duration-700'
  | 'container-mid:duration-700'
  | 'container-large:duration-700'
  | 'container:duration-1000'
  | 'container-mid:duration-1000'
  | 'container-large:duration-1000'
  | 'container:delay-75'
  | 'container-mid:delay-75'
  | 'container-large:delay-75'
  | 'container:delay-100'
  | 'container-mid:delay-100'
  | 'container-large:delay-100'
  | 'container:delay-150'
  | 'container-mid:delay-150'
  | 'container-large:delay-150'
  | 'container:delay-200'
  | 'container-mid:delay-200'
  | 'container-large:delay-200'
  | 'container:delay-300'
  | 'container-mid:delay-300'
  | 'container-large:delay-300'
  | 'container:delay-500'
  | 'container-mid:delay-500'
  | 'container-large:delay-500'
  | 'container:delay-700'
  | 'container-mid:delay-700'
  | 'container-large:delay-700'
  | 'container:delay-1000'
  | 'container-mid:delay-1000'
  | 'container-large:delay-1000'
  | 'container:animate-none'
  | 'container-mid:animate-none'
  | 'container-large:animate-none'
  | 'container:animate-spin'
  | 'container-mid:animate-spin'
  | 'container-large:animate-spin'
  | 'container:animate-ping'
  | 'container-mid:animate-ping'
  | 'container-large:animate-ping'
  | 'container:animate-pulse'
  | 'container-mid:animate-pulse'
  | 'container-large:animate-pulse'
  | 'container:animate-bounce'
  | 'container-mid:animate-bounce'
  | 'container-large:animate-bounce'
  | 'container:sr-only'
  | 'container-mid:sr-only'
  | 'container-large:sr-only'
  | 'focus:sr-only'
  | 'container:not-sr-only'
  | 'container-mid:not-sr-only'
  | 'container-large:not-sr-only'
  | 'focus:not-sr-only'

export type TCustomFormsPluginClasses =
  | 'form-input'
  | 'form-textarea'
  | 'form-select'
  | 'form-multiselect'
  | 'form-checkbox'
  | 'form-radio'

export type TTypographyPluginClasses =
  | 'prose'
  | 'prose-sm'
  | 'prose-lg'
  | 'prose-xl'
  | 'prose-2xl'

export type TClasses =
  | TLayout
  | TTypography
  | TBackgrounds
  | TBorders
  | TFlexBox
  | TGrid
  | TSpacing
  | TSizing
  | TTables
  | TEffects
  | TTransforms
  | TTransitionsAndAnimations
  | TInteractivity
  | TSVG
  | TAccessibility 
  | TCustomFormsPluginClasses  
  | TPseudoClasses;


export type TTailwindString = "TAILWIND_STRING"

export type TKey = TClasses | TTailwindString

export type TArg =
  | TClasses
  | null
  | undefined
  | {[key in TKey]?: boolean}
  | TTailwindString

export type TTailwind = (...args: TArg[]) => TTailwindString

export const classnames: TTailwind = classnamesLib as any

export const tw = classnames

export default tw
