import Image from "next/image";
import React, { FC } from "react";

import { tw } from "../../tailwindcss-classnames";
import { B2 } from "../type/Typography";

import { c } from "lib/c";

const year = new Date().getFullYear();

export const Footer: FC<{ className?: string }> = ({ className }) => {
  return (
    <footer
      className={c(
        tw(
          "text-white",
          "text-center",
          "pt-32",
          "container-large:pb-32",
          "space-y-16",
          "pb-24"
        ),
        className
      )}
    >
      <Image
        src="/golden_eggs_gold_transparent.png"
        alt="Golden Eggs Logo"
        width={156}
        height={60}
        className={tw("inline-block")}
      />
      <B2>{`© ${year} GoldenEggs investment Services.`}</B2>
      <B2>All rights reserved.</B2>
    </footer>
  );
};
