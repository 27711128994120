import { motion } from "framer-motion";
import Link from "next/link";
import { forwardRef, HTMLAttributes } from "react";

import { TArg as TArgument, tw } from "../../tailwindcss-classnames";
import { LoadingSpinner } from "../display/LoadingSpinner";

import { c } from "lib/c";

type ButtonColorType =
  | "gold"
  | "sand"
  | "white"
  | "white-outline"
  | "white-red";
type FullButtonColorType = ButtonColorType &
  ("disabled-gold" | "disabled-sand");
type ButtonSizeType = "sm" | "base";
type ButtonShapeType = "block" | "inline";

interface ButtonProps
  extends Omit<
    HTMLAttributes<HTMLButtonElement>,
    "onAnimationStart" | "onDragStart" | "onDragEnd" | "onDrag"
  > {
  color?: ButtonColorType;
  size?: ButtonSizeType;
  href?: string;
  type?: "submit" | "reset" | "button";
  loading?: boolean;
  fakeDisabled?: boolean;
  disabled?: boolean;
  notDisabledWhileLoading?: boolean;
  shape?: ButtonShapeType;
  target?: "_blank";
}

const buttonClass = tw(
  "block",
  "font-bold",
  "py-10",
  "px-16",
  "rounded-4",
  "container-large:rounded-8",
  "text-center"
);
const colorClass: Record<FullButtonColorType, TArgument> = {
  gold: tw("bg-gold", "text-white"),
  "disabled-gold": tw("bg-unassumingGray", "text-gray-300"),
  sand: tw("bg-sand", "text-gold"),
  "disabled-sand": tw("bg-unassumingGray", "text-gray-300"),
  white: tw("bg-white", "text-gold"),
  "white-outline": tw("border-2", "border-white", "text-white"),
  "white-red": tw("bg-white", "text-mistakeRed"),
  "disabled-white": tw("bg-unassumingGray", "text-gray-300"),
};

const sizeClass: Record<ButtonSizeType, TArgument> = {
  base: tw("text-base"),
  sm: tw("text-sm"),
};
const shapeClass: Record<ButtonShapeType, TArgument> = {
  inline: tw("inline-block"),
  block: tw("w-full", "block"),
};
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      className,
      fakeDisabled,
      disabled,
      loading,
      href,
      children,
      target,
      type = "button",
      size = "16",
      color = "gold",
      shape = "block",
      notDisabledWhileLoading,
      ...props
    },
    ref
  ) {
    if (!notDisabledWhileLoading && loading) {
      disabled = true;
    }
    const buttonProps = {
      ...props,
      ref,
      disabled,
      className: c(
        buttonClass,
        colorClass[fakeDisabled || disabled ? "disabled-" + color : color],
        sizeClass[size],
        shapeClass[shape],
        disabled && tw("pointer-events-none"),
        className
      ),
      whileHover: { scale: 1.02 },
      whileTap: { scale: 0.98 },
      transition: { duration: 0.1 },
    };

    if (href) {
      if (href.startsWith("http") || href.startsWith("mailto")) {
        target = "_blank";
      }
      return target ? (
        <motion.a {...(buttonProps as any)} href={href} target={target}>
          {children}
          {loading && (
            <LoadingSpinner
              className={tw("inline-block", "px-16", "max-h-16" as TArgument)}
            />
          )}
        </motion.a>
      ) : (
        <Link href={href} passHref={true}>
          <motion.a {...(buttonProps as any)}>
            {children}
            {loading && (
              <LoadingSpinner
                className={tw("inline-block", "px-16", "max-h-16" as TArgument)}
              />
            )}
          </motion.a>
        </Link>
      );
    } else {
      return (
        <motion.button {...buttonProps} type={type}>
          {children}
          {loading && (
            <LoadingSpinner
              className={tw("inline-block", "px-16", "max-h-16" as TArgument)}
            />
          )}
        </motion.button>
      );
    }
  }
);
