import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { LandingPage } from "../components/landing/LandingPage";

const Index: React.FC<unknown> = () => {
  const router = useRouter();
  useEffect(() => {
    router.prefetch("/users/[userId]/home");
    router.prefetch("/login");
    router.prefetch("/signup");
  }, [router]);
  return <LandingPage />;
};

export default Index;
