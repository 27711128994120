import { ElementType, FC, HTMLAttributes } from "react";

import { TArg as TArgument, tw } from "../../tailwindcss-classnames";

import { c } from "lib/c";

const h1TwClass = tw("font-extrabold", "text-xl", "text-center");

type HeaderProps = HTMLAttributes<HTMLHeadingElement> & {
  Component?: ElementType;
  extraProps?: any;
};

export const H1: FC<HeaderProps> = ({
  Component = "h1",
  children,
  extraProps,
  ...props
}) => (
  <Component
    {...props}
    className={c(h1TwClass, props.className)}
    {...extraProps}
  >
    {children}
  </Component>
);

const h2TwClass = tw("font-bold", "text-base");

export const H2: FC<HeaderProps> = ({
  Component = "h2",
  children,
  extraProps,
  ...props
}) => (
  <Component
    {...props}
    className={c(h2TwClass, props.className)}
    {...extraProps}
  >
    {children}
  </Component>
);

const h3TwClass = tw("font-bold", "text-sm", "uppercase");

export const H3: FC<HeaderProps> = ({
  Component = "h3",
  children,
  extraProps,
  ...props
}) => (
  <Component
    {...props}
    className={c(h3TwClass, props.className)}
    {...extraProps}
  >
    {children}
  </Component>
);

const b1TwClass = tw("text-base");

export const B1: FC<HeaderProps> = ({
  Component = "h5",
  children,
  extraProps,
  ...props
}) => (
  <Component
    {...props}
    className={c(b1TwClass, props.className)}
    {...extraProps}
  >
    {children}
  </Component>
);

export const B2: FC<HeaderProps & { color?: string }> = ({
  Component = "h5",
  children,
  extraProps,
  color = "text-steel",
  ...props
}) => (
  <Component
    {...props}
    className={c(tw("text-sm", color as TArgument), props.className)}
    {...extraProps}
  >
    {children}
  </Component>
);
